import BaseInvoker from "./BaseInvoker";
import { Component } from "react";

const urlInvoke = `/api`;
const baseInvoker = new BaseInvoker();

export default class AppointmentApiInvoker extends Component {
  static getScheduleAppointment(
    appointmentId,
    dontFetchCanceled = false,
    callback,
    callerror
  ) {
    baseInvoker.get(
      `${urlInvoke}/schedule/appointment/${appointmentId}?NOT_CANCELLED=${dontFetchCanceled}`,
      callback,
      callerror
    );
  }

  // static getAppointments(callback, callerror) {
  //   baseInvoker.get(`${urlInvoke}/appointments`, callback, callerror);
  // }

  static getAppointmentTechnicalFile(appointmentId, callback, callerror) {
    baseInvoker.get(
      `${urlInvoke}/appointment-schedules/${appointmentId}`,
      callback,
      callerror
    );
  }

  static updateAppointmentMetadata(appointmentId, body, callback, callerror) {
    this.updateAppointmentTechnicalFile(
      appointmentId,
      body,
      callback,
      callerror
    );
  }

  static updateAppointmentTechnicalFile(
    appointmentId,
    body,
    callback,
    callerror
  ) {
    baseInvoker.put(
      `${urlInvoke}/appointments/${appointmentId}/metadata`,
      body,
      callback,
      callerror
    );
  }

  static getScheduleInCalendar(scheduleId, callback, callerror) {
    baseInvoker.get(
      `${urlInvoke}/schedule-in-calendar/${scheduleId}`,
      callback,
      callerror
    );
  }

  static getScheduleBookingInCalendar(scheduleBookingId, callback, callerror) {
    baseInvoker.get(
      `${urlInvoke}/schedule-booking-in-calendar/${scheduleBookingId}`,
      callback,
      callerror
    );
  }

  static getPackagesActive(callback, callerror) {
    baseInvoker.get(`${urlInvoke}/packages/?active=True`, callback, callerror);
  }

  static getPackagesAll(callback, callerror) {
    baseInvoker.get(`${urlInvoke}/packages/`, callback, callerror);
  }

  static getModulePractices(moduleId, callback, callerror) {
    baseInvoker.get(
      `${urlInvoke}/package/${moduleId}/practices`,
      callback,
      callerror
    );
  }

  static getPracticeTypes(callback, callerror) {
    baseInvoker.get(
      `${urlInvoke}/practice_types?active=true`,
      callback,
      callerror
    );
  }

  static getGenericPracticeTypes(callback, callerror) {
    baseInvoker.get(`${urlInvoke}/generic-practice-type`, callback, callerror);
  }

  static getCompanyPracticeTypes(callback, callerror) {
    baseInvoker.get(`${urlInvoke}/company-practicetype`, callback, callerror);
  }

  static getCompanyPracticeType(practiceTypeId, callback, callerror) {
    baseInvoker.get(
      `${urlInvoke}/company-practicetype/${practiceTypeId}`,
      callback,
      callerror
    );
  }

  static getPracticeTypesGroup(callback, callerror) {
    baseInvoker.get(`${urlInvoke}/practice-type-group`, callback, callerror);
  }

  static postCompanyPracticeType(body, callback, callerror) {
    baseInvoker.post(
      `${urlInvoke}/company-practicetype`,
      body,
      callback,
      callerror
    );
  }

  static getCustomerSchedules(customerId, callback, callerror) {
    baseInvoker.get(
      `${urlInvoke}/customer/${customerId}/schedules`,
      callback,
      callerror
    );
  }

  static getScheduleStatuses(callback, callerror) {
    baseInvoker.get(`${urlInvoke}/scheduleStatuses`, callback, callerror);
  }

  static getRepeatRecurrenceTypes(callback, callerror) {
    baseInvoker.get(`${urlInvoke}/repeatRecurrenceTypes`, callback, callerror);
  }

  static getAppointmentTypes(callback, callerror) {
    baseInvoker.get(`${urlInvoke}/appointmentTypes`, callback, callerror);
  }

  static getAppointmentTool(dateFrom, dateTo, callback, callerror) {
    baseInvoker.get(
      `${urlInvoke}/tools-available?date-from=${dateFrom}&date-to=${dateTo}`,
      callback,
      callerror
    );
  }

  static getAppointmentSelectedTool(appointmentId, callback, callerror) {
    baseInvoker.get(
      `${urlInvoke}/appointments/${appointmentId}/tools`,
      callback,
      callerror
    );
  }

  static postAppointment(body, callback, callerror) {
    baseInvoker.post(`${urlInvoke}/appointment`, body, callback, callerror);
  }

  static postAppointmentTool(appointmentid, body, callback, callerror) {
    baseInvoker.post(
      `${urlInvoke}/appointments/${appointmentid}/tools`,
      body,
      callback,
      callerror
    );
  }

  static deleteAppointmentTool(appointmentId, toolId, callback, callerror) {
    baseInvoker.delete(
      `${urlInvoke}/appointments/${appointmentId}/tools/${toolId}`,
      callback,
      callerror
    );
  }

  static getAppointment(appointmentId, callback, callerror) {
    baseInvoker.get(
      `${urlInvoke}/appointment/${appointmentId}`,
      callback,
      callerror
    );
  }

  static cancelAppointment(appointmentId, body, callback, callerror) {
    baseInvoker.post(
      `${urlInvoke}/appointment/${appointmentId}/cancel-status`,
      body,
      callback,
      callerror
    );
  }

  static getAppointmentCancellations(appointmentId, callback, callerror) {
    baseInvoker.get(
      `${urlInvoke}/appointment/${appointmentId}/cancel-status`,
      callback,
      callerror
    );
  }
  static getAppointmentItems(appointmentId, callback, callerror) {
    var url = `${urlInvoke}/appointment/items-request/${appointmentId}`;
    baseInvoker.get(url, callback, callerror);
  }
  static postAppointmentScheduleItems(
    appointmentId,
    body,
    callback,
    callerror
  ) {
    baseInvoker.post(
      `${urlInvoke}/appointment/items-request/${appointmentId}`,
      body,
      callback,
      callerror
    );
  }

  static deleteAppointmentScheduleItems(
    appointmentItemRequestId,
    callback,
    callerror
  ) {
    baseInvoker.delete(
      `${urlInvoke}/appointment/items-request/${appointmentItemRequestId}`,
      callback,
      callerror
    );
  }

  static getAppointmentItemsDetails(appointmentId, callback, callerror) {
    var url = `${urlInvoke}/appointment-detail/items-request/${appointmentId}`;
    baseInvoker.get(url, callback, callerror);
  }

  static getAppointmentById(appointmentId, callback, callerror) {
    var url = `${urlInvoke}/mobility/transfer/${appointmentId}`;
    baseInvoker.get(url, callback, callerror);
  }

  static getAppointments(params, callback, callerror) {
    var url = `${urlInvoke}/mobility/transfers/?${params}`;
    baseInvoker.get(url, callback, callerror);
  }

  static createAppointment(body, callback, callerror) {
    baseInvoker.post(
      `${urlInvoke}/mobility/transfer/`,
      body,
      callback,
      callerror
    );
  }

  static newSchedule(transferId, callback, callerror) {
    baseInvoker.post(
      `${urlInvoke}/mobility/transfer/${transferId}/new-schedule`,
      callback,
      callerror
    );
  }

  static confirmNewSchedule(transferId, callback, callerror) {
    baseInvoker.post(
      `${urlInvoke}/mobility/transfer/${transferId}/confirm-schedule`,
      callback,
      callerror
    );
  }

  static cancelNewSchedule(transferId, callback, callerror) {
    baseInvoker.post(
      `${urlInvoke}/mobility/transfer/${transferId}/cancel-schedule`,
      callback,
      callerror
    );
  }

  static getHospital(callback, callerror) {
    let url = `${urlInvoke}/mobility/hospital`;
    baseInvoker.get(url, callback, callerror);
  }

  static getDrivers(callback, callerror) {
    let url = `${urlInvoke}/drivervehicle/all`;
    baseInvoker.get(url, callback, callerror);
  }

  static getQuantity(id, callback, callerror) {
    let url = `${urlInvoke}/mobility/transfer/${id}/quantity`;
    baseInvoker.get(url, callback, callerror);
  }
}
