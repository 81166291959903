export const useFiltersParams = (filters, pageNumber, pageSize) => {

  const rmEmptyValuesObject = (obj) => {
    const valuesObject = { ...obj };
    for (const key in valuesObject) {
      if (valuesObject[key] === undefined || valuesObject[key] === null || valuesObject[key] === '') {
        delete valuesObject[key];
      }
    }
    return valuesObject;

  }

  const getFiltersSearch = (filters) => {
    const cleanFilters = rmEmptyValuesObject(filters);
    let params = Object.keys(cleanFilters).map((k) => {
      const value = cleanFilters[k];
      return `${encodeURIComponent(k)}=${encodeURIComponent(value)}`;
    }).join('&');
    if (params) {
      params = `${params}&`;
    }
    return params;
  }

  const searchParams = getFiltersSearch(filters);
  const queryParams = `${searchParams}page=${pageNumber}&pageSize=${pageSize}`;

  return queryParams;
}
