import React from 'react'
import { PropTypes } from 'prop-types'
import Tooltip from '@material-ui/core/Tooltip'
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded';
import RejectIcon from '../../assets/img/cancel_schedule_calendar.png';

import './EventWithIcon.css'

const EventWithIcon = ({ event }) => {
  let CustomIcon, color, className
  switch (event.status) {
    case 'OK':
      CustomIcon = CheckCircleRoundedIcon
      className = 'success'
      break
    case 'WARNING':
      CustomIcon = ErrorRoundedIcon
      className = 'warning'
      break
    case 'ERROR':
      CustomIcon = CancelRoundedIcon
      className = 'error'
      break
    default:
      CustomIcon = CheckCircleRoundedIcon
      className = 'success'
  }

  const Icon = (
    <CustomIcon
      className={`miniIcon ${className}`}
    />
  )

  const dataToRender =
    //event.type !== 'schedule' ? (
      event.type === 'booking' ? (
      <Tooltip
        title={
          event && event.status !== 'OK' && event.errorDetail
            ? event.errorDetail
            : ''
        }
      >
        {Icon}
      </Tooltip>
    ) : null

  
  let classType = event.type === 'booking' ? "event-with-icon" : "event-not-icon";
  
  let title = event.title;

  if (event.view === 'month') {
    title = `${event.time_start} - ${title}`
  }
  let  extraicon = <div/>
  if (event.hasRejected) {

    let  classname = "reject-icon"

    if (event.view === 'agenda') {
      classname = "reject-icon-agenda"
    }

    extraicon = <img src={RejectIcon} className={classname} />
  }

  if (event.view !== 'month' && title.length > 6) {
    let  range_time = "";
    if (event.view !== 'agenda') {
      range_time = event.time_display;
    }
    let  diffMs = (event.end - event.start);
    let  diffMins = Math.floor((diffMs/1000)/60);

    if (diffMins <= 30) {
      title = (title.length > 25 ? title.substring(0, 25) + '...' : title) + " " + range_time
      range_time = ""
    }
    return (
      <div className={`event-cont ${classType}`}>
        <div className="event-alert">
          {dataToRender}
        </div>
        <div className="event-container-title">
          <span className="event-title">{title}</span>
        </div>
        {extraicon}
      </div>
    )
    
  } else {

    console.log("aca");
    console.log(title);

      return (
        <div className={`event-cont ${classType}`}>
          <div className="event-alert">
            {dataToRender}
          </div>
          <div className="event-container-title">
            <span className="event-title">{title}</span>
          </div>
          {extraicon}
        </div>
      )
  }

}

EventWithIcon.propTypes = {
  event: PropTypes.shape({
    type: PropTypes.string,
    status: PropTypes.string,
    errorDetail: PropTypes.string,
  }),
}

export default EventWithIcon
