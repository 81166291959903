import React from 'react';
import { withTranslation } from 'react-i18next';
import GridContainer from '../Grid/GridContainer'
import GridItem from '../Grid/GridItem';
import LogoHMM from '../../../src/assets/img/login/logo-hmm.png';
import LogoGlin from '../../../src/assets/img/glin-full-grey.png';
import './About.css';

const About = ({ t }) => {
    return (
        <GridContainer className="about text-center">
            <GridItem xs={12}>
                <img className="about-logo" alt="HMM" src={LogoHMM} />
            </GridItem>
            <GridItem xs={12} className="about-information">
                <p>
                    <span className="about-information-label">{t('about.version')}: </span>
                    <span className="about-information-value">
                        {localStorage.getItem('APP_VERSION')}
                    </span>
                </p>
                <p>
                    <span className="about-information-label" >{t('about.date')}: </span> 
                    <span className="about-information-value">
                        {localStorage.getItem('RELEASE_DATE')}
                    </span>
                </p>
            </GridItem>
            <GridItem className="about-company-info" xs={12}>
                <p className="powered-by"><small>Powered by</small></p>
                <p>
                    <a href="https://glin-technologies.com/" rel="noopener noreferrer" target="_blank">
                        <img className="about-company" alt="Glin" src={LogoGlin} />
                    </a>
                </p>
            </GridItem>
        </GridContainer>
    )
}

export default withTranslation()(About);
