import React, { Suspense } from 'react';
import { hot } from 'react-hot-loader';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { Router, Route, browserHistory, Redirect } from 'react-router';

import UserService from './containers/UserService';
import AppointmentsComponents from './containers/AppointmentsService';
import AlertsService from './containers/AlertsService';
import CustomersService from './containers/CustomersService';
import EmployeesService from './containers/EmployeesService';
import CustomerComponents from './containers/NewCustomerService';
import EmployeeComponents from './containers/NewEmployeeService';
import Navigator from './components/Navigator';
import BookingsService from './containers/BookingsService';
import DataReport from './containers/DataReportService';

import './i18n';
import requireAuth from './handlers/authorizing-handler';
import _ from 'lodash';
import Drivers from './pages/Drivers';
import Driver from './pages/Driver';

const Root = ({ store }) => (
  <Suspense fallback={null}>
    <Provider store={store}>
      <Router history={browserHistory}>
        <Route path="/login" component={UserService.UserLogged} />
        <Route component={Navigator}>
          <Redirect
            exact
            from="/"
            to="/solicitudes"
            onEnter={requireAuth}
          />
          <Route path="/" component={UserService.UserLogged} />
          <Route path="/solicitudes" exact component={AppointmentsComponents.AppointmentsService} onEnter={requireAuth} />
          <Route path="/new-appointment" exact component={AppointmentsComponents.AppointmentDetailService} onEnter={requireAuth} />

          <Route path="/solicitudes/:id" exact component={AppointmentsComponents.AppointmentDetailService} onEnter={requireAuth} />
          <Route path="/appointments/:id/edit/agenda" exact component={AppointmentsComponents.AppointmentDetailService} onEnter={requireAuth} />
          <Route path="/appointments/:id/renew/agenda" exact component={AppointmentsComponents.AppointmentDetailService} onEnter={requireAuth} />

          <Route path="/turnos/:id" component={BookingsService} onEnter={requireAuth} />
          <Route path="/statistics" component={DataReport} onEnter={requireAuth} />

          <Route path="/alertas" component={AlertsService} onEnter={requireAuth} />
          <Route path="/alertas" component={AlertsService} onEnter={requireAuth} />
          <Route path="/patients" component={CustomersService} onEnter={requireAuth} />
          <Route path="/ver-profesional/:id" component={EmployeeComponents.EditEmployeeService} onEnter={requireAuth} />
          <Route path="/profesionales" component={EmployeesService.EmployeesService} onEnter={requireAuth} />
          <Route path="/nuevo-profesional" component={EmployeeComponents.NewEmployeeService} onEnter={requireAuth} />
          <Route path="/editar-profesional/:id" component={EmployeeComponents.EditEmployeeService} onEnter={requireAuth} />
          <Route path="/nuevo-paciente" component={CustomerComponents.NewCustomerService} onEnter={requireAuth}/>
          <Route path="/editar-paciente/:id" component={CustomerComponents.EditCustomerService} onEnter={requireAuth} />
          <Route path="/patient/:id" component={CustomerComponents.EditCustomerService} onEnter={requireAuth} />
          {/* mobility */}
          <Route path="/drivers" component={Drivers} onEnter={requireAuth} />
          <Route path="/driver/:id" component={Driver} onEnter={requireAuth} />
        </Route>
      </Router>
    </Provider>
  </Suspense>
)

Root.propTypes = {
  store: PropTypes.object.isRequired,
}

export default hot(module)(Root);
