import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker
} from 'react-google-maps';
import ButtonSpinner from '../ButtonSpinner';
import CheckInput from '../CheckInput/check-input';
import Button from '../CustomButtons/Button';
import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';
import ValidationInput from '../ValidationInput';
import SelectInput from '../SelectInput';
import Snackbar from '../Snackbar/Snackbar';
import ApiInvoker from "../../api/ApiInvoker"
import PersonApiInvoker from "../../api/PersonApiInvoker";
import ThirdPartiesInvoker from "../../api/ThirdPartiesInvoker";
import { compose, withProps } from "recompose";
import './CreateAddress.css';

const MapComponent = compose(
    withProps({
      googleMapURL:"/googlemap",
      loadingElement: <div style={{ height: `100%` }} />,
      containerElement: <div style={{ position:`absolute`, width: `100%`, height: `100%` }} />,
      mapElement: <div style={{ height: `100%` }} />      
    }),
    withScriptjs,
    withGoogleMap
  )(props => (
    <GoogleMap defaultZoom={16}
        center={{
            lat: Number(props.lat) || -31.41070438150723,
            lng: Number(props.lng) || -64.19620923199496
            }}>
      <Marker position={{ lat: Number(props.lat), lng: Number(props.lng) }} />
    </GoogleMap>
));

class CreateAddress extends Component {
    constructor(props) {
        super(props);

        this.state = {
            addressType: '',
            addressTypes: [],
            addressTypesForPosting: [],
            locations: [],
            cities: [],
            countries: [],
            country: '',
            provinces: [],
            province: '',
            geographicZones: [],
            geographicZone: '',
            city: '',
            street: '',
            number: '',
            floor: '',
            apartment: '',
            neighborhood: '',
            zip: '',
            latitude: '',
            longitude: '',
            addressDefault: false,
            alertOpen: false,
            save: false,
            addressChecked: _.get(props.edit, 'latitude', false)  && _.get(props.edit, 'longitude', false),
            geolocationUpdated: false,
            loading: false,
        }
    }

    componentWillMount() {
        this.getCountries();
        this.getAddressTypes();
        if (this.props.edit) {
            const { addressType, addressDefault, countryId, department, floor, geographicZone, latitude, longitude, location, neighborhood, number, save, provinceId, street, zipcode, } = this.props.edit;
            this.setState({
                addressType: addressType && addressType.addressTypeId,
                number: number,
                neighborhood: neighborhood,
                street: street,
                zip: zipcode,
                city: location.locationId,
                apartment: department,
                floor: floor,
                province: provinceId !== 0 ? provinceId : '',
                country: countryId !== 0 ? countryId : '',
                geographicZone: geographicZone && geographicZone.geographicZoneId ? geographicZone.geographicZoneId : '',
                latitude: latitude ? latitude : '',
                longitude: longitude ? longitude : '',
                addressDefault: addressDefault,
                save: save,
            });
            if (countryId && countryId !== 0) {
                this.getProvinces(countryId);
            }

            if (provinceId && provinceId !== 0) {
                this.getCities(provinceId);
            }

            if (location.locationId && location.locationId !== 0) {
                this.getGeographicZones(location.locationId);
            }
        } 
    };

    componentDidUpdate () {
        if (this.shouldSearchForLocationInGoogle()) {
            navigator.geolocation.getCurrentPosition(pos => {
                const coords = pos.coords;
                ThirdPartiesInvoker.getGoogleLocationBasedOnLatLong(coords.latitude, coords.longitude, this.handleLocationFromGeoposition.bind(this));
            }, null, {maximumAge:60000, timeout:5000, enableHighAccuracy:true});
        }
    };

    shouldSearchForLocationInGoogle () {
        const state = this.state;
        return (
            !this.props.edit && 
            !state.geolocationUpdated &&
            navigator && 
            navigator.geolocation && 
            !_.isEmpty(state.countries)
        );
    };

    handleLocationFromGeoposition (responseJson) {
        if (responseJson.status === 'OK') {
            const addressComponents = responseJson.results[0].address_components;
            let city, region, country, locality;

            for (let i = 0; i < addressComponents.length; i++) {
                if (addressComponents[i].types[0] === 'locality') {
                    locality = addressComponents[i].long_name;
                }
                if (addressComponents[i].types[0] === 'administrative_area_level_2') {
                    city = addressComponents[i].long_name;
                }
                if (addressComponents[i].types[0] === 'administrative_area_level_1') {
                    region =addressComponents[i].long_name;
                }
                if (addressComponents[i].types[0] === 'country') {
                    country = addressComponents[i].long_name;
                }
            }

            this.openAlert('info', this.props.t('address.gmap.getLocation'), {
                geolocationUpdated: true, 
            });

            // this should be concatenated to wait for the services calls based on selected values
            this.getProvinces(
                this.getLocationFromStateByName('country', country),
                () => {
                    this.getCities(
                        this.getLocationFromStateByName('province', region),
                        () => {
                            this.getGeographicZones(
                                this.getLocationFromStateByName('city', [locality, city])
                            )
                        }
                    );
                }
            );
        }
    };

    getLocationFromStateByName (type, googleItem) {
        const state = this.state;
        const stateMap = {
            'country': state.countries,
            'province': state.provinces,
            'city': state.cities
        };
        const locationSelected = _.find(stateMap[type], (item) => {
            let value = _.get(item, 'value', '');            
            value = this.normalizeSpecialChars(value);
    
            if (_.isArray(googleItem)) {
                return _.some(googleItem, item => {
                    let normalizedGoogleItem = this.normalizeSpecialChars(item);
                    return (value && normalizedGoogleItem ? value.toLowerCase() === normalizedGoogleItem.toLowerCase() : false);
                });
            } else {
                const normalizedGoogleItem = this.normalizeSpecialChars(googleItem);
                return (value && normalizedGoogleItem ? value.toLowerCase() === normalizedGoogleItem.toLowerCase() : false);
            }
        }) || {};

        return locationSelected.id;
    };

    normalizeSpecialChars(value) {
        if (value) {
            return value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        } else {
            return value;
        }
    }

    handleValue(value, state) {
        this.setState({
            [state]: value,
            addressChecked: false,
        });
    }
    
    handleCountry(value) {
        this.setState({
            addressChecked: false,
            country: value,
            city: null,
            geographicZone: null
        }, () => {
            this.getProvinces(value);
        })
    }

    handleProvince(value) {
        this.setState({
            province: value,
            addressChecked: false,
            geographicZone: null
        }, () => {
            this.getCities(value);
        })
    }

    handleCities(value) {
        this.setState({
            city: value,
            addressChecked: false,
            geographicZone: null
        }, () => {
            this.getGeographicZones(value)
        })
    }

    getCountries() {
        ApiInvoker.getCountries(data => {
            this.formatCountries(data);
        }, null);
    }

    getProvinces(country, setStateCallBack) {
        ApiInvoker.getProvinces(country, data => {
            this.formatProvinces(data, setStateCallBack);
            this.setState({ country});
        }, null);
    }

    getCities(province, setStateCallBack) {
        ApiInvoker.getLocations(province, cities => {
            this.formatCities(cities, setStateCallBack);
            this.setState({ province });
        }, null);
    }

    getAddressTypes() {
        ApiInvoker.getAddressTypes(addressTypes => {
            this.setState({
                addressTypes: _.map(addressTypes, this.getAddressType),
                addressTypesForPosting: addressTypes,
            });
        });
    }

    getAddressType(addressType) {
        return {
            id: addressType.addressTypeId,
            value: addressType.name,
        };
    }

    getGeographicZones(city) {
        ApiInvoker.getGeographicZoneFromLocation(city, geographicZones => {
            this.formatGeographicZones(geographicZones);
            this.setState({ city });
        }, null);
    }

    checkGeocode (save) {
        const { t } = this.props;
        const { cities, city, countries, country, number, province, provinces, street } = this.state;
        const cityComplete = _.find(cities, (item) => item.id === city);
        const provinceComplete = _.find(provinces, (item) => item.id === province);
        const countryComplete = _.find(countries, (item) => item.id === country);
        
        if (street && number && cityComplete && provinceComplete && countryComplete) {
            const completeAddress = [
                street, number, cityComplete.value, provinceComplete.value, countryComplete.value
            ].join(' ');

            ThirdPartiesInvoker.getGoogleGeolocation(
                completeAddress, 
                responseJson => {
                    if (responseJson.status === 'OK') {
                        this.setState({
                            latitude: responseJson.results[0].geometry.location.lat,
                            longitude: responseJson.results[0].geometry.location.lng,
                            addressChecked: true,
                        }, ()=> {
                            //this.saveAddress();
                        });                        
                    } else {
                        this.openAlert('danger', t('address.gmap.errorCoordinates'));
                    }
                }, () => {
                    this.openAlert('danger', t('address.gmap.errorCoordinates'));
                }
            );
        } else {
            this.openAlert('warning', t('warning.message.fieldsComplete'));
        }

    }
    
    formatProvinces(provinces, setStateCallBack) {
        let result = [];
        result = provinces.map(p => {
            return {
                id: p.provinceId,
                value: p.name,
            }
        });

        this.setState({ provinces: result }, setStateCallBack);
    }

    formatCountries(countries) {
        let result = [];
        result = countries.map(c => {
            return {
                id: c.countryId,
                value: c.name,
            }
        });

        this.setState({ countries: result });
    }

    formatCities(cities, setStateCallBack) {
        let result = [];
        result = cities.map(c => {
            return {
                id: c.locationId,
                value: c.name
            }
        });

        this.setState({
            locations: cities,
            cities: result,
        }, setStateCallBack);
    }

    formatGeographicZones(geographicZones) {
        let result = [];
        result = geographicZones.map(gz => {
            return {
                id: gz.geographicZoneId,
                value: gz.detail,
            }
        });

        this.setState({ geographicZones: result });
    }

    saveAddress() {
        if (this.state.addressChecked) {
            if (this.props.edit) {
                this.props.edit.save = true;
                this.patchAddress();
            } else {
                this.setState({ save: true });
                this.postAddress();
            }
        } else {
            //this.checkGeocode(true);
            this.openAlert('danger', this.props.t('address.messageWngCheckAddress'));
        }
    }

    dataPost = (edit) => {
        const { personId } = this.props;
        const {
            addressDefault, apartment, floor, latitude, longitude, neighborhood, number, street, zip,
        } = this.state;

        const data = {
            personId: personId,
            location: this.findLocation(),
            latitude: (latitude ? latitude.toString().substring(0,14) : latitude),
            longitude: (longitude ? longitude.toString().substring(0,14) : longitude),
            department: apartment,
            floor: floor,
            addressType: this.getAddressTypeForPosting(),
            number: number,
            neighborhood: neighborhood,
            street: street,
            zipCode: zip,
            addressDefault: addressDefault,
            geographicZone: this.findGeographicZone(),
        }
        if (edit) {
            data.addressId = edit.addressId;
            data.address = edit.address;
        } else data.address = '';

        return data;
    }

    patchAddress() {
        this.setState({ loading: true });
        const { edit, personId, t } = this.props;
        const { addressType, city, geographicZone, neighborhood, number, street, zip } = this.state;

        if (addressType && number && neighborhood && street && zip && city && geographicZone) {
            PersonApiInvoker.postPersonsAddress(
                personId,
                edit.addressId,
                this.dataPost(edit),
                data => {
                    this.setState({ loading: false });
                    if (!data.message) {
                        this.openAlert('success', t('address.messageUpdateSuccess'));
                        this.props.onAddressSubmited(data);
                    } else this.openAlert('danger', data.message);
                }, error => {
                    this.setState({ loading: false });
                    const msg = error.message ?? t('customers.addresses.404.error');
                    this.openAlert('danger', msg);
                    console.error("** error de Fetch", error.message);
                }
            )
        } else {
            this.openAlert('warning', t('warning.message.fieldsComplete'));
            this.setState({ loading: false });
        }
    }

    postAddress() {
        this.setState({ loading: true });
        const { personId, t } = this.props;
        const { addressType, city, geographicZone, neighborhood, number, street, zip } = this.state;
        if (addressType && number && neighborhood && street && zip && city && geographicZone) {
            PersonApiInvoker.postPersonsNewAddress(
                personId,
                this.dataPost(null),
                data => {
                    this.setState({ loading: false });
                    if (!data.message) {
                        this.openAlert('success', t('address.messageSaveSuccess'));
                        this.props.onAddressSubmited(data);
                    } else this.openAlert('danger', data.message);
                }, error => {
                    this.setState({ loading: false });
                    const msg = error.message ?? t('customers.addresses.404.error');
                    this.openAlert('danger', msg);
                    console.error("** error de Fetch", error.message);
                }
            );
        } else {
            this.openAlert('warning', t('warning.message.fieldsComplete'));
            this.setState({ loading: false });
        }
    }

    findLocation() {
        let currentLocation = {};
        currentLocation = this.state.locations.find(l => l.locationId === this.state.city);
        return currentLocation;
    }

    findGeographicZone = () => {
        let currentGeographicZone = null;
        currentGeographicZone = this.state.geographicZones.map(gz => {
            return {
                companyId: localStorage.getItem('itlg_default_company_id'),
                detail: gz.value,
                geographicZoneId: gz.id,
                locationId: this.findLocation().locationId,
                metadata: null,
            }
        }).find(gz => gz.geographicZoneId === this.state.geographicZone);
        return currentGeographicZone;
    }

    openAlert(color, message, extraState) {
        this.setState(_.assign({
            alertColor: color,
            alertMessage: message,
            alertOpen: true,
        }, extraState));

        setTimeout(() => {
            this.setState({ alertOpen: false });
        }, 2000)
    }

    getAddressTypeForPosting() {
        const { addressType } = this.state;
        return _.find(this.state.addressTypesForPosting, (item) => item.addressTypeId === addressType);
    }

    disableAddressCheckButton = () => {
        return !(
            this.state.country &&
            this.state.city &&
            this.state.province &&
            this.state.number &&
            this.state.street
        );
    }

    renderGoogleMap = () => <MapComponent key="map" lat={this.state.latitude} lng={this.state.longitude} />;

    render() {
        const {
            addressDefault, addressType, addressTypes, alertColor, alertMessage, alertOpen, apartment, cities, city,
            countries, country, floor, geographicZone, geographicZones,
            latitude, loading, longitude, neighborhood, number, province, provinces, save, street, zip,
        } = this.state;
        const { t } = this.props;

        return (
            <GridContainer>
                <GridItem xs={12} sm={6} md="4">
                    <SelectInput
                        label={`${t('label.country')} *`}
                        elements={countries}
                        value={country}
                        onSelectedValue={(value) => this.handleCountry(value)}
                        invalid={country === '' && save}
                    />
                </GridItem>
                <GridItem xs={12} sm={6} md="4">
                    <SelectInput
                        label={`${t('label.province')} *`}
                        elements={provinces}
                        value={province}
                        onSelectedValue={(value) => this.handleProvince(value)}
                        invalid={province === '' && save}
                    />
                </GridItem>
                <GridItem xs={12} sm={6} md="4">
                    <SelectInput
                        label={`${t('label.location')} *`}
                        elements={cities}
                        value={city}
                        onSelectedValue={(value) => this.handleCities(value)}
                        invalid={city === '' && save}
                    />
                </GridItem>
                <GridItem xs={12} sm={6} md="4">
                    <SelectInput
                        label={`${t('label.geographicZone')} *`}
                        elements={geographicZones} value={geographicZone}
                        onSelectedValue={(value) => this.handleValue(value, 'geographicZone')}
                        invalid={geographicZone === '' && save}
                    />
                </GridItem>
                <GridItem xs={12} sm={6} md="4">
                    <ValidationInput
                        text={`${t('address.neighborhood')} *`}
                        value={neighborhood}
                        onChangeValue={(value) => this.handleValue(value, 'neighborhood')}
                        invalid={neighborhood === '' && save}
                    />
                </GridItem>
                <GridItem xs={12} sm={6} md="4">
                    <ValidationInput
                        text={`${t('address.street')} *`}
                        value={street}
                        onChangeValue={(value) => this.handleValue(value, 'street')}
                        invalid={street === '' && save}
                    />
                </GridItem>
                <GridItem xs={12} sm={6} md="4">
                    <ValidationInput
                        text={`${t('address.number')} *`}
                        type="number"
                        value={number}
                        onChangeValue={(value) => this.handleValue(value, 'number')}
                        invalid={number === '' && save}
                    />
                </GridItem>
                <GridItem xs={6} sm={3} md="2">
                    <ValidationInput
                        text={t('address.floor')}
                        type="number"
                        value={floor}
                        onChangeValue={(value) => this.handleValue(value, 'floor')}
                        invalid={false}
                    />
                </GridItem>
                <GridItem xs={6} sm={3} md="2">
                    <ValidationInput
                        text={t('address.apartment')}
                        type="text"
                        value={apartment}
                        onChangeValue={(value) => this.handleValue(value, 'apartment')}
                        invalid={false}
                    />
                </GridItem>
                <GridItem xs={12} sm={6} md="4">
                    <ValidationInput
                        text={`${t('address.zipCode')} *`}
                        value={zip}
                        onChangeValue={(value) => this.handleValue(value, 'zip')}
                        invalid={zip === '' && save}
                    />
                </GridItem>
                <GridItem xs={12} sm={6} md="4">
                    <SelectInput
                        label={`${t('address.addressType')} *`}
                        elements={addressTypes}
                        value={addressType}
                        onSelectedValue={(value) => this.handleValue(value, 'addressType')}
                        invalid={addressType === '' && save}
                    />
                </GridItem>
                <GridItem md={8} />
                <GridItem md={4}>
                    <CheckInput
                        onChangeValue={() => this.handleValue(!addressDefault, 'addressDefault')}
                        text={t('address.mainAddress')}
                        checked={addressDefault}
                    />
                </GridItem>
                <GridItem md={8} className="address-check-button text-right">
                    <Button
                        color="secondary"
                        onClick={() => this.checkGeocode(false)}
                        disabled={this.disableAddressCheckButton()}
                    >
                        {t('address.checkAddress')}
                    </Button>
                </GridItem>
                <GridContainer>
                    <GridItem className="gmap-container margin-top" xs={12}>
                        {this.renderGoogleMap()}
                    </GridItem>
                </GridContainer>
                <GridItem md={6}>
                    <ValidationInput text={t('address.latitude')} type="text" value={latitude} onChangeValue={(value) => this.handleValue(value, 'latitude')} disabled />
                </GridItem>
                <GridItem md={6}>
                    <ValidationInput text={t('address.longitude')} type="text" value={longitude} onChangeValue={(value) => this.handleValue(value, 'longitude')} disabled />
                </GridItem>
                <GridItem xs={12}>
                    <ButtonSpinner
                        className="create-address-submit"
                        onClick={() => this.saveAddress()}
                        disabled={loading}
                        label={t('address.save')}
                        labelLoading={t('address.saving')}
                        loading={loading}
                        typeButton="submit"
                        color="primary"
                    />
                    <Snackbar
                        place="tr"
                        color={alertColor}
                        message={alertMessage}
                        open={alertOpen}
                    />
                </GridItem>
            </GridContainer>
        )
    };
}

CreateAddress.propTypes = {
    personId: PropTypes.number,
    onGetLocations: PropTypes.func,
    onAddressSubmited: PropTypes.func,
    edit: PropTypes.object,
    invalid: PropTypes.bool,
}

export default withTranslation()(CreateAddress);
