import "../NewSchedule.css";

import React, { Component } from "react";

import BusinessApiInvoker from "../../../api/BusinessApiInvoker";
import Card from "../../Card/Card";
import CardBody from "../../Card/CardBody";
import CardHeader from "../../Card/CardHeader";
import CardIcon from "../../Card/CardIcon";
import DateInput from "../../DateInput";
import GridContainer from "../../Grid/GridContainer";
import GridItem from "../../Grid/GridItem";
import PropTypes from "prop-types";
import SelectInput from "../../SelectInput";
import ValidationInput from "../../ValidationInput";
import Work from "@material-ui/icons/Work";
import lodash from "lodash";
import { withTranslation } from "react-i18next";

class ProviderForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      financiers: [],
      financier: "",
      financierName: "",
      affiliateId: "",
      providers: [],
      provider: "",
      dateStart: "",
      dateEnd: "",
    };
  }

  componentDidMount() {
    this.getProviders();
    this.getFinanciers();

    if (this.props.value) {
      this.handleValue(this.props.value.financier, "financier");
      this.handleValue(this.props.value.provider, "provider");
      this.handleValue(this.props.value.dateStart, "dateStart");
      this.handleValue(this.props.value.dateEnd, "dateEnd");
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.customerId &&
      this.props.customerId &&
      !this.state.customerId
    ) {
      this.getFinanciers({ customerId: nextProps.customerId.customer.id });
      this.setState({
        customerId: this.props.customerId,
      });
    }
    if (
      nextProps.customerId &&
      this.props.customerId &&
      this.props.customerId.customer.id !== nextProps.customerId.customer.id
    ) {
      this.getFinanciers({ customerId: nextProps.customerId.customer.id });
    }
    if (nextProps.value && nextProps.value.provider) {
      this.setState({
        dateStart: nextProps.value.provider.dateStart,
        dateEnd: nextProps.value.provider.dateEnd,
      });
    }

    if (nextProps.value && nextProps.value.financier) {
      this.setState({
        affiliateId: nextProps.value.financier.affiliateId,
        financier: nextProps.value.financier.financierId,
        financierName: nextProps.value.financier.financierName,
      });
    }
    if (nextProps.value && !this.state.financiers.length) {
      this.getFinanciers({
        customerId: { customer: { id: nextProps.value.customerId.customer } },
      });
    }
  }

  getFinanciers = (value) => {
    let customerId;
    if (lodash.get(value, "customerId.customer", null)) {
      customerId = value.customerId;
    } else if (lodash.get(this, "props.customerId.customer", null)) {
      customerId = this.props.customerId;
    } else if (lodash.get(this, "props.value.customerId.customer", null)) {
      customerId = this.props.value.customerId;
    }
    if (customerId && customerId.customer) {
      BusinessApiInvoker.getCustomersFinanciers(
        customerId.customer.id,
        (data) => {
          if (!data.length) {
            this.setState(
              {
                financiers: [],
                financier: "",
                affiliateId: "",
              },
              () => {
                this.props.onFilledFinancier({
                  financierId: this.state.financier,
                  affiliateId: "",
                  financierName: "",
                });
              }
            );
          } else if (data.length === 1) {
            this.setState(
              {
                financiers: data,
              },
              () => {
                this.setFirstFinancier();
              }
            );
          } else {
            const financierIndex = this.state.financiers.findIndex(
              (item) =>
                item.financierId ===
                (value ||
                  (this.props.value &&
                    this.props.value.financier &&
                    this.props.value.financier.financierId))
            );
            if (financierIndex !== -1) {
              var financierName =
                this.state.financiers[financierIndex].financier.businessName;
              this.setState(
                {
                  financiers: data,
                  financier:
                    value ||
                    (this.props.value &&
                      this.props.value.financier.financierId),
                  affiliateId:
                    this.state.financiers.length &&
                    this.state.financiers[financierIndex].affiliateId,
                },
                () => {
                  if (this.props.onFilledFinancier) {
                    this.props.onFilledFinancier({
                      financierId: this.state.financier,
                      affiliateId: this.state.affiliateId,
                      financierName: financierName,
                    });
                  }
                }
              );
            } else {
              this.setState(
                {
                  financiers: data,
                },
                () => {
                  this.setFirstFinancier();
                }
              );
            }
          }
        },
        null
      );
    }
  };

  setFirstFinancier() {
    this.setState({
      financier:
        (this.state.financiers &&
          this.state.financiers[0] &&
          this.state.financiers[0].financier.financierId) ||
        "",
      affiliateId:
        (this.state.financiers &&
          this.state.financiers[0] &&
          this.state.financiers[0].affiliateId) ||
        "",
    });
    var financierName = this.state.financiers[0].financier.businessName;
    this.props.onFilledFinancier({
      financierId:
        (this.state.financiers &&
          this.state.financiers[0] &&
          this.state.financiers[0].financier.financierId) ||
        "",
      affiliateId:
        (this.state.financiers &&
          this.state.financiers[0] &&
          this.state.financiers[0].affiliateId) ||
        "",
      financierName: financierName,
    });
  }

  getProviders(data) {
    const { value, disabled } = this.props;
    BusinessApiInvoker.getServiceProviders((providers) => {
      this.setState(
        {
          providers: this.formatProviders(providers),
          provider:
            data ||
            (disabled && value.provider && value.provider.serviceProviderId),
        },
        () => {
          this.setState({
            provider: this.state.providers[0]
              ? this.state.providers[0].id
              : null,
          });
        }
      );
    }, null);
  }

  formatProviders(providers) {
    let result = providers.map((p) => {
      return {
        id: p.serviceProviderId,
        value: p.fantasyName,
      };
    });
    return result;
  }

  formatFinanciers(financiers) {
    let result = financiers.map((f) => {
      return {
        id: f.financier.financierId,
        value: f.financier.businessName,
        affiliateId: f.affiliateId,
      };
    });
    return result;
  }

  handleValue(value, state) {
    this.setState(
      {
        [state]: value,
      },
      () => {
        this.props.onFilledProvider({
          provider: this.state.provider,
          dateStart: this.state.dateStart,
          dateEnd: this.state.dateEnd,
        });

        if (
          state === "dateStart" &&
          this.state.dateEnd &&
          this.state.dateEnd < value
        ) {
          this.setState(
            {
              dateEnd: "",
            },
            () => {
              this.props.onFilledProvider({
                dateStart: this.state.dateStart,
                dateEnd: this.state.dateEnd,
              });
            }
          );
        }

        if (state === "financier") {
          if (!this.state.financiers) {
            this.getFinanciers(value);
          } else {
            const financierIndex = this.state.financiers.findIndex(
              (item) => item.financierId === value
            );

            if (financierIndex !== -1) {
              this.setState(
                {
                  affiliateId:
                    this.state.financiers[financierIndex].affiliateId,
                },
                () => {
                  this.props.onFilledFinancier({
                    financierId: this.state.financier,
                    affiliateId: this.state.affiliateId,
                  });
                }
              );
            }
          }
        }
      }
    );
  }

  render() {
    const {
      financiers,
      financier,
      affiliateId,
      providers,
      provider,
      dateStart,
      dateEnd,
      financierName,
    } = this.state;
    const { t, disabled, checkInvalid } = this.props;
    return (
      <GridItem
        className="provider-form-component schedule-form"
        xs={12}
        sm={12}
        md={12}
      >
        <Card>
          <CardHeader icon>
            <CardIcon color="primary">
              <Work />
            </CardIcon>
            <h3 className="card-icon-title">
              {t("appointment.financialData")}
            </h3>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem className="fullWidth-input" xs={12} sm={6}>
                <SelectInput
                  fullWidth
                  disabled={disabled}
                  label={t("appointment.new.provider.financier")}
                  elements={this.formatFinanciers(financiers)}
                  onSelectedValue={(value) =>
                    this.handleValue(value, "financier")
                  }
                  value={financier}
                  invalid={!financier && checkInvalid}
                  errorText={
                    !financier && checkInvalid ? t("error.required") : ""
                  }
                />
              </GridItem>
              <GridItem
                className="fullWidth-input validation-input"
                xs={12}
                sm={6}
              >
                <ValidationInput
                  fullWidth
                  value={affiliateId || ""}
                  onChangeValue={() => {}}
                  disabled
                  text={t("appointment.new.provider.affiliate_identifier")}
                  invalid={!affiliateId && checkInvalid}
                  errorText={
                    !affiliateId && checkInvalid ? t("error.required") : ""
                  }
                />
              </GridItem>

              <GridItem className="fullWidth-input" xs={12}>
                <SelectInput
                  disabled={disabled}
                  label={t("appointment.new.provider.provider")}
                  elements={providers}
                  onSelectedValue={(value) =>
                    this.handleValue(value, "provider")
                  }
                  value={
                    this.state.provider ||
                    (this.props.value && this.props.value.provider
                      ? this.props.value.provider.serviceProviderId
                      : this.state.provider)
                  }
                  invalid={!provider && checkInvalid}
                  errorText={
                    !provider && checkInvalid ? t("error.required") : ""
                  }
                />
              </GridItem>
              <GridItem className="fullWidth-input" xs={12} sm={6}>
                <DateInput
                  disabled={disabled}
                  text={t("appointment.new.provider.date_start")}
                  minDate
                  onChangeValue={(value) =>
                    this.handleValue(value, "dateStart")
                  }
                  value={dateStart}
                  invalid={!dateStart && checkInvalid}
                  errorText={
                    !dateStart && checkInvalid ? t("error.required") : ""
                  }
                  inputProps={{
                    disabled: disabled,
                  }}
                />
              </GridItem>
              <GridItem className="fullWidth-input" xs={12} sm={6}>
                <DateInput
                  disabled={disabled}
                  text={t("appointment.new.provider.date_end")}
                  minDate
                  min={dateStart}
                  onChangeValue={(value) => this.handleValue(value, "dateEnd")}
                  value={dateEnd}
                  invalid={!dateEnd && checkInvalid}
                  errorText={
                    !dateEnd && checkInvalid ? t("error.required") : ""
                  }
                  inputProps={{
                    disabled: disabled,
                  }}
                />
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
    );
  }
}

ProviderForm.defaultProps = {
  onFilledProvider: () => {},
  onFilledFinancier: () => {},
};

ProviderForm.propTypes = {
  onFilledProvider: PropTypes.func.isRequired,
  onFilledFinancier: PropTypes.func.isRequired,
  value: PropTypes.shape({
    dateEnd: PropTypes.string,
    dateStart: PropTypes.string,
    financier: PropTypes.any,
    provider: PropTypes.any,
  }),
  checkInvalid: PropTypes.bool,
  disabled: PropTypes.bool,
  customer: PropTypes.object,
};

export default withTranslation()(ProviderForm);
