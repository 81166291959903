import "./DriversStyles.css";

import React, { useEffect, useState } from "react";

import ApiInvoker from "../../api/ApiInvoker";
import AssignmentIcon from "@material-ui/icons/Assignment";
import Button from "../../components/CustomButtons/Button";
import Card from "../../components/Card/Card";
import CardBody from "../../components/Card/CardBody";
import CardHeader from "../../components/Card/CardHeader";
import CardIcon from "../../components/Card/CardIcon";
import CustomInput from "../../components/CustomInput/CustomInput";
import { Drivers as DriversIcon } from "../../icons";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import Snackbar from "../../components/Snackbar/Snackbar";
import Table from "../../components/Table/Table";
import TableRowActionsButtons from "../../components/TableRowActionsButtons";
import { withTranslation } from "react-i18next";

const showSnackbarInitialValue = {
  open: false,
  color: "danger",
  message: "",
};

const Drivers = ({ t }) => {
  const [loading, setLoading] = useState(false);
  const [drivers, setDrivers] = useState([]);
  const [page, setPage] = useState(0);
  const [id, setId] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [vehicle, setVehicle] = useState("");
  const [brand, setBrand] = useState("");
  const [model, setModel] = useState("");

  const [showSnackbar, setShowSnackbar] = useState(showSnackbarInitialValue);

  const cleanFilters = () => {
    setId("");
    setFirstName("");
    setLastName("");
    setEmail("");
    setBrand("");
    setModel("");
  };

  const getAllDrivers = () => {
    getDrivers("", "", "", "", "", "");
  };

  const openSnackbar = (message, color = "danger") => {
    setShowSnackbar({
      color,
      message,
      open: true,
    });

    setTimeout(() => {
      setShowSnackbar(showSnackbarInitialValue);
    }, 2000);
  };

  const getDrivers = (id, firstName, lastName, email, brand, model) => {
    if (
      id !== "" ||
      firstName !== "" ||
      lastName !== "" ||
      email !== "" ||
      brand !== "" ||
      model !== ""
    ) {
      let filter = {
        id,
        firstName,
        lastName,
        email,
        vehicle,
        brand,
        model,
      };

      let params = Object.keys(filter)
        .map((k) => {
          let value = filter[k];
          if (value !== "") {
            return `${encodeURIComponent(k)}=${encodeURIComponent(value)}`;
          } else {
            return null;
          }
        })
        .join("&");
      if (params !== "") {
        params = `${params}&`;
      }
      params = `${params}page=${page}&pageSize=${20}`;
      setLoading(true);
      ApiInvoker.getDrivers(
        params,
        (data) => {
          formatDrivers(data);
          setLoading(false);
        },
        (error) => {
          setLoading(false);
          openSnackbar(t("common.errorMessage"));
          console.log("** error", error);
        }
      );
    } else {
      let params = `&page=${page}&pageSize=${20}`;
      setLoading(true);
      ApiInvoker.getDrivers(
        params,
        (data) => {
          formatDrivers(data);
          setLoading(false);
        },
        (error) => {
          setLoading(false);
          openSnackbar(t("common.errorMessage"));
          console.log("** error", error);
        }
      );
    }
  };

  const formatDrivers = (data) => {
    const dataDrivers = data.map((d) => {
      return {
        lastName: d.lastName,
        firstName: d.firstName,
        id: d.personId,
        email: d.email,
        zoneGeographics: d.geographicZone ? d.geographicZone.detail : "",
        identification: d.idNumber,
        vehicle: d.vehicle.brand + " " + d.vehicle.model,
        actions: (
          <div className="drivers-actions">
            <TableRowActionsButtons
              buttons={[
                {
                  icon: AssignmentIcon,
                  state: { mode: "view", driverVehicleId: d.personId },
                  pathname: `/driver/${d.personId}`,
                  messageTooltip: "common.view",
                },
              ]}
            />
          </div>
        ),
      };
    });
    setDrivers(dataDrivers);
  };

  const find = () => {
    getDrivers(id, firstName, lastName, email, brand, model);
  };

  useEffect(() => {
    getDrivers("", "", "", "", "", "");
    return () => {
      setDrivers([]);
      setLoading(false);
    };
  }, []);

  return (
    <>
      <Snackbar
        open={showSnackbar.open}
        color={showSnackbar.color}
        message={t(showSnackbar.message)}
        place="tr"
      ></Snackbar>
      <GridContainer className="drivers-list">
        <GridItem xs={12}>
          <Card>
            <CardHeader icon>
              <CardIcon color="primary">
                <DriversIcon />
              </CardIcon>
              <GridContainer className="appointments-filters">
                <GridItem xs={10} sm={9} md={10} className="no-padding">
                  <GridContainer>
                    <GridItem xs={4}>
                      <CustomInput
                        className="filter-full no-padding"
                        labelText={t("label.identification")}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        value={id}
                        onChange={(value) => setId(value.target.value)}
                        errorText={t("error.required")}
                      />
                    </GridItem>
                    <GridItem xs={4}>
                      <CustomInput
                        className="filter-full no-padding"
                        labelText={t("label.firstName")}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        value={firstName}
                        onChange={(value) => setFirstName(value.target.value)}
                        errorText={t("error.required")}
                      />
                    </GridItem>
                    <GridItem xs={4}>
                      <CustomInput
                        className="filter-full no-padding"
                        labelText={t("label.lastName")}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        value={lastName}
                        onChange={(value) => setLastName(value.target.value)}
                        errorText={t("error.required")}
                      />
                    </GridItem>
                    <GridItem xs={4}>
                      <CustomInput
                        className="filter-full no-padding"
                        labelText={t("label.email")}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        value={email}
                        onChange={(value) => setEmail(value.target.value)}
                        errorText={t("error.required")}
                      />
                    </GridItem>
                    <GridItem xs={4}>
                      <p className="label-search">
                        {t("drivers.find.vehicle")}:
                      </p>
                      <CustomInput
                        className="filter-full no-padding"
                        labelText={"Marca"}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        value={brand}
                        onChange={(value) => setBrand(value.target.value)}
                        errorText={t("error.required")}
                      />
                    </GridItem>
                    <GridItem xs={4}>
                      <CustomInput
                        className="filter-full no-padding"
                        labelText={"Modelo"}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        value={model}
                        onChange={(value) => setModel(value.target.value)}
                        errorText={t("error.required")}
                      />
                    </GridItem>
                  </GridContainer>
                </GridItem>

                <GridItem xs={2}>
                  <GridContainer className="appointments-filters" style={{}}>
                    <GridItem className="filters-actions" xs={12}>
                      <Button block onClick={() => find()} color="primary">
                        {t("common.find")}
                      </Button>
                    </GridItem>
                    <GridItem className="filters-actions" xs={12}>
                      <Button
                        block
                        onClick={() => {
                          cleanFilters();
                          getAllDrivers();
                        }}
                        color="danger"
                      >
                        {t("common.clean")}
                      </Button>
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
            </CardHeader>
            <CardBody className="filtrable-table">
              <Table
                striped
                filterable
                loading={loading}
                tableHeaderColor="primary"
                tableHead={[
                  { Header: t("label.firstName"), accessor: "firstName" },
                  { Header: t("label.lastName"), accessor: "lastName" },
                  {
                    Header: t("label.identification"),
                    accessor: "identification",
                  },
                  { Header: t("label.email"), accessor: "email" },
                  { Header: t("label.vehicle"), accessor: "vehicle" },
                  {
                    Header: t("label.geographicZone"),
                    accessor: "zoneGeographics",
                  },
                  {
                    Header: t("label.actions"),
                    accessor: "actions",
                    sortable: false,
                    filterable: false,
                  },
                ]}
                defaultPageSize={20}
                tableData={drivers}
                colorsColls={["primary"]}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </>
  );
};

export default withTranslation()(Drivers);
