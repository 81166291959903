import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import ReactTable from 'react-table';
import CircularProgress from '@material-ui/core/CircularProgress';
import withStyles from "@material-ui/core/styles/withStyles";
import tableStyle from "../../assets/components/tableStyle";
import '../../assets/plugins/react-table.css';

function filterCaseInsensitive(filter, row) {  
  const id = filter.pivotId || filter.id;
	return (
    row[id] !== undefined
    ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase())
		: false
	);
}

function CustomTable({ ...props }) {
  const {
    tableHead,
    tableData,
    sortable,
    pageSizeOptions,
    defaultPageSize,
    showPaginationTop,
    showPaginationBottom,
    loading,
    SubComponent,
    onRowClick,
    t,
    filterable,
    customClasses
  } = props;

  return (
    loading
    ? <div style={{
        display: 'flex',
        height: '200px',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircularProgress />
    </div> 
    : <ReactTable
      data={tableData}
      sortable={sortable}
      filterable={filterable}
      pageSizeOptions={pageSizeOptions || [5, 10, 20, 25, 50, 75, 100, 125]}
      defaultPageSize={defaultPageSize}
      showPaginationTop={showPaginationTop}
      showPaginationBottom={showPaginationBottom}
      className={`-striped -highlight ${customClasses}`}
      columns={tableHead}
      defaultFilterMethod={filterCaseInsensitive}
      SubComponent={SubComponent}
      getTrProps={(state, rowInfo, column) => {
        return {
            onClick: (e, t) => { 
              if (typeof onRowClick !== 'undefined') {
                onRowClick(e, t, rowInfo)
              }
            }
          }
        }
      }
      // Text
      previousText={t("table.previous")}
      nextText={t("table.next")}
      loadingText={t("table.loading")}
      noDataText={t("table.empty")}
      pageText={t("table.page")}
      ofText={t("table.of")}
      rowsText={t("table.rows")}
    />
  )
}

CustomTable.defaultProps = {
  tableHeaderColor: "gray",
  hover: false,
  colorsColls: [],
  coloredColls: [],
  striped: false,
  loading: false,
  customCellClasses: [],
  customClassesForCells: [],
  customHeadCellClasses: [],
  customHeadClassesForCells: []
};

CustomTable.propTypes = {
  loading: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "violet",
    "gray"
  ]),
  onRowClick: PropTypes.func,
  tableData: PropTypes.array,
  hover: PropTypes.bool,
  coloredColls: PropTypes.arrayOf(PropTypes.number),
  colorsColls: PropTypes.array,
  customCellClasses: PropTypes.arrayOf(PropTypes.string),
  customClassesForCells: PropTypes.arrayOf(PropTypes.number),
  customHeadCellClasses: PropTypes.arrayOf(PropTypes.string),
  customHeadClassesForCells: PropTypes.arrayOf(PropTypes.number),
  striped: PropTypes.bool,
  tableShopping: PropTypes.bool,
};

export default withStyles(tableStyle)(withTranslation()(CustomTable));
