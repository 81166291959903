import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { withTranslation } from 'react-i18next';
import cx from 'classnames';
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from 'perfect-scrollbar';
// @material-ui/core components
import Collapse from '@material-ui/core/Collapse';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import Icon from '@material-ui/core/Icon';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import withStyles from '@material-ui/core/styles/withStyles';
import CloseIcon from '@material-ui/icons/Close';
import Button from '../CustomButtons/Button';
import UserService from '../../containers/UserService';
import Authority from '../../util/Authority';
import avatar from '../../assets/img/avatar.png';
import pwdBy from '../../assets/img/glin-white.png';
import hmmLogo from '../../assets/img/logo-hmm-white.png';
import sidebarStyle from '../../assets/components/sidebarStyle';
import './Sidebar.css';

let ps;

const SidebarFooter = ({ logos }) => {
  return (
    <div className="logos">
      {logos.length > 0 && (
        logos.map(({title, linkTo, img}, index) => (
          <a key={`sidebarfooter-link-${index}`} href={linkTo} target={linkTo !=='#' ? '_blank': ''} rel="noopener noreferrer">
            <img className={title ? title.toLowerCase(): ''} src={img} title={title} alt={title} />
          </a>
        ))
      )}
    </div>
  )
}
// We've created this component so we can have a ref to the wrapper of the links that appears in our sidebar.
// This was necessary so that we could initialize PerfectScrollbar on the links.
// There might be something with the Hidden component from material-ui, and we didn't have access to
// the links, and couldn't initialize the plugin.
class SidebarWrapper extends React.Component {
  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.refs.sidebarWrapper, {
        suppressScrollX: true,
        suppressScrollY: false
      });
    }
  }

  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
  }

  render() {
    const { className, headerLinks, links, miniActive } = this.props;
    const logos = [
      { img: pwdBy, title:'Glin', linkTo: 'http://glin-technologies.com/'}
    ];

    return (
      <div className={className} ref="sidebarWrapper">
        {headerLinks}
        {links}
        { !miniActive && <SidebarFooter logos={logos} /> }
      </div>
    );
  }
}

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openAvatar: false,
      miniActive: true,
      ...this.getCollapseStates(props.routes)
    };
  }
  // this creates the intial state of this component based on the collapse routes
  // that it gets through this.props.routes
  getCollapseStates = routes => {
    let initialState = {};
    routes.map((prop, key) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: this.getCollapseInitialState(prop.views),
          ...this.getCollapseStates(prop.views),
          ...initialState
        };
      }
      return null;
    });
    return initialState;
  };

  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.jsx - route /admin/regular-forms
  getCollapseInitialState(routes) {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && this.getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (window.location.href.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  }
  // verifies if routeName is the one active (in browser input)
  activeRoute = routeName => {
    if (this.props.location) {
      return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
    }
  };
  openCollapse(collapse) {
    const st = {};
    st[collapse] = !this.state[collapse];
    this.setState(st);
  }
  // this function creates the links and collapses that appear in the sidebar (left menu)
  createLinks = (routes) => {
    routes = routes.filter(item => Authority.hasPermission(item.authority));

    const { classes, color, miniActive, rtlActive, t } = this.props;
    return routes.map((prop, key) => {
      if (!prop.hidden) {
      if (prop.redirect) {
        return null;
      }
      if (prop.collapse) {
        const st = {};
        st[prop["state"]] = !this.state[prop.state];
        const navLinkClasses =
          classes.itemLink +
          " " +
          cx({
            [" " + classes.collapseActive]: this.getCollapseInitialState(
              prop.views
            )
          });
        const itemText =
          classes.itemText +
          " " +
          cx({
            [classes.itemTextMini]:
              miniActive && this.state.miniActive,
            [classes.itemTextMiniRTL]:
              rtlActive && miniActive && this.state.miniActive,
            [classes.itemTextRTL]: rtlActive
          });
        const collapseItemText =
          classes.collapseItemText +
          " " +
          cx({
            [classes.collapseItemTextMini]:
              miniActive && this.state.miniActive,
            [classes.collapseItemTextMiniRTL]:
              rtlActive && miniActive && this.state.miniActive,
            [classes.collapseItemTextRTL]: rtlActive
          });
        const itemIcon =
          classes.itemIcon +
          " " +
          cx({
            [classes.itemIconRTL]: rtlActive
          });
        const caret =
          classes.caret +
          " " +
          cx({
            [classes.caretRTL]: rtlActive
          });
        const collapseItemMini =
          classes.collapseItemMini +
          " " +
          cx({
            [classes.collapseItemMiniRTL]: rtlActive
          });

        return (
          <ListItem
            key={key}
            className={cx(
              { [classes.item]: prop.icon !== undefined },
              { [classes.collapseItem]: prop.icon === undefined }
            )}
          >
            <Link
              to={"#"}
              className={navLinkClasses}
              activeClassName={`${classes[color]} active`}
              onClick={e => {
                e.preventDefault();
                this.setState(st);
              }}
            >
              {prop.icon !== undefined ? (
                typeof prop.icon === "string" ? (
                  <Icon className={itemIcon}>{prop.icon}</Icon>
                ) : (
                  <prop.icon className={itemIcon} />
                )
              ) : (
                <span className={collapseItemMini} style={{ paddingLeft: `${prop.inset}px` }}>
                  {rtlActive ? t(prop.rtlMini) : t(prop.mini)}
                </span>
              )}
              <ListItemText
                primary={rtlActive ? t(prop.rtlName) : t(prop.name)}
                style={{ paddingLeft: (prop.mini !== '' ? 0 : `${prop.inset}px`) }}
                secondary={
                  <b
                    className={
                      caret +
                      " " +
                      (this.state[prop.state] ? classes.caretActive : "")
                    }
                  />
                }
                disableTypography={true}
                className={cx(
                  { [itemText]: prop.icon !== undefined },
                  { [collapseItemText]: prop.icon === undefined }
                )}
              />
            </Link>
            <Collapse in={this.state[prop.state]} unmountOnExit>
              <List className={classes.list + " " + classes.collapseList}>
                {this.createLinks(prop.views)}
              </List>
            </Collapse>
          </ListItem>
        );
      }
      const innerNavLinkClasses =
        classes.collapseItemLink +
        " " +
        cx({
          [" " + classes[color]]: this.activeRoute(prop.path)
        });
      const collapseItemMini =
        classes.collapseItemMini +
        " " +
        cx({
          [classes.collapseItemMiniRTL]: rtlActive
        });
      const navLinkClasses =
        classes.itemLink +
        " " +
        cx({
          [" " + classes[color]]: this.activeRoute(prop.path)
        });
      const itemText =
        classes.itemText +
        " " +
        cx({
          [classes.itemTextMini]:
            miniActive && this.state.miniActive,
          [classes.itemTextMiniRTL]:
            rtlActive && miniActive && this.state.miniActive,
          [classes.itemTextRTL]: rtlActive
        });
      const collapseItemText =
        classes.collapseItemText +
        " " +
        cx({
          [classes.collapseItemTextMini]:
            miniActive && this.state.miniActive,
          [classes.collapseItemTextMiniRTL]:
            rtlActive && miniActive && this.state.miniActive,
          [classes.collapseItemTextRTL]: rtlActive
        });
      const itemIcon =
        classes.itemIcon +
        " " +
        cx({
          [classes.itemIconRTL]: rtlActive
        });
        return (
          <ListItem
            key={key}
            className={cx(
              { [classes.item]: prop.icon !== undefined },
              { [classes.collapseItem]: prop.icon === undefined },
            )}
          >
            <Link
              to={prop.enabled ? prop.layout + prop.path : ''}
              className={cx(
                { [navLinkClasses]: prop.icon !== undefined },
                { [innerNavLinkClasses]: prop.icon === undefined },
                { [classes.disabled]: !prop.enabled }
              )}
              activeClassName={`${classes[color]} active`}
            >
              {prop.icon !== undefined ? (
                typeof prop.icon === "string" ? (
                  <Icon className={itemIcon}>{prop.icon}</Icon>
                ) : (
                  <prop.icon className={itemIcon} />
                )
              ) : (
                <span className={collapseItemMini} style={{paddingLeft: `${prop.inset}px` }}>
                  {rtlActive ? t(prop.rtlMini) : t(prop.mini)}
                </span>
              )}
              <ListItemText
                primary={rtlActive ? t(prop.rtlName) : t(prop.name)}
                disableTypography={true}
                style={{ paddingLeft: (prop.mini !== '' ? 0 : `${prop.inset}px`) }}
                className={cx(
                  { [itemText]: prop.icon !== undefined },
                  { [collapseItemText]: prop.icon === undefined }
                )}
              />
            </Link>
          </ListItem>
        );
      }
    });
  };

  render() {
    const {
      classes,
      logo,
      image,
      logoText,
      routes,
      bgColor,
      rtlActive,
      miniActive,
      open
    } = this.props;
    const itemText =
      classes.itemText +
      " " +
      cx({
        [classes.itemTextMini]: miniActive && this.state.miniActive,
        [classes.itemTextMiniRTL]:
          rtlActive && miniActive && this.state.miniActive,
        [classes.itemTextRTL]: rtlActive
      });
    const collapseItemText =
      classes.collapseItemText +
      " " +
      cx({
        [classes.collapseItemTextMini]:
          miniActive && this.state.miniActive,
        [classes.collapseItemTextMiniRTL]:
          rtlActive && miniActive && this.state.miniActive,
        [classes.collapseItemTextRTL]: rtlActive
      });
    const userWrapperClass =
      classes.user +
      " " +
      cx({
        [classes.whiteAfter]: bgColor === "white"
      });
    const caret =
      classes.caret +
      " " +
      cx({
        [classes.caretRTL]: rtlActive
      });
    const collapseItemMini =
      classes.collapseItemMini +
      " " +
      cx({
        [classes.collapseItemMiniRTL]: rtlActive
      });
    const photo =
      classes.photo +
      " " +
      cx({
        [classes.photoRTL]: rtlActive
      });
    const user = (
      <div className={userWrapperClass}>
        <div className={photo}>
          <img src={avatar} className={classes.avatarImg} alt="..." />
        </div>
        <List className={classes.list}>
          <ListItem className={classes.item + " " + classes.userItem}>
            <Link
              to={"#"}
              className={classes.itemLink + " " + classes.userCollapseButton}
              activeClassName="active"
              onClick={() => this.openCollapse("openAvatar")}
            >
              <ListItemText
                primary={this.props.user}
                disableTypography={true}
                className={itemText + " " + classes.userItemText}
              />
            </Link>
          </ListItem>
        </List>
      </div>
    );
    const links = (
      <List className={classes.list}>{this.createLinks(routes)}</List>
    );

    const logoNormal =
      classes.logoNormal +
      " " +
      cx({
        [classes.logoNormalSidebarMini]:
          miniActive && this.state.miniActive,
        [classes.logoNormalSidebarMiniRTL]:
          rtlActive && miniActive && this.state.miniActive,
        [classes.logoNormalRTL]: rtlActive
      });
    const logoMini =
      classes.logoMini +
      " " +
      cx({
        [classes.logoMiniRTL]: rtlActive
      });
    const logoClasses =
      classes.logo +
      " " +
      cx({
        [classes.whiteAfter]: bgColor === "white"
      });
    const brand = (
      <div className={`${logoClasses}logo-sidebar ${!logo ? 'companyName': ''}`}>
        <img src={hmmLogo} alt={logoText} className={classes.img} />
      </div>
    );
    
    const drawerPaper =
      classes.drawerPaper +
      " " +
      cx({
        [classes.drawerPaperMini]:
          miniActive && this.state.miniActive,
        [classes.drawerPaperRTL]: rtlActive
      });
    const sidebarWrapper =
      classes.sidebarWrapper +
      " " +
      cx({
        [classes.drawerPaperMini]:
          miniActive && this.state.miniActive,
        [classes.sidebarWrapperWithPerfectScrollbar]:
          navigator.platform.indexOf("Win") > -1
      });
    return (
      <div ref="mainPanel">
        <Hidden mdUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={rtlActive ? "left" : "right"}
            open={open}
            classes={{
              paper: drawerPaper + " " + classes[bgColor + "Background"]
            }}
            onClose={this.props.handleDrawerToggle}
            ModalProps={{
              keepMounted: true // Better open performance on mobile.
            }}
          >
            <Button
                className={`${classes.appResponsive} closeModalTopFixButton`}
                color="transparent"
                justIcon
                aria-label="open drawer"
                onClick={() => this.props.handleDrawerToggle()}
              >
              <CloseIcon />
            </Button>
            {brand}
            <SidebarWrapper
              className={`${miniActive && this.state.miniActive} ps`} 
              user={user}
              miniActive={this.state.miniActive}
              headerLinks={<UserService.NavbarService rtlActive={rtlActive} />}
              links={links}
            />
            {image !== undefined ? (
              <div
                className={`${classes.background} backgroundTablet`}
                style={{ backgroundImage: `url('${image}')`}}
              />
            ) : null}
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            onMouseOver={() => this.setState({ miniActive: false })}
            onMouseOut={() => this.setState({ miniActive: true })}
            anchor={rtlActive ? "right" : "left"}
            variant="permanent"
            open
            classes={{
              paper: drawerPaper + " " + classes[bgColor + "Background"]
            }}
          >
            {brand}
            <SidebarWrapper
              className={sidebarWrapper}
              user={user}
              miniActive={miniActive && this.state.miniActive}
              links={links}
            />
            {image !== undefined ? (
              <div
                className={classes.background}
                style={{ backgroundImage: `url('${image}')` }}
              />
            ) : null}
          </Drawer>
        </Hidden>
      </div>
    );
  }
}

Sidebar.defaultProps = {
  bgColor: "black"
};

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired,
  bgColor: PropTypes.oneOf(["primary", "white", "black", "blue"]),
  rtlActive: PropTypes.bool,
  color: PropTypes.oneOf([
    "white",
    "red",
    "orange",
    "green",
    "blue",
    "purple",
    "violet",
    "transparent",
  ]),
  logo: PropTypes.string,
  logoText: PropTypes.string,
  image: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  user: PropTypes.string,
  enabled: PropTypes.bool,
  companies: PropTypes.array,
  selectedCompany: PropTypes.number
};

const mapStateToProps = (state) => {
  return {
    selectedCompany: state.user.selectedCompany
  };
}
export default connect(mapStateToProps, null)(withStyles(sidebarStyle)(withTranslation()(Sidebar)));
