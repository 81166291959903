import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ApiInvoker from '../../api/ApiInvoker';
import Card from '../Card/Card';
import CardBody from '../Card/CardBody';
import CardHeader from '../Card/CardHeader';
import CardIcon from '../Card/CardIcon';
import CustomCheckbox from '../CustomCheckbox';
import GridItem from '../Grid/GridItem';
import Table from '../Table/Table';
import { Address as AddressIcon } from '../../icons';

const PersonAddress = ({t, personId, openSnackbar}) => {
  const [address, setAddress] = useState([]);

  const formatAdress = (data) => {
    const addressList =  data && data.length > 0 ? data.map((a) => ({
      addresstype: a.addressType.name,
      street: a.street,
      number: a.number,
      city: a.locationName,
      province: a.provinceName,
      default: <CustomCheckbox checked={a.addressDefault} />,
    })) : [];
    return addressList;
  }

  const getDriverAddress = () => {
    ApiInvoker.getPersonAddress(personId, (data) => {
      const ad = formatAdress(data);
      setAddress(ad);
      return;
    }, (error) => {
      openSnackbar(t('common.errorMessage'));
      console.error('** error', error);
    })
  }

  useEffect(() => {
    getDriverAddress();
    return () => {
      setAddress([]);
    }
  }, [])
  

  return (
    <GridItem xs={12} className="specialities-practices-view">
          <Card className="employee-adresses">
            <CardHeader icon>
              <CardIcon color="secondary">
                <AddressIcon />
              </CardIcon>
              <h3 className="card-icon-title">{t('employee.new.Addresses')}</h3>
            </CardHeader>
            <CardBody>
                <Table
                  striped
                  filterable
                  tableHeaderColor="primary"
                  tableHead={[
                      { Header: t('employees.table.addresstype'), accessor: 'addresstype' },
                      { Header: t('employees.table.street'), accessor: 'street' },
                      { Header: t('employees.table.number'), accessor: 'number' },
                      { Header: t('employees.table.city'), accessor: 'city' },
                      { Header: t('employees.table.province'), accessor: 'province' },
                      { Header: t('employees.table.main'), accessor: 'default' },
                  ]}
                  tableData={address}
                  defaultPageSize={10}
                  colorsColls={['primary']}
                />
            </CardBody>
          </Card>
        </GridItem>
  )
}

PersonAddress.defaultProps = {
  openSnackbar: () => {},
}

PersonAddress.propTypes = {
  personId: PropTypes.number.isRequired,
  openSnackbar: PropTypes.func,
}

export default withTranslation()(PersonAddress);
