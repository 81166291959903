import { makeStyles, Tooltip } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import ApiInvoker from '../../api/ApiInvoker';
import Button from '../../components/CustomButtons/Button';
import CustomInput from '../../components/CustomInput/CustomInput';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import SelectInput from '../../components/SelectInput';

const useStyles = makeStyles(() => ({
  filtersGrid: { marginBottom: 16 },
  buttonsGrid: { marginTop: 40 },
}));

const initFilters = {
  customerId:'', firstName: '', lastName: '', email: '', provinceId: '', locationId: '',
}

const CustomersFilters = ({ t, onCleanFilters, onFindFilters }) => {
  const classes = useStyles();

  const [provinces, setProvinces] = useState([]);
  const [locations, setLocations] = useState([]);
  const [locationsDisabled, setLocationsDisabled] = useState(true);
  const [filters, setFilters] = useState(initFilters);

  const onFilterProvince = (value) => {
    setFilters((prev) => ({ ...prev, locationId: null }));
    setLocationsDisabled(!(!!value));
    if(value) {
      getLocationsByProvId(value);
      return;
    }
    setLocations([]);
  }

  const handleFiltersValue = (filter, value) => {
    setFilters((prev) => ({ ...prev, [filter]: value }));
    if (filter === 'provinceId') {
      onFilterProvince(value);
    }
  }

  const handleFiltersSubmit = () => onFindFilters(filters);

  const handleFiltersClean = () => {
    onCleanFilters();
    setFilters(initFilters);
    setLocationsDisabled(true);
    setLocations([]);
  }

  const formatProvinces = (data) => {
    const result = data.map(p => ({
      id: p.provinceId,
      value: p.name,
    }));
    setProvinces(result);
  }

  const getProvinces = () => {
    ApiInvoker.getAllProvinces(data => {
      formatProvinces(data);
    }, null);
  }

  const formatLocations = (data) => {
    const result = data.map(c => ({
      id: c.locationId,
      value: c.name,
    }));
    setLocations(result);
  }

  const getLocationsByProvId = (provId) => {
    ApiInvoker.getLocations(provId, data => {
      formatLocations(data);
    }, null);
  }

  useEffect(() => {
    getProvinces();
    return () => {
      setProvinces([]);
      setLocations([]);
      setFilters(initFilters);
    }
  }, [])

  return (
    <GridContainer className={classes.filtersGrid}>
      <GridItem xs={10} sm={9} md={10} className="no-padding">
        <GridContainer>
          <GridItem xs={12} sm={4}>
            <CustomInput
              className="filter-full no-padding"
              labelText={t('label.firstName')}
              formControlProps={{
                fullWidth: true
              }}
              value={filters.firstName}
              onChange={(value) => handleFiltersValue('firstName', value.target.value)}
              errorText={t('error.required')}
            />
          </GridItem>
          <GridItem xs={12} sm={4}>
            <CustomInput
              className="filter-full no-padding"
              labelText={t('label.lastName')}
              formControlProps={{
                fullWidth: true
              }}
              value={filters.lastName}
              onChange={(value) => handleFiltersValue('lastName', value.target.value)}
              errorText={t('error.required')}
            />
          </GridItem>
          <GridItem xs={12} sm={4}>
            <CustomInput
              className="filter-full no-padding"
              labelText={t('label.identification')}
              formControlProps={{
                fullWidth: true
              }}
              value={filters.customerId}
              onChange={(value) => handleFiltersValue('customerId', value.target.value)}
              errorText={t('error.required')}
            />
          </GridItem>
          <GridItem xs={12} sm={4}>
            <SelectInput
              className="no-padding"
              label={t('label.province')}
              elements={provinces}
              value={filters.provinceId}
              onSelectedValue={(value) => handleFiltersValue('provinceId', value)}
            />
          </GridItem>
          <GridItem xs={12} sm={4}>
            <Tooltip title={locationsDisabled ? t('filters.messaggeLocation') : ''}>
              <div>
                <SelectInput
                  className="no-padding"
                  label={t('label.location')}
                  elements={locations}
                  value={filters.locationId}
                  onSelectedValue={(value) => handleFiltersValue('locationId', value)}
                  disabled={locationsDisabled}
                />
              </div>
            </Tooltip>
          </GridItem>
          <GridItem xs={12} sm={4}>
            <CustomInput
              className="filter-full no-padding"
              labelText={t('label.email')}
              formControlProps={{ fullWidth: true }}
              value={filters.email}
              onChange={(value) => handleFiltersValue('email', value.target.value)}
              errorText={t('error.required')}
            />
          </GridItem>
        </GridContainer>
      </GridItem>

      <GridItem xs={2} sm={3} md={2} className={classes.buttonsGrid}>
        <GridContainer className="appointments-filters">
          <GridItem className="filters-actions" xs={12}>
            <Button block onClick={() => handleFiltersSubmit()} color="primary">
              {t('common.find')}
            </Button>
          </GridItem>
          <GridItem className="filters-actions" xs={12}>
            <Button block onClick={() => handleFiltersClean()} color="danger">
              {t('common.clean')}
            </Button>
          </GridItem>
        </GridContainer>
      </GridItem>
    </GridContainer>
  )
}

export default withTranslation()(CustomersFilters);
