import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from "react-i18next";
import ButtonSpinner from '../ButtonSpinner';
import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';
import Snackbar from '../Snackbar/Snackbar';
import SelectInput from '../SelectInput';
import ValidationInput from '../ValidationInput';
import BusinessApiInvoker from "../../api/BusinessApiInvoker";
import './CreateFinancier.css';


class CreateFinancier extends Component {
    constructor(props) {
        super(props)

        this.state = {
            affiliateId: '',
            financiers: [],
            financier: '',
            alertColor: '',
            alertOpen: '',
            alertMessage: '',
            disabled: false,
            loading: false,
        }
    }

    componentWillMount() {
        if (this.props.financiers && this.props.financiers.length) {
            const financiers = this.formatFinanciers(this.props.financiers)
            this.setState({
                financiers,
            })
        } else {
            this.getFinanciers();
        }

        if (this.props.edit) {
            this.props.edit.disabled = true;
            this.setState({
                affiliateId: this.props.edit.affiliateId,
                financier: this.props.edit.financierId,
            });
        }
    }

    componentWillReceiveProps(next) {
        if (next.financiers) {
            const financiers = this.formatFinanciers(next.financiers)
            this.setState({ financiers });
        }
    }

    componentWillUnmount() {
        this.setState({
            affiliateId: '',
            financiers: [],
            financier: '',
            alertColor: '',
            alertOpen: '',
            alertMessage: '',
            loading: false,
        })
    }

    formatFinanciers(financiers) {
        const result = [];
        financiers.map(f => {
            result.push({
                id: f.financierId,
                value: f.businessName,
            });
        })
        return result;
    }

    getFinanciers() {
        BusinessApiInvoker.getFinanciers(financiersData => {
            this.setState({
                financiers: this.formatFinanciers(financiersData)
            });
            this.props.onGetCompanyFinanciers(financiersData)
        }, null);
    }

    handleValue(value, state) {
        this.setState({ [state]: value });
    }

    saveFinancier() {
        if (this.props.edit) {
            this.patchFinancier();
        } else {
            this.postFinancier();
        }
    }

    postFinancier() {
        this.setState({ save: true, loading: true });
        if (this.state.financier && this.state.affiliateId) {
            BusinessApiInvoker.postCustomersFinanciers([{
                affiliateId: this.state.affiliateId,
                customerId: this.props.personId,
                financierId: this.state.financier,
            }], data => {
                this.setState({ loading: false });
                if (data[0].financierId) {
                    this.openAlert('success', this.props.t('financer.messageAddSuccess'));
                    this.props.onFinancierSubmitted(data);
                } else {
                    this.openAlert('danger', data.message);
                }
            }, e => {
                this.setState({ loading: false });
                this.openAlert('danger', e);
            })
        } else {
            this.setState({ loading: false });
            this.openAlert(
                'warning',
                this.props.t('warning.message.fieldsComplete')
            );
        }
    }

    patchFinancier() {
        this.setState({ save: true, loading: true });
        if (this.state.financier && this.state.affiliateId) {
            BusinessApiInvoker.postCustomersFinanciers([{
                affiliateId: this.state.affiliateId,
                customerId: this.props.personId,
                financierId: this.state.financier,
            }], data => {
                this.setState({ loading: false });
                if (data[0].financierId) {
                    this.openAlert('success', this.props.t('financer.messageEditSuccess'));
                    this.props.onFinancierSubmitted(data);
                } else {
                    this.openAlert('danger', data.message);
                }
            }, e => {
                this.setState({ loading: false });
                this.openAlert('danger', e);
            });
        } else {
            this.setState({ loading: false });
            this.openAlert(
                'warning',
                this.props.t('warning.message.fieldsComplete')
            );
        }
    }

    openAlert(color, message) {
        this.setState({
            alertColor: color,
            alertMessage: message,
            alertOpen: true,
        })

        setTimeout(() => {
            this.setState({ alertOpen: false })
        }, 2000)
    }

    render() {
        const { t, edit } = this.props;
        const {
            affiliateId, alertColor, alertMessage, alertOpen, financier, financiers, loading, save
        } = this.state;

        return (
            <GridContainer xs={12}>
                <GridItem xs={12} sm={6}>
                    <SelectInput
                        disabled={edit && edit.disabled}
                        label={`${t('financer.title')} *`}
                        elements={financiers}
                        value={financier}
                        onSelectedValue={value => this.handleValue(value, 'financier')}
                        invalid={!financier && save}
                    />
                </GridItem>
                <GridItem xs={12} sm={6}>
                    <ValidationInput
                        type="text"
                        text={`${t('label.affiliateNumber')} *`}
                        value={affiliateId}
                        onChangeValue={value => this.handleValue(value, 'affiliateId')}
                        invalid={affiliateId === '' && save}

                    />
                </GridItem>
                <GridItem className="base-font" xs={12}>
                    <p className="required-text">
                        <small>*</small> {t('common.fields.required')}
                    </p>
                </GridItem>

                <hr className="divider-root divider-light" />
                <GridItem xs={12}>
                    <ButtonSpinner
                        className="create-financier-submit"
                        onClick={() => this.saveFinancier()}
                        disabled={loading}
                        label={t('financer.save')}
                        labelLoading={t('financer.saving')}
                        loading={loading}
                        typeButton="submit"
                        color="info"
                    />
                    <Snackbar
                        place="tr"
                        color={alertColor}
                        message={alertMessage}
                        open={alertOpen}
                    />
                </GridItem>
            </GridContainer>
        )
    }
}

CreateFinancier.propTypes = {
    personId: PropTypes.number,
    financiers: PropTypes.array,
    onGetCompanyFinanciers: PropTypes.func,
    onFinancierSubmitted: PropTypes.func,
    edit: PropTypes.any,
    t: PropTypes.func,
}

export default withTranslation()(CreateFinancier);
