import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';
import { withTranslation } from 'react-i18next';
import Tooltip from '@material-ui/core/Tooltip';
import AssignmentIcon from '@material-ui/icons/Assignment';
import Drivers from '../../assets/img/driver_jeep.png';
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import Card from '../Card/Card';
import CardHeader from '../Card/CardHeader';
import CardBody from '../Card/CardBody';
import CardIcon from '../Card/CardIcon';
import Table from "../Table/Table";
import Button from '../CustomButtons/Button';
import './Employees.css';
import memory from '../../config/memory';

class Employees extends Component {
    constructor(props) {
        super(props);

        this.state = {
            employees: [],
            loading: false,
        }
    }

    componentDidMount() {
        this.getEmployees();
    }

    componentWillReceiveProps(next) {
        if (next.employees) {
            this.formatEmployees(next.employees);
        }
    }

    getEmployees() {
        this.formatEmployees(memory.drivers)
        
    }

    formatEmployees(employees) {
        const data = employees.map(c => {
            return {
                lastName: c.lastName,
                firstName: c.firstName,
                id: c.id,
                email: c.email,
                zoneGeographics: c.zoneGeographics,
                identification: c.identification,
                vehicle: c.vehicle,
                actions: <div className="employees-actions">
                    <Tooltip title={this.props.t('common.view')}><div><Button simple justIcon color="info" onClick={() => 
                         browserHistory.push({
                         state:{mode: 'view'},
                         pathname:`/ver-profesional/${c.id}`})
                    }><AssignmentIcon /></Button></div></Tooltip>
                </div>
            }
        });
        this.setState({ employees: data });
    }

    toStringTypes = (employeeType) => {
        let toString = '';
        for (let idx = 0; idx < employeeType.length; idx++) {
            if (idx > 0) {
                toString = toString + ', ';
            }
            toString = toString + employeeType[idx].name;
        }
        return toString;
    }

    render() {
        const { t } = this.props;
        const { loading, employees } = this.state;
        return (
            <GridContainer className="employees">
                <GridItem xs={12}>
                    <Card>
                        <CardHeader icon>
                            <CardIcon color="primary">
                                <img src={Drivers} alt="Drivers" width="32" />
                            </CardIcon>
                           
                        </CardHeader>
                        <CardBody className="filtrable-table">
                            <Table
                                striped
                                filterable
                                loading={loading}
                                tableHeaderColor="primary"
                                tableHead={[
                                    { Header: t("label.firstName"), accessor: 'firstName' },
                                    { Header: t("label.lastName"), accessor: 'lastName' },
                                    { Header: t("label.identification"), accessor: 'identification' },
                                    { Header: t("label.email"), accessor: 'email' },
                                    { Header: t("label.vehicle"), accessor: 'vehicle' },
                                    { Header: t("label.geographicZones"), accessor: 'zoneGeographics' },
                                    { Header: t("label.actions"), accessor: 'actions', sortable: false, filterable: false }
                                ]}
                                defaultPageSize={10}
                                tableData={employees}
                                colorsColls={["primary"]}
                            />
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        )
    }
}

Employees.propTypes = {
    onGetEmployees: PropTypes.func.isRequired,
    employees: PropTypes.array,
}

export default withTranslation()(Employees);
