import "./DateInput.css";

import React, { Component } from "react";

import CustomInput from "../CustomInput/CustomInput";
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import PropTypes from "prop-types";
import moment from "moment";

class DateInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      date: "",
      dateState: "",
      shrink: false,
    };
  }

  componentDidMount() {
    if (this.props.value && this.props.value !== "") {
      this.setState({ shrink: true });
    }

    if (this.props.invalid) {
      this.setState({ dateState: "error" });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value && this.props.value !== "") {
      this.setState({ shrink: true });
    }

    if (this.props.invalid && prevProps.invalid !== this.props.invalid) {
      this.setState({ dateState: "error" });
    }
  }

  change(value) {
    if (this.props.disabled) {
      return;
    }
    if (value.length > 0) {
      this.setState({ shrink: true });
    }
    if (value && value.format) {
      this.props.onChangeValue(`${value.format("YYYY-MM-DD")}`);
    } else {
      this.props.onChangeValue("");
    }
    this.setState({
      dateState: "success",
      shrink: true,
    });
  }

  closeShrink = () => this.setState({ shrink: false });

  handleBlur(value) {
    const momentValue = moment(value);
    if (momentValue.isValid()) {
      //this.props.onChangeValue(`${momentValue.format('YYYY-MM-DD')}T00:00:00.000`)
      this.setState({
        dateState: "success",
        shrink: true,
      });
    } else {
      if (value == null || value.length === 0) {
        this.setState({ shrink: false });
      }
    }
  }

  render() {
    const {
      disabled,
      error,
      errorText,
      fullWidth,
      max,
      maxDate,
      min,
      minDate,
      sm,
      value,
      text,
    } = this.props;

    const { dateState, shrink } = this.state;

    return (
      <GridContainer className="date-input">
        <GridItem xs={12}>
          <CustomInput
            dateShrink={shrink}
            labelText={text}
            helpText={errorText}
            error={dateState === "error" || error}
            formControlProps={{}}
            minDate={minDate}
            maxDate={maxDate}
            closeOnSelect={true}
            disabled={disabled}
            dateValue={value ? moment.utc(value).format("DD/MM/YYYY") : value}
            type="date"
            min={min}
            max={max}
            time={false}
            onChange={(event) => this.change(event)}
            onBlur={(event) => this.handleBlur(event)}
            inputProps={{
              disabled: disabled,
            }}
            className={disabled ? "disabled" : ""}
          />
        </GridItem>
      </GridContainer>
    );
  }
}

DateInput.defaultProps = {
  fullWidth: true,
};

DateInput.propTypes = {
  text: PropTypes.string,
  value: PropTypes.any,
  onChangeValue: PropTypes.func,
  invalid: PropTypes.bool,
  disabled: PropTypes.bool,
  erroText: PropTypes.string,
  minDate: PropTypes.bool,
  maxDate: PropTypes.bool,
  fullWidth: PropTypes.bool,
  min: PropTypes.string,
  max: PropTypes.string,
  sm: PropTypes.number,
};

export default DateInput;
