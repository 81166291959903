/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { browserHistory } from 'react-router';
import PropTypes from 'prop-types';
import { Person } from '@material-ui/icons';
import PhoneIcon from '@material-ui/icons/Phone';
import ApiInvoker from '../../api/ApiInvoker';
import Button from '../../components/CustomButtons/Button';
import Card from '../../components/Card/Card';
import CardBody from '../../components/Card/CardBody';
import CardHeader from '../../components/Card/CardHeader';
import CardIcon from '../../components/Card/CardIcon';
import CustomCheckbox from '../../components/CustomCheckbox';
import FullLoading from '../../components/FullLoading';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import Snackbar from '../../components/Snackbar/Snackbar';
import Table from '../../components/Table/Table';
import { Drivers as DriversIcon, Location as GlobeLocation } from '../../icons';
import { usePersonDataTypes } from '../../hooks/usePersonDataTypes';
import PersonAddress from '../../components/PersonAddress';
import PersonalInfo from './PersonalInfo';

const showSnackbarInitialValue = {
  open: false,
  color: 'danger',
  message: ''
}

const formSettingsInitialValue = {
  mode: 'view',
  save: false,
}

const Driver = ({ location, params, t }) => {

  const { documentTypes, genderTypes, nationalities } = usePersonDataTypes(t);

  const [formSettings, setFormSettings] = useState(formSettingsInitialValue);
  const [driver, setDriver] = useState({});
  const [vehicles, setVehicles] = useState([]);
  const [geographicZones, setGeographicZones] = useState([]);
  const [phones, setPhones] = useState([]);
  const [loading, setLoading] = useState(false);

  const [showSnackbar, setShowSnackbar] = useState(showSnackbarInitialValue);

  const submitDriver = () => {
    setFormSettings((prev) => ({ prev, save: true}));
    // @TODO: submit data
  }

  const openSnackbar = (message, color = 'danger') => {
    setShowSnackbar({
      color,
      message,
      open: true,
    });

    setTimeout(() => {
      setShowSnackbar(showSnackbarInitialValue);
    }, 2000);
  }

  const formatDriver = (data) => ({
    firstName: data.firstName,
    lastName: data.lastName,
    nationality: data.nationality,
    documentType: data.idType.idTypeId,
    identification: data.idNumber,
    gender: data.sex,
    birthDate: data.birthdate,
    email: data.email,
  })

  const getDriver = () => {
    setLoading(true);
    ApiInvoker.getDriver(params.id, (data) => {
      const d = formatDriver(data[0]);
      setDriver(d);
      setLoading(false);
      return;
    }, (error) => {
      setLoading(false);
      openSnackbar(t('common.errorMessage'));
      console.error('** error', error);
    })
  }

  const formatVehicles = (data) => data.map((d) =>({
    brand: d.brand,
    model: d.model,
    type: d.carType ? t(`label.cardType.${d.carType}`) : t('label.undefined'),
    wheelChairSuitable: d.wheelChairSuitable ? t('common.yes') : t('common.no'),
    year: d.year,
    patent: d.patent,
    id: d.vehicleId,
  }))

  const getDriverVehicles = () => {
    ApiInvoker.getDriverVehicles(params.id, (data) => {
      const v = formatVehicles(data);
      setVehicles(v);
      return;
    }, (error) => {
      openSnackbar(t('common.errorMessage'));
      console.error('** error', error);
    })
  }

  const formatGeographicZones = (data) => {
    const geogZonesList =  data && data.length > 0 ? data.map((a) => ({
      province: a.provinceName,
      city: a.locationName,
      zone: a.geoZoneName,
    })) : [];
    return geogZonesList;
  }

  const getDriverGeoZones = () => {
    ApiInvoker.getDriverGeoZones(params.id, (data) => {
      const gz = formatGeographicZones(data);
      setGeographicZones(gz);
      return;
    }, (error) => {
      openSnackbar(t('common.errorMessage'));
      console.error('** error', error);
    })
  }

  const formatPhones = (data) => { // @TODO: traer data del servicio de address
    const addressList =  data && data.length > 0 ? data.map((a) => ({
      detail: a.detail,
      type: a.phoneType.name,
      number: a.number,
      default: <CustomCheckbox checked={a.phoneDefault} />,
    })) : [];
    return addressList;
  }

  const getDriverPhones= () => {
    ApiInvoker.getDriverPhones(params.id, (data) => {
      const p = formatPhones(data);
      setPhones(p);
      return;
    }, (error) => {
      openSnackbar(t('common.errorMessage'));
      console.error('** error', error);
    })
  }

  const loadData = () => {
    setFormSettings((prev) => ({ prev, mode: location && location.state && location.state.mode}));
    getDriver();
    getDriverVehicles();
    getDriverGeoZones();
    getDriverPhones();
  }

  useEffect(() => {
    loadData();
    return () => {
      setLoading(false);
      setDriver({});
      setVehicles([]);
      setGeographicZones([]);
      setPhones([]);
      setLoading([]);
    }
  }, []);

  const handleDriverValue = (state, value) => {
    setDriver((prev) => ({ ...prev, [state]: value }));
  }

  const goBack = () => browserHistory.push('/drivers');

  return (
    <>
      <Snackbar
        open={showSnackbar.open}
        color={showSnackbar.color}
        message={showSnackbar.message}
        place="tr"
      ></Snackbar>
      {loading ?
        <FullLoading /> : 
        <GridContainer className="edit-customer employee">
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader icon>
                <CardIcon color="primary">
                  <Person />
                </CardIcon>
                <h3 className="card-icon-title">{t('employee.new.profileData')}</h3>
              </CardHeader>
              <CardBody className="edit-customer-form">
                <PersonalInfo
                  data={{
                    documentTypes, driver, nationalities, genderTypes,
                    mode: formSettings.mode, save: formSettings.save,
                  }}
                  handleDriverValue={handleDriverValue}
                  submitDriver={submitDriver}
                />
              </CardBody>
            </Card>
          </GridItem>

          {/* VEHICLES */}
          <GridItem xs={12} className="specialities-practices-view">
            <Card className="employee-geographic-zones">
              <CardHeader icon>
                <CardIcon color="secondary">
                  <DriversIcon />
                </CardIcon>
                <h3 className="card-icon-title">{t('employee.new.vehicles')}</h3>
              </CardHeader>
              <CardBody>
                  <Table
                      striped
                      filterable
                      tableHeaderColor="primary"
                      defaultPageSize={10}
                      tableHead={[
                          { Header: t('employees.table.marca'), accessor: 'brand' },
                          { Header: t('employees.table.model'), accessor: 'model' },
                          { Header: t('employees.table.type'), accessor: 'type' },
                          { Header: t('employees.table.apto'), accessor: 'wheelChairSuitable' },
                          { Header: t('employees.table.year'), accessor: 'year' },
                          { Header: t('employees.table.patente'), accessor: 'patent' },
                      ]}
                      tableData={vehicles}
                      colorsColls={['primary']}
                  />
              </CardBody>
            </Card>
          </GridItem>

          {/* GEO-ZONES */}
          <GridItem xs={12} className="specialities-practices-view">
            <Card className="employee-geographic-zones">
              <CardHeader icon>
                <CardIcon color="primary">
                  <GlobeLocation />
                </CardIcon>
                <h3 className="card-icon-title">{t('employee.new.GeographicsZones')}</h3>
              </CardHeader>
              <CardBody>
                <Table
                  striped
                  filterable
                  tableHeaderColor="primary"
                  defaultPageSize={10}
                  tableHead={[
                    { Header: t('employees.table.province'), accessor: 'province' },
                    { Header: t('employees.table.city'), accessor: 'city' },
                    { Header: t('employees.table.zone'), accessor: 'zone' },
                  ]}
                  tableData={geographicZones}
                  colorsColls={['primary']}
                />
              </CardBody>
          </Card>
        </GridItem>

        {/* ADDRESS */}
        <PersonAddress personId={params.id} openSnackbar={openSnackbar} />
        

        {/* PHONES */}
        <GridItem xs={12} className="specialities-practices-view">
          <Card className="employee-phones">
            <CardHeader icon>
                <CardIcon color="primary">
                    <PhoneIcon />
                </CardIcon>
                <h3 className="card-icon-title">{t('employee.new.Phones')}</h3>
            </CardHeader>
            <CardBody>
                <Table
                  striped
                  filterable
                  tableHeaderColor="primary"
                  tableHead={[
                    { Header: t('employees.table.description'), accessor: 'detail' },
                    { Header: t('employees.table.typephone'), accessor: 'type' },
                    { Header: t('employees.table.numberphone'), accessor: 'number' },
                    { Header: t('label.phoneDefault'), accessor: 'default' },
                  ]}
                  tableData={phones}
                  defaultPageSize={10}
                  colorsColls={['primary']}
                />
            </CardBody>
          </Card>
        </GridItem>

          <GridItem className="actions-buttons text-center">
            <Button onClick={goBack}>
              {t('appointment.new.back')}
            </Button>
          </GridItem>
        </GridContainer>
      }
    </>
  )
}

Driver.propTypes = {
  onGetEmployee: PropTypes.object,
}

export default withTranslation()(Driver);
