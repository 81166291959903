import "./AppointmentDetail.css";

import { Calendar, momentLocalizer } from "react-big-calendar";
import React, { Component, useEffect } from "react";

import Add from "@material-ui/icons/Add";
import AppointmentApiInvoker from "../../../api/AppointmentApiInvoker";
import Assignment from "@material-ui/icons/Assignment";
import BusinessApiInvoker from "../../../api/BusinessApiInvoker";
import Button from "../../CustomButtons/Button";
import CalendarToday from "@material-ui/icons/CalendarToday";
import Card from "../../Card/Card";
import CardBody from "../../Card/CardBody";
import CardFooter from "../../Card/CardFooter";
import CardHeader from "../../Card/CardHeader";
import CardIcon from "../../Card/CardIcon";
import CustomInput from "../../CustomInput/CustomInput";
import Delete from "@material-ui/icons/Delete";
import DialogTraslado from "../Traslado/DialogTraslado";
import Edit from "@material-ui/icons/Edit";
import EventWithIcon from "../../EventWithIcon/EventWithIcon";
import GridContainer from "../../Grid/GridContainer";
import GridItem from "../../Grid/GridItem";
import ModalDetailServiceTraslado from "../Traslado/ModalDetailServiceTraslado";
import PersonApiInvoker from "../../../api/PersonApiInvoker";
import PropTypes from "prop-types";
import SchedulerService from "../../../containers/SchedulerService";
import Table from "../../Table/Table";
import Tooltip from "@material-ui/core/Tooltip";
import ValidationInput from "../../ValidationInput";
import ViewList from "@material-ui/icons/ViewList";
import { browserHistory } from "react-router";
import memory from "../../../config/memory";
import moment from "moment";
import { services } from "i18next";
import withStyles from "@material-ui/core/styles/withStyles";
import { withTranslation } from "react-i18next";

require("moment/locale/es.js");

const localizer = momentLocalizer(moment);

class AppointmentDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      metadata: {
        medicalData: {},
        diagnostic: {},
      },
      cancellations: [],
      cancellationReason: "",
      confirmMetadataEdit: false,
      saveChangesDisabled: false,
      bookings: [],
      practiceModulesCopy: [],
      loading: false,
      openDetail: false,
      openConfirmCancel: false,
      openConfirmChanges: false,
      currentEvent: {},
      events: [],
      event_original: [],
      items: [],
      itemsCopy: [],
      updateErrors: {},
      status: "",
      alertErrorOpen: false,
      alertErrorMessage: "",
      alertOpen: false,
      alertStatus: "",
      appointment: null,
      showConfirmUndo: false,
      showConfirmSave: false,
      bookingErrors: [],
      alertMessageOpen: false,
      alertMessageColor: "danger",
      alertMessageMsg: "danger",
      rejecteds: [],
      calendarViewMode: "month",
      scheduleStatus: "",
      serviceProvider: null,
      confirmOpenNewAppointment: false,
      busy: false,
      events: [],
      services: [],
      openService: false,
      disabledButton: true,
      customersOptions: [],
    };
    this.componentCleanup = this.componentCleanup.bind(this);
  }

  componentCleanup() {
    this.confirmUndo();
  }

  onUnload = (event) => {
    event.preventDefault();
    // Chrome requires returnValue to be set.
    event.returnValue = "";
  };

  setDataToView = (data) => {};

  componentDidMount() {
    this.setCustomersOptions();
    memory.tempService = null;
    if (this.props.params.id) {
      let appointment = this.formatAppointment(this.props.params.id);
      this.setDataToView(appointment);
    }
  }

  fillSchedules = (appointment, services) => {
    try {
      const d = new Date();
      appointment.provider.dateStart = d.setDate(d.getDate() - 5);
      appointment.provider.dateEnd = d.setDate(d.getDate() + 30);
      appointment.services.startDate = appointment.provider.dateStart;
      services.startDate = d.setDate(d.getDate() - 5);
      //const schedules = this.makeBookingsFromAppointment(appointment);
      //appointment.schedules = schedules;
      return appointment, services;
    } catch (ex) {
      alert(JSON.stringify(appointment.provider));
    }
  };

  formatDate = (date) => {
    let newDate = new Date(date);
    let year = newDate.getFullYear();
    let month = newDate.getMonth();
    let day = newDate.getDate();
    console.log(date);
    console.log(newDate);
    let result = `${day + 1}/${month + 1}/${year}`;
    console.log(result);
    return result;
  };

  formatAppointment = (id) => {
    AppointmentApiInvoker.getQuantity(id, (quantity) => {
      AppointmentApiInvoker.getAppointmentById(id, (response) => {
        this.setState({
          services: [
            {
              order: 1,
              type: "Traslado",
              serviceName: this.formatTransferTypeRecive(
                response.items[0].transferType
              ),
              events: this.formatBookings(response.schedules),
              startDate: this.formatDate(response.startDate),
              recurrence: response,
              quantity: quantity.quantity,
            },
          ],
          userCreator: `${response.createEmployeeFirstName} ${response.createEmployeeLastName}`,
        });
        PersonApiInvoker.getCustomer(response.customerId, (customer) => {
          PersonApiInvoker.getPersonsAddress(response.customerId, (address) => {
            this.setState({
              appointment: {
                customer: {
                  customer: customer.personId,
                  address: address[0].addressId,
                  zone: address[0].geographicZone.detail,
                  location: address[0].location.locationId,
                  province: address[0].provinceName,
                  address_name: address[0].street,
                  latitude: address[0].latitude,
                  longitude: address[0].longitude,
                },
              },
            });
          });
          BusinessApiInvoker.getCustomersFinanciers(
            customer.personId,
            (financier) => {
              this.setState({
                appointment: {
                  ...this.state.appointment,
                  financier: {
                    financierId: financier[0].financier.financierId,
                    affiliateId: financier[0].affiliateId,
                    financiers: financier,
                  },
                  provider: {
                    dateStart: response.startDate,
                    dateEnd: response.endDate,
                  },
                  appointmentId: response.items[0].transferId,
                  appointmentStatus: response.status,
                  schedules: response.schedules,
                },
              });
            }
          );
        });
        return this.state.appointment;
      });
    });
  };

  setCustomersOptions = () => {
    PersonApiInvoker.getCustomers((response) => {});
  };

  componentWillMount() {
    this.setCustomersOptions();
    window.addEventListener("unload", this.componentCleanup);
  }

  setProvider = (serviceProvider) => {
    this.setState({ serviceProvider: serviceProvider });
  };

  getProvider = () => {
    const { financier, customer, startDate, endDate } = this.state.appointment;
    return {
      customerId: {
        customer: customer.personId,
      },
      financier,
      provider: this.getServiceProviderOnEdit(this.state.items),
      dateStart: startDate,
      dateEnd: endDate,
    };
  };

  onBack = () => {
    browserHistory.push(`/solicitudes`);
  };

  setProviderF(value) {
    this.setState(
      {
        provider: value,
      },
      () => {}
    );
  }

  setFinancier(value) {
    this.setState({
      financierData: value,
      financierId: value.financierId ? value.financierId : "",
      affiliateId: value.affiliateId ? value.affiliateId : "",
    });
  }

  getPlanQuantity(plan, startDate, endDatePlan, endDateAppointment, makeEvent) {
    const events = [];
    if (startDate == "") {
      return 0;
    }
    let endDate = endDateAppointment;
    if (!(endDate instanceof Date)) {
      endDate = moment(endDate, "YYYY-MM-DD");
    }
    if (endDatePlan != null) {
      endDate = endDatePlan;
    }

    let quantity = 1;
    const daysTotal = moment(endDate).diff(startDate, "days") + 1;
    const every = plan.recurrence.every;

    if (
      plan.recurrence.endType === "OCURRENCES" &&
      (plan.recurrence.finishOcurrences || plan.recurrence.ocurrences)
    ) {
      quantity = plan.recurrence.finishOcurrences || plan.recurrence.ocurrences;
    } else {
      if (moment(startDate).diff(endDate, "days") > 0) {
        return 0;
      }

      let currentDate = startDate;
      const endDatePlusOne = moment(endDate, "YYYY-MM-DD").add(1, "d");
      let e = every;
      let q = 0;

      if (plan.recurrence.recurrenceType === "DAY") {
        while (!moment(currentDate).isSame(endDatePlusOne, "day") && q < 100) {
          if (e == every) {
            q++;
            if (makeEvent) this.makeEvent(events, currentDate, q);

            e = 1;
          } else {
            e++;
          }
          currentDate = moment(currentDate).add(1, "days");
        }
        quantity = q;
      } else if (
        plan.recurrence.recurrenceType === "WEEK" ||
        plan.recurrence.recurrenceType === "INTRADAY"
      ) {
        let weekDays =
          plan.recurrence.weekRecurrence || plan.recurrence.weekDays;
        if (weekDays == null) {
          weekDays = "";
        }
        weekDays =
          typeof weekDays === "string" ? weekDays.split(",") : weekDays;
        let weekDaysSchedulePlan = [];
        if (weekDays.includes("L")) {
          weekDaysSchedulePlan.push(1);
        }
        if (weekDays.includes("M")) {
          weekDaysSchedulePlan.push(2);
        }
        if (weekDays.includes("X")) {
          weekDaysSchedulePlan.push(3);
        }
        if (weekDays.includes("J")) {
          weekDaysSchedulePlan.push(4);
        }
        if (weekDays.includes("V")) {
          weekDaysSchedulePlan.push(5);
        }
        if (weekDays.includes("S")) {
          weekDaysSchedulePlan.push(6);
        }
        if (weekDays.includes("D")) {
          weekDaysSchedulePlan.push(0);
        }
        let yesterdary;
        while (!moment(currentDate).isSame(endDatePlusOne, "day")) {
          if (e == every) {
            if (weekDaysSchedulePlan.includes(currentDate.day())) {
              if (plan.recurrence.recurrenceType === "INTRADAY") {
                q =
                  q +
                  parseInt(
                    plan.recurrence.quantityIntraday
                      ? plan.recurrence.quantityIntraday
                      : 1
                  );
              } else {
                q++;
                if (makeEvent) this.makeEvent(events, currentDate, q);
              }
            }
          }
          yesterdary = currentDate;
          currentDate = moment(currentDate).add(1, "days");
          if (plan.recurrence.recurrenceType === "WEEK") {
            if (!moment(yesterdary).isSame(currentDate, "week")) {
              if (e == every) {
                e = 1;
              } else {
                e++;
              }
            }
          }
        }
        quantity = q;
      } else if (plan.recurrence.recurrenceType === "MONTH") {
        let yesterdary;
        let monthDay =
          plan.recurrence.monthRecurrence || plan.recurrence.monthDay;

        while (!moment(currentDate).isSame(endDatePlusOne, "day")) {
          if (e == every) {
            if (currentDate.date() == monthDay) {
              q++;
              if (makeEvent) this.makeEvent(events, currentDate, q);
            }
          }
          yesterdary = currentDate;
          currentDate = moment(currentDate).add(1, "days");
          if (!moment(yesterdary).isSame(currentDate, "month")) {
            if (e == every) {
              e = 1;
            } else {
              e++;
            }
          }
        }
        quantity = q;
      }
    }
    if (makeEvent) {
      return events;
    }
    return quantity;
  }

  openAddService = () => {
    this.setState({ openService: true });
  };

  formatService2 = (item, appointment) => {
    let provider = this.state.provider;
    if (appointment) {
      provider = appointment.provider;
    }
    return {
      order: 1,
      type: "Traslado",
      name: item.traslado.value,
      //startDate: item.startDate,
      startDate: "Hola 2",
      quantity: this.getPlanQuantity(
        item,
        moment(new Date(item.startDate)),
        null,
        provider ? provider.dateEnd : null,
        false
      ),
      actions:
        appointment &&
        appointment.appointmentStatus === "SCHEDULES_ASSIGNED" ? (
          <div />
        ) : (
          this.renderActionComponent(item, appointment)
        ),
    };
  };

  formatService = (item, appointment) => {
    let provider = this.state.provider;
    if (appointment) {
      provider = appointment.provider;
    }
    let startDate = item.startDate;
    let dateEnd = provider.dateEnd;

    if (
      appointment &&
      (appointment.appointmentId == 100 || appointment.appointmentId == 101)
    ) {
      const d = new Date();
      startDate = d.setDate(d.getDate() - 5);
      startDate = new Date(startDate);
      dateEnd = d.setDate(d.getDate() + 30);
      dateEnd = new Date(dateEnd);
    }
    return {
      order: 1,
      type: "Traslado",
      serviceName: item.traslado.value,
      recurrenceType: item.recurrence.recurrenceType,
      endType: "DATE",
      companyIdPartnership: localStorage.getItem("itlg_default_company_id"),
      processingScheduleType: "SCHEDULE",
      //startDate: item.startDate,
      startDate: this.formatDate(item.startDate),
      startDateSend: item.startDate,
      endDate: null,
      startTime: item.startTime,
      endTime: null,
      driver: item.driver.id,
      hospitalId: item.hospitalId,
      quantity: this.getPlanQuantity(
        item,
        moment(new Date(item.startDate)),
        null,
        provider ? provider.dateEnd : null,
        false
      ),
      actions:
        appointment &&
        appointment.appointmentStatus === "SCHEDULES_ASSIGNED" ? (
          <div />
        ) : (
          this.renderActionComponent(item, appointment)
        ),
    };
  };

  renderActionComponent = (a, appointment) => {
    const { t } = this.props;

    if (appointment && appointment.appointmentStatus === "CREATED") {
      return (
        <div className="appointments-actions">
          <Tooltip title={t("appointment.service.edit")}>
            <div>
              <Button
                simple
                justIcon
                color="info"
                onClick={() => this.openAddService()}
              >
                <Assignment />
              </Button>
            </div>
          </Tooltip>
        </div>
      );
    } else {
      return (
        <div className="appointments-actions">
          <Tooltip title={t("appointment.service.edit")}>
            <div>
              <Button
                simple
                justIcon
                color="info"
                onClick={() => this.openAddService()}
              >
                <Edit />
              </Button>
            </div>
          </Tooltip>
          <Tooltip title={t("appointment.service.delete")}>
            <div>
              <Button
                simple
                justIcon
                color="info"
                authority="appointment_view,appointment_view_related"
                onClick={() => this.deleteService()}
              >
                <Delete />
              </Button>
            </div>
          </Tooltip>
        </div>
      );
    }
  };

  closeService = () => {
    if (!this.state.appointment) {
      const services =
        memory.tempService && memory.tempService.traslado
          ? [this.formatService(memory.tempService, this.state.appointment)]
          : [];
      this.setState({
        openService: false,
        services,
      });
    }
  };

  deleteService = () => {
    memory.tempService = null;
    this.setState({ services: [] });
  };

  makeEvent = (events, date, index) => {
    return;
    const time = memory.tempService.startTime;
    date = new Date(date).setHours(
      parseInt(time.substring(0, 2)),
      parseInt(time.substring(3, 5)),
      0
    );
    const label = memory.tempService.traslado.value;
    events.push(this.makeBooking(label, date, time, index));
  };

  makeBooking = (label, date, time, lastIndexOf) => {
    return {
      id: lastIndexOf,
      title: label,
      label,
      time_display: time,
      time_start: time,
      allDay: false,
      view: "month",
      start: date,
      end: date,
      color: "F06292",
      employeeId: -1,
      status: "PENDING_APPROVAL",
      type: "booking",
      errorDetail: "",
    };
  };

  makeBookings = () => {
    if (!this.state.appointment.provider.dateEnd) {
      return [];
    }
    return this.getPlanQuantity(
      this.state.services,
      moment(new Date(this.state.services.startDate)),
      null,
      this.state.appointment.provider
        ? moment(new Date(this.state.appointment.provider.dateEnd))
        : null,
      true
    );
  };

  makeBookingsFromAppointment = (appointment) => {
    return this.getPlanQuantity(
      memory.tempService,
      moment(memory.tempService.startDate),
      null,
      appointment.provider ? moment(appointment.provider.dateEnd) : null,
      false
    );
  };

  asignSchedules = () => {
    AppointmentApiInvoker.newSchedule(
      this.state.appointment.appointmentId,
      null,
      (response) => {
        browserHistory.push({
          state: {
            appointment: this.state.appointment,
            bookings: response.schedules,
            dates: {
              startDate: this.state.appointment.provider.dateStart,
              endDate: this.state.appointment.provider.dateEnd,
            },
          },
          pathname: `/turnos/${this.props.params.id}`,
        });
      }
    );
  };

  formatTransferTypeSend = (type) => {
    if (type === "Traslado estándar") {
      return "STANDAR";
    } else if (type === "Traslado con rampa") {
      return "RAMPA";
    } else if (type === "Traslado con ambulancia") {
      return "AMBULANCIA";
    }
  };

  formatTransferTypeRecive = (type) => {
    if (type === "STANDAR") {
      return "Traslado estándar";
    } else if (type === "RAMPA") {
      return "Traslado con rampa";
    } else if (type === "AMBULANCIA") {
      return "Traslado con ambulancia";
    }
  };

  saveAppointment = () => {
    if (!this.state.provider) {
      return;
    }
    // MLGSAVE
    const body = {
      customerId: this.state.customerId.customer.id,
      addressId: this.state.addressId,
      financierId: this.state.financierId,
      createEmployee: localStorage.getItem("user_id"),
      status: "CREATED",
      startDate: this.state.provider.dateStart,
      endDate: this.state.provider.dateEnd,
      items: [
        {
          recurrenceType: this.state.services[0].recurrenceType,
          endType: this.state.services[0].endType,
          companyIdPartnership: {
            companyId: this.state.services[0].companyIdPartnership,
          },
          processingScheduleType: this.state.services[0].processingScheduleType,
          startDate: this.state.startDateSend,
          endDate: this.state.services[0].endDate,
          startTime: this.state.services[0].startTime,
          hospital: { hospitalId: this.state.services[0].hospitalId },
          endTime: this.state.services[0].endTime,
          transferType: this.formatTransferTypeSend(
            this.state.services[0].serviceName
          ),
          driver: {
            personId: this.state.services[0].driver,
          },
        },
      ],
    };

    AppointmentApiInvoker.createAppointment(body, (response) => {
      if (response) {
        window.location.href = "/solicitudes";
      }
    });

    // const app = memory.appointments;
    // body["appointmentId"] = 100 + app.length;
    // app.push(body);
    // memory.appointments = app;
    // const startDate = moment(memory.tempService.startDate).format("DD/MM/YYYY");
    // this.sendSMS(
    //   body["appointmentId"],
    //   startDate,
    //   memory.tempService.nosocomio.value,
    //   memory.tempService.startTime,
    //   this.makeid(4),
    //   this.makeid(4)
    // );
  };

  sendSMS = (idv, day, nosocomio, time, code_inicio, code_fin) => {
    let msg = "Su viaje para el dia ".concat(day);
    msg = msg.concat(" con destino a ");
    msg = msg.concat(nosocomio);
    msg = msg.concat(" inicia ");
    msg = msg.concat(time);
    msg = msg.concat(" hs., codigo de inicio: ");
    msg = msg.concat(code_inicio);
    msg = msg.concat(", codigo de finalizacion: ");
    msg = msg.concat(code_fin);
    msg = msg.concat(".");

    const details = {
      pUser: "Hmmglobal",
      pPassword: "JxTGX1uU",
      pToNum: 3512120314,
      pToCompany: 0,
      pFromNum: "11011",
      pMessage: msg,
      pmsgId: idv,
    };

    const formData = new URLSearchParams();

    for (const property in details) {
      formData.append(property, details[property]);

      //const encodedKey = encodeURIComponent(property);
      //const encodedValue = encodeURIComponent(details[property]);
      //formBody.push(encodedKey + "=" + encodedValue);
    }

    fetch("/api/send_sms/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        body: formData.toString(),
      }),
    })
      .then((response) => response.json())

      .then((responseData) => {});
    browserHistory.push("/solicitudes");
  };

  makeid = (length) => {
    const result = [];
    const characters = "0123456789";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result.push(
        characters.charAt(Math.floor(Math.random() * charactersLength))
      );
    }
    return result.join("");
  };

  selectedEvent(event) {
    this.setState({ event: event, openService: true });
  }

  eventColors(event, start, end, isSelected) {
    let backgroundColor = "event-";
    event.color
      ? (backgroundColor = backgroundColor + event.color)
      : (backgroundColor = backgroundColor + "default");
    return {
      className: backgroundColor,
    };
  }

  isInProgress = () => {
    if (this.props.params.id == 101) return true;
    return false;
  };

  handleChangeValue = () => {};

  /*
  <DialogTraslado
    customer={this.state.appointment.customer}
    appointment={this.state.appointment}
    event={this.state.appointment.schedules[0]}
    
    viewMode={'apointment'}
    provider={this.state.appointment.provider}
    disabled={true}
    saveService={this.saveService}
    handleChangeValue={this.handleChangeValue}
  />*/
  setTranferType = (type) => {
    if (type === "STANDAR") {
      return "Traslado estándar";
    } else if (type === "RAMPA") {
      return "Traslado con rampa";
    } else if (type === "AMBULANCIA") {
      return "Traslado con ambulancia";
    }
  };

  getColor(group) {
    //TODO: HMM-749
    const colors = {
      0: "E157CA",
      1: "F06292",
      2: "5AB4FF",
      3: "79009B",
      4: "D81B60",
      5: "0A85FF",
      6: "BF00B0",
      7: "880E4F",
      8: "1F3FB9",
    };

    return colors[group % 9];
  }

  formatBookings(data) {
    let bookings = data;
    // eslint-disable-next-line no-undef
    bookings = _.orderBy(bookings, ["scheduleDateTime"], ["asc"]);
    let result = [];
    bookings.map((b) => {
      if (b) {
        result.push({
          id: b.transferScheduleId,
          title: `${this.setTranferType(b.transferItemRequestId.transferType)}`,
          time_display: "",
          time_start: moment(b.startDateTime).format("HH:mm"),
          allDay: false,
          view: this.state.calendarViewMode,
          start: b.startDateTime,
          end: b.endDateTime,
          color: this.getColor(1),
          employeeId: b.employeeId,
          status: b.transferScheduleStatus,
          type: b.type,
          errorDetail: "",
          origin: b,
        });
      }
    });
    this.setState({ events: result });
  }

  setStatusAppointment = (status) => {
    if (status === "TRANSFER_SCHEDULES_ASSIGNED") {
      return "Asignada";
    } else if (status === "CREATED") {
      return "Creada";
    }
  };

  haveDataCustomer = () => {};

  setData = (validation) => {
    if (validation) {
      this.setState({ disabledButton: false });
    }
  };

  UNSAFE_componentWillUpdate(nextProps, nextState) {}

  render() {
    const { t } = this.props;
    const { appointment, customerId, event, openService, provider, services } =
      this.state;

    return (
      <GridContainer>
        {appointment && (
          <GridItem className="appointment-data" xs={12}>
            <Card>
              <CardHeader icon>
                <CardIcon color="secondary">
                  <Assignment />
                </CardIcon>
                <h3 className="card-icon-title">{t("appointment.title")}</h3>
              </CardHeader>
              <CardBody>
                <GridContainer className="appointment-detail-schedule">
                  <GridItem
                    className="fullWidth-input validation-input"
                    md={4}
                    xs={4}
                    sm={4}
                  >
                    <CustomInput
                      id="appointment_id"
                      disabled={true}
                      labelText={t("appointment.input.appointment_id")}
                      value={
                        appointment.appointmentId
                          ? appointment.appointmentId
                          : ""
                      }
                      formControlProps={{
                        disabled: true,
                      }}
                    />
                  </GridItem>
                  <GridItem
                    className="fullWidth-input validation-input center-align"
                    md={4}
                    xs={4}
                    sm={4}
                  >
                    <CustomInput
                      id="appointment_status"
                      labelText={t("appointment.input.status")}
                      value={
                        appointment.appointmentStatus
                          ? this.setStatusAppointment(
                              appointment.appointmentStatus
                            )
                          : ""
                      }
                      formControlProps={{
                        disabled: true,
                      }}
                    />
                  </GridItem>
                  <GridItem className="align-right" md={4} xs={4} sm={4}>
                    <div className="align-right">
                      <CustomInput
                        id="init_date"
                        disabled={true}
                        labelProps={{ style: { marginTop: "-10px" } }}
                        inputProps={{
                          style: {
                            textAlign: "right",
                            width: "100%",
                            marginLeft: "-20px",
                          },
                        }}
                        labelText={t("appointment.input.init_date")}
                        value={
                          appointment
                            ? moment(appointment.createdDateTime).format(
                                "DD/MM/YYYY HH:mm:ss"
                              )
                            : null
                        }
                        formControlProps={{
                          disabled: true,
                        }}
                      />
                    </div>
                  </GridItem>
                </GridContainer>
                <GridContainer className="appointment-detail-schedule">
                  <GridItem
                    className="fullWidth-input validation-input"
                    xs={4}
                    sm={4}
                  >
                    <ValidationInput
                      id="appointment_id"
                      classes={{
                        input: "fullWidth-input",
                      }}
                      fullWidth
                      text={t("appointment.input.created_user")}
                      disabled={true}
                      className="fullWidth-input"
                      value={this.state.userCreator}
                      formControlProps={{
                        disabled: true,
                      }}
                    />
                  </GridItem>
                  <GridItem
                    className="fullWidth-input validation-input center-align"
                    xs={4}
                    sm={4}
                  >
                    <CustomInput
                      id="clientId"
                      labelText={t("appointment.input.clientId")}
                      value={"HMM"}
                      formControlProps={{
                        disabled: true,
                      }}
                    />
                  </GridItem>
                  <GridItem
                    className="fullWidth-input align-right"
                    xs={4}
                    sm={4}
                  >
                    <CustomInput
                      id="foreignId"
                      labelText={t("appointment.input.foreignId")}
                      value={"-"}
                      labelProps={{
                        style: { textAlign: "right", width: "100%" },
                      }}
                      inputProps={{
                        style: { textAlign: "right", width: "75%" },
                      }}
                      formControlProps={{
                        disabled: true,
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        )}
        <GridItem xs={6} sm={6} md={6}>
          <SchedulerService.CustomerFormService
            haveData={this.haveDataCustomer}
            setData={this.setData}
            onFilledCustomer={(value) => {
              this.setState({
                customerId: value,
                addressId: value.address,
                zoneId: value.zone,
              });
            }}
            value={
              appointment
                ? {
                    customer: appointment.customer
                      ? appointment.customer.customer
                      : null,
                    address: appointment.customer
                      ? appointment.customer.address
                      : null,
                    zone: appointment.customer
                      ? appointment.customer.zone
                      : null,
                    location: appointment.customer
                      ? appointment.customer.location
                      : null,
                    province: appointment.customer
                      ? appointment.customer.province
                      : null,
                    address_name: appointment.customer
                      ? appointment.customer.address_name
                      : null,
                  }
                : null
            }
            onUpdateCustomers={this.onUpdateCustomers}
            disabled={appointment != null}
          />
        </GridItem>

        <GridItem xs={6} sm={6} md={6}>
          <SchedulerService.ProviderFormService
            customerId={customerId}
            onFilledProvider={(value) => this.setProviderF(value)}
            onFilledFinancier={(value) => this.setFinancier(value)}
            value={
              this.state.appointment
                ? {
                    provider: appointment.provider,
                    financier: appointment.financier,
                    customerId: appointment.customer,
                  }
                : null
            }
            disabled={appointment != null}
          />
        </GridItem>

        <GridItem className="schedule-form" xs={12} sm={12} md={12}>
          <Card>
            <CardHeader icon className="filters-header">
              <CardIcon color="secondary">
                <ViewList />
              </CardIcon>
              <h3 className="card-icon-title">{t("appointment.services")}</h3>
            </CardHeader>
            <CardBody className="appointments-content-body filtrable-table">
              <Table
                filterable
                loading={false}
                tableHeaderColor={"primary"}
                defaultPageSize={services.length > 10 ? 20 : 10}
                sortable
                tableHead={[
                  { Header: t("service.order"), accessor: "order" },
                  { Header: t("service.type"), accessor: "type" },
                  { Header: t("service.name"), accessor: "serviceName" },
                  {
                    Header: t("service.start_date"),
                    accessor: "startDate",
                    sortMethod: (a, b) => this.sortDate(a, b),
                  },
                  {
                    Header: t("service.quantity"),
                    accessor: "quantity",
                    sortMethod: (a, b) => this.sortDate(a, b),
                  },
                  {
                    Header: t("appointment.table.actions"),
                    accessor: "actions",
                    sortable: false,
                    filterable: false,
                  },
                ]}
                tableData={services}
                colorsColls={["primary"]}
                className="-striped -highlight filtrable sticky"
                showPaginationTop={false}
                showPaginationBottom={true}
              />
            </CardBody>
            <CardFooter className="schedule-form-submit buttonFooter">
              {!memory.tempService && (
                <Button
                  className="buttonPlusFooter"
                  onClick={() => this.openAddService()}
                  disabled={
                    !customerId ||
                    !this.state.customerId.latitude ||
                    !provider ||
                    !this.state.disabledButton ||
                    !provider.dateStart ||
                    !provider.dateEnd
                  }
                  color="primary"
                >
                  <Add />
                </Button>
              )}
            </CardFooter>
          </Card>

          {this.isInProgress() && appointment && (
            <Card>
              <CardHeader icon className="filters-header">
                <CardIcon color="primary">
                  <CalendarToday />
                </CardIcon>
                <h3 className="card-icon-title">
                  {t("appointment.trans.inprogress")}
                </h3>
              </CardHeader>
              <CardBody calendar>
                <DialogTraslado
                  customer={appointment.customer}
                  appointment={appointment}
                  event={appointment.schedules[0]}
                  viewMode={"apointment"}
                  provider={appointment.provider}
                  disabled={true}
                  saveService={this.saveService}
                  handleChangeValue={this.handleChangeValue}
                />
              </CardBody>
            </Card>
          )}

          {appointment &&
            appointment.schedules &&
            appointment.appointmentStatus === "TRANSFER_SCHEDULES_ASSIGNED" && (
              <Card>
                <CardHeader icon className="filters-header">
                  <CardIcon color="secondary">
                    <CalendarToday />
                  </CardIcon>
                  <h3 className="card-icon-title">{t("appointment.agenda")}</h3>
                </CardHeader>
                <CardBody className="calendar-booking" calendar>
                  <Calendar
                    selectable
                    showMultiDayTimes={true}
                    localizer={localizer}
                    events={this.state.events}
                    defaultView="month"
                    step={30}
                    views={["month"]}
                    scrollToTime={new Date(1970, 1, 1, 6)}
                    defaultDate={new Date()}
                    onSelectEvent={(event) => this.selectedEvent(event)}
                    eventPropGetter={this.eventColors}
                    messages={{
                      next: t("common.next"),
                      previous: t("common.prev"),
                      today: t("common.today"),
                      month: t("common.month"),
                      week: t("common.week"),
                      day: t("common.day"),
                      event: t("common.event"),
                      date: t("common.date"),
                      time: t("common.hour"),
                    }}
                    components={{
                      event: EventWithIcon,
                    }}
                  />
                </CardBody>
              </Card>
            )}

          <GridContainer className="buttons-containers">
            <GridItem xs={10} sm={10} md={10}>
              <Button onClick={() => this.onBack()}>
                {t("appointment.cancel")}
              </Button>
            </GridItem>
            <GridItem xs={2} sm={2} md={2} style={{ textAlign: "right" }}>
              {appointment != null &&
                appointment.appointmentStatus === "CREATED" && (
                  <Button
                    color="blue"
                    onClick={() => this.asignSchedules()}
                    className="align-right"
                  >
                    {t("appointment.asign")}
                  </Button>
                )}
              {appointment === null && (
                <Button
                  className="align-right"
                  color="blue"
                  disabled={!services.length}
                  onClick={() => this.saveAppointment()}
                >
                  {t("appointment.save")}
                </Button>
              )}
            </GridItem>
          </GridContainer>
        </GridItem>
        <ModalDetailServiceTraslado
          open={openService}
          onCloseModal={this.closeService}
          disabled={appointment}
          event={event}
          viewMode={""}
          provider={provider}
          appointment={appointment}
          customer={appointment ? appointment.customer : customerId}
          onSaveService={this.addService}
          editData={services}
        />
      </GridContainer>
    );
  }
}

AppointmentDetail.defaultProps = {
  selectedSchedules: [],
  onSaveSchedulesAppointment: () => {},
};

AppointmentDetail.propTypes = {
  selectedAppointment: PropTypes.object,
  providers: PropTypes.array,
  appointmentTools: PropTypes.array,
  selectedSchedules: PropTypes.array,
  onSelectAppointmentObject: PropTypes.func,
  params: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  onGetAppointmentsTools: PropTypes.func,
  onDeleteAppointmentTool: PropTypes.func,
  onGetSchedulesAppointment: PropTypes.func,
  onSaveSchedulesAppointment: PropTypes.func,
};

const styles = {
  cardHeader: {
    zIndex: "0 !important",
  },
};

export default withStyles(styles)(withTranslation()(AppointmentDetail));
