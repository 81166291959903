import "./Appointments.css";

import * as _ from "lodash";

import {
  Calendar as CalendarIcon,
  DeleteDocument,
  EventDeclined,
  HealthCalendar,
} from "../../icons";
import React, { Component } from "react";

import Add from "@material-ui/icons/Add";
import AppointmentApiInvoker from "../../api/AppointmentApiInvoker";
import Assignment from "@material-ui/icons/Assignment";
import BusinessApiInvoker from "../../api/BusinessApiInvoker";
import Button from "../CustomButtons/Button";
import ButtonSpinner from "../ButtonSpinner";
import Card from "../Card/Card";
import CardBody from "../Card/CardBody";
import CardHeader from "../Card/CardHeader";
import CardIcon from "../Card/CardIcon";
import ComponentDisabledWithTooltip from "../ComponentDisabledWithTooltip/ComponentDisabledWithTooltip";
import Copy from "@material-ui/icons/FileCopy";
import CustomDialog from "../CustomDialog/index";
import CustomInput from "../CustomInput/CustomInput";
import DateInput from "../DateInput";
import Edit from "@material-ui/icons/Edit";
import { Grid } from "@material-ui/core";
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import PersonApiInvoker from "../../api/PersonApiInvoker";
import PropTypes from "prop-types";
import SelectInput from "../SelectInput/index";
import Snackbar from "../Snackbar/Snackbar.jsx";
import SweetAlert from "react-bootstrap-sweetalert";
import Table from "../Table/Table";
import Tooltip from "@material-ui/core/Tooltip";
import ValidationInput from "../ValidationInput";
import { browserHistory } from "react-router";
import memory from "../../config/memory.js";
import moment from "moment";
import { withTranslation } from "react-i18next";

const dateFormat = "DD/MM/YYYY";

class Appointments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      appointments: [],
      loading: false,
      filterModules: "",
      filterProfessionals: "",
      filterStatus: "",
      filterTools: "",
      filterColors: [
        { id: 1, value: this.props.t("appointment.table.filters.red") },
        { id: 2, value: this.props.t("appointment.table.filters.yellow") },
        { id: 3, value: this.props.t("appointment.table.filters.green") },
        { id: 4, value: this.props.t("appointment.table.filters.rejected") },
      ],
      transferStatus: [
        { id: "CREATED", value: this.props.t("CREATED") },
        {
          id: "TRANSFER_SCHEDULES_ASSIGNED",
          value: this.props.t("TRANSFER_SCHEDULES_ASSIGNED"),
        },
        { id: "IN_PROGRESS", value: this.props.t("IN_PROGRESS") },
        { id: "CANCELLED", value: this.props.t("CANCELLED") },
        { id: "ENDED", value: this.props.t("ENDED") },
      ],
      dateFrom: "",
      financiers: [],
      dateTo: "",
      currentFilterColor: [],
      color: "true",
      checkedInput: false,
      currentUserId: localStorage.getItem("user_id"),
      dialogCopyApointment: false,
      busy: false,
      filterAppointmentId: "",
      filterCustomer: "",
      filterIdentification: "",
      filterState: "",
      filterFinancier: "",
      filterStartDate: "",
      filterEndDate: "",
      filterDriver: "",
      page: 0,
      openMessage: false,
      customersOptions: [],
      showSnackBar: false,
    };
  }

  formatCustomers = (customers) => {
    let result = customers.map((c) => {
      return {
        id: c.personId,
        value: `${c.firstName} ${c.lastName}`,
        customerId: c.id,
      };
    });
    return result;
  };

  setCustomersOptions = () => {
    PersonApiInvoker.getCustomers((response) => {
      this.setState({ customersOptions: this.formatCustomers(response) });
    });
  };

  componentWillMount() {
    this.setCustomersOptions();
    this.getAppointments("", "", "", "", "", "", "", "", "");
    this.getFinanciers();
  }

  componentWillReceiveProps(next) {
    if (next.appointments) {
      this.reduceAppointmentes(next.appointments);
    }
  }

  reduceAppointmentes(appointments) {
    if (appointments.length) {
      const data = appointments.map(this.appDataFormater);
      this.setState({ appointments: data });
    }
  }

  handleCustomer(e) {
    const value = e.target.value;
    const data =
      this.props.appointments &&
      this.props.appointments
        .filter((a) => {
          const name = `${a.customer.firstName} ${a.customer.lastName}`;
          return name.toLowerCase().indexOf(value.toLowerCase()) !== -1;
        })
        .map(this.appDataFormater);

    this.setState({ appointments: data });
  }

  handleAppointmentFilter(e) {
    const value = e.target.value.toString();
    const data =
      this.props.appointments &&
      this.props.appointments
        .filter((a) => a.appointmentId.toString().indexOf(value) !== -1)
        .map(this.appDataFormater);

    this.setState({ appointments: data });
  }

  handleFilters({ date, filter }) {
    const newState = {};
    let appointments = this.props.appointments || [];

    if (filter) {
      newState[filter === "to" ? "dateTo" : "dateFrom"] = date;
    }

    if (this.state.dateTo && !newState.dateTo) {
      newState.dateTo = this.state.dateTo;
    }

    if (this.state.dateFrom && !newState.dateFrom) {
      newState.dateFrom = this.state.dateFrom;
    }

    if (this.state.currentFilterColor.length) {
      appointments = appointments.filter((a) => this.filterAppointmentOr(a));
    }
    if (newState.checkedInput || newState.dateFrom || newState.dateTo) {
      appointments = appointments.filter((a) =>
        this.filterAppointmentAnd(a, newState)
      );
    }

    appointments = appointments.map(this.appDataFormater);

    newState.appointments = appointments;

    this.setState(newState);
  }

  filterAppointmentAnd(a, newState) {
    const userId = parseInt(this.state.currentUserId);
    let accepted = true;
    let personIdentifier =
      a.responsableEmployee && a.responsableEmployee.personId;
    const appointmentDateMoment = moment(a.createdDateTime);

    if (newState.checkedInput) {
      accepted = accepted && personIdentifier === userId;
    }
    if (newState.dateFrom) {
      accepted =
        accepted &&
        appointmentDateMoment.isSameOrAfter(newState.dateFrom, "days");
    }
    if (newState.dateTo) {
      accepted =
        accepted &&
        appointmentDateMoment.isSameOrBefore(newState.dateTo, "days");
    }

    return accepted;
  }

  filterAppointmentOr(a) {
    let now = moment();
    let endDateO = moment(a.endDate);
    let daysLeft = Math.ceil(endDateO.diff(now, "days", true));
    let accepted = false;

    if (this.state.currentFilterColor.indexOf(1) > -1) {
      // Filter Red Icons
      accepted = accepted || (daysLeft >= 0 && daysLeft <= 5);
    }
    if (this.state.currentFilterColor.indexOf(2) > -1) {
      // Filter
      accepted = accepted || (daysLeft >= 6 && daysLeft <= 10);
    }
    if (this.state.currentFilterColor.indexOf(3) > -1) {
      // Filter Rejected
      accepted = accepted || (daysLeft >= 11 && daysLeft <= 15);
    }
    if (this.state.currentFilterColor.indexOf(4) > -1) {
      // Filter Rejecteds Icons
      accepted = accepted || a.hasSchedulesRejected;
    }
    return accepted;
  }

  formatFinanciers = (financiers) => {
    const result = [];
    financiers.map((f) => {
      result.push({
        id: f.financierId,
        value: f.businessName,
      });
    });
    return result;
  };

  appDataFormater = (a) => {
    const d = new Date();
    if (a.startDate == "") {
      a.startDate = d.setDate(d.getDate() - 5);
      a.endDate = d.setDate(d.getDate() + 30);
    }
    return {
      appointmentId: a.transferId,
      date: moment(a.createdDateTime).format(dateFormat),
      name: a.customer
        ? a.customer.firstName + " " + a.customer.lastName
        : a.customer.name,
      customerId: a.customer.id,
      identification: a.customer.id,
      startDate: moment(a.startDate).format(dateFormat),
      endDate: moment(a.endDate).format(dateFormat),
      appStatus: this.setStatusAppointment(a.transferStatus),
      actions: this.renderActionComponent(a),
    };
  };

  setStatusAppointment = (status) => {
    if (status === "TRANSFER_SCHEDULES_ASSIGNED") {
      return "Asignada";
    } else if (status === "CREATED") {
      return "Creada";
    }
  };

  endDateFormater(endDate, hasSchedulesRejected) {
    const { t } = this.props;
    const now = moment();
    const endDateF = moment(endDate);
    const daysLeft = Math.ceil(endDateF.diff(now, "days", true));
    let rejected = "";
    if (hasSchedulesRejected) {
      rejected = (
        <Tooltip
          className="toolTipEdit"
          title={`${t("appointment.table.rejected")}`}
        >
          <div>
            <Button
              disabled
              className="noButton"
              simple
              justIcon
              color="danger"
            >
              <EventDeclined className="svgdanger svgIcon" />
            </Button>
          </div>
        </Tooltip>
      );
    }
    if (daysLeft >= 11 && daysLeft <= 15) {
      return (
        <div>
          <Tooltip
            className="toolTipEdit"
            title={`${t("appointment.table.remaining")} ${daysLeft} ${t(
              "appointment.table.days"
            )} `}
          >
            <div>
              <Button
                disabled
                className="noButton"
                simple
                justIcon
                color="success"
              >
                <CalendarIcon className="svgsuccess svgIcon" fontSize="large" />
              </Button>
            </div>
          </Tooltip>
          {rejected}
        </div>
      );
    } else if (daysLeft >= 6 && daysLeft <= 10) {
      return (
        <div>
          <Tooltip
            className="toolTipEdit"
            title={`${t("appointment.table.remaining")} ${daysLeft} ${t(
              "appointment.table.days"
            )} `}
          >
            <div>
              <Button
                disabled
                className="noButton"
                simple
                justIcon
                color="warning"
              >
                <CalendarIcon className="svgyellow svgIcon" />
              </Button>
            </div>
          </Tooltip>
          {rejected}
        </div>
      );
    } else if (daysLeft <= 5 && daysLeft > 0) {
      return (
        <div>
          <Tooltip
            className="toolTipEdit"
            title={`${t("appointment.table.remaining")} ${daysLeft} ${
              daysLeft === 1
                ? t("appointment.table.day")
                : t("appointment.table.days")
            } `}
          >
            <div>
              <Button
                disabled
                className="noButton"
                simple
                justIcon
                color="danger"
              >
                <CalendarIcon className="svgdanger svgIcon" />
              </Button>
            </div>
          </Tooltip>
          {rejected}
        </div>
      );
    } else if (daysLeft === 0) {
      return (
        <div>
          <Tooltip
            className="toolTipEdit"
            title={`${t("appointment.table.remaining.today")} `}
          >
            <div>
              <Button
                disabled
                className="noButton"
                simple
                justIcon
                color="danger"
              >
                <CalendarIcon className="svgdanger svgIcon" />
              </Button>
            </div>
          </Tooltip>
          {rejected}
        </div>
      );
    } else if (daysLeft < 0) {
      return (
        <div>
          <Tooltip
            className="toolTipEdit"
            title={`${t("appointment.table.remaining.end")} `}
          >
            <div>
              <Button disabled className="noButton" simple justIcon>
                <CalendarIcon className="svgdefault svgIcon" />
              </Button>
            </div>
          </Tooltip>
          {rejected}
        </div>
      );
    } else {
      return <div>{rejected}</div>;
    }
  }

  goToView = (id) => {
    browserHistory.push(`/solicitudes/${id}`);
  };

  renderActionComponent = (a) => {
    const { t } = this.props;
    const label =
      a.appointmentStatus === "CREATED"
        ? t("appointment.asignSchedule")
        : t("appointment.viewAppointment");
    return (
      <div className="appointments-actions">
        <Tooltip title={label}>
          <div>
            <Button
              simple
              justIcon
              color="primary"
              authority="appointment_view,appointment_view_related"
              onClick={() => {
                this.goToView(a.transferId);
              }}
            >
              <Assignment />
            </Button>
          </div>
        </Tooltip>
      </div>
    );
  };

  handleValue(value, state) {
    this.setState({ [state]: value }, () => {});
  }

  addAppointment() {
    browserHistory.push("/new-appointment");
  }

  handleStatus(e) {
    const status = e.target.value;
    const data = this.props.appointments
      .filter((app) => {
        const currentStatus = _.get(app, "appointmentStatus", "");
        return currentStatus.toLowerCase().indexOf(status.toLowerCase()) !== -1;
      })
      .map(this.appDataFormater.bind(this));

    this.setState({ appointments: data });
  }

  sortDate = (a, b) => {
    const a1 = moment(a, dateFormat).valueOf();
    const b1 = moment(b, dateFormat).valueOf();
    if (a1 < b1) return -1;
    else if (a1 > b1) return 1;
    else return 0;
  };

  toggleCopy = () => {
    this.setState({
      dialogCopyApointment: !this.state.dialogCopyApointment,
      busy: false,
    });
  };

  getFinanciers = () => {
    BusinessApiInvoker.getFinanciers(
      (data) => {
        this.setState({ financers: this.formatFinanciers(data) });
      },
      (err) => {
        this.setState({ financers: [] });
      }
    );
  };

  checkAppointment = () => {
    this.setState({ busy: true });
    AppointmentApiInvoker.getAppointment(
      this.state.appointment_copy,
      (data) => {
        //mlg
        this.props.onSelectAppointmentList(-1);
        this.props.onSelectAppointmentObject(null);
        browserHistory.push({
          state: { mode: "copy" },
          pathname: `/appointments/${this.state.appointment_copy}/renew/agenda`,
        });
      },
      (err) => {
        this.setState({
          dialogCopyApointment: !this.state.dialogCopyApointment,
          alertWarningCopy: true,
          busy: false,
        });
      }
    );
  };

  cleanFilters = () => {
    this.setState({
      filterAppointmentId: "",
      filterCustomer: "",
      filterIdentification: "",
      filterState: "",
      transferFinancier: "",
      filterStartDate: "",
      filterEndDate: "",
      filterDriver: "",
    });

    this.getAppointments("", "", "", "", "", "", "", "", "");
  };

  validateSearsh = (
    transferId,
    startDate,
    endDate,
    transferStatus,
    customerId,
    driverId,
    transferFinancier
  ) => {
    if (
      transferId == "" &&
      startDate == "" &&
      endDate == "" &&
      transferStatus == "" &&
      customerId == "" &&
      driverId == "" &&
      transferFinancier == ""
    ) {
      this.setState({ showSnackBar: true });
      setTimeout(() => {
        this.setState({ showSnackBar: false });
      }, 2000);
    }
  };

  getAppointments = (
    transferId,
    startDate,
    endDate,
    transferStatus,
    customerId,
    driverId,
    transferFinancier
  ) => {
    if (
      transferId !== "" ||
      startDate !== "" ||
      endDate !== "" ||
      transferStatus !== "" ||
      customerId !== "" ||
      driverId !== "" ||
      transferFinancier !== ""
    ) {
      let filter = {
        transferId,
        startDate,
        endDate,
        transferStatus,
        customerId,
        driverId,
        transferFinancier,
      };

      let params = Object.keys(filter)
        .map((k) => {
          let value = filter[k];
          if (value !== "") {
            return `${encodeURIComponent(k)}=${encodeURIComponent(value)}`;
          } else {
            return null;
          }
        })
        .join("&");
      if (params !== "") {
        params = `${params}&`;
      }
      params = `${params}page=${this.state.page}&pageSize=${20}`;
      this.setState({ loading: true });
      AppointmentApiInvoker.getAppointments(
        params,
        (data) => {
          this.reduceAppointmentes(data);
          this.setState({ loading: false });
          if (data.length === 0) {
            this.setState({ openMessage: true });
            setTimeout(() => {
              this.setState({ openMessage: false });
            }, "2000");
          }
        },
        (error) => {
          this.setState({ loading: false });
          // eslint-disable-next-line no-unused-expressions
          <Snackbar
            color={"danger"}
            message={error.message}
            open={true}
            place="tc"
          />;
        }
      );
    } else {
      let params = `&page=${this.state.page}&pageSize=${20}`;
      this.setState({ loading: true });
      AppointmentApiInvoker.getAppointments(
        params,
        (data) => {
          this.reduceAppointmentes(data);
          this.setState({ loading: false });
        },
        (error) => {
          this.setState({ loading: false });
          // eslint-disable-next-line no-unused-expressions
          <Snackbar
            color={"danger"}
            message={error.message}
            open={true}
            place="tc"
          />;
        }
      );
    }
  };

  render() {
    const { t } = this.props;
    const { alertWarningCopy, appointments, loading } = this.state;
    return (
      <GridContainer className="appointments appointments-list">
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader
              icon
              className="filters-header"
              style={{ paddingBottom: 20 }}
            >
              <div className="header-internment-table">
                <div className="header-icon">
                  <CardIcon color="warning">
                    <Assignment />
                  </CardIcon>
                </div>
                <div className="header-buttons">
                  <Tooltip title="Nueva Hospitalización">
                    <span>
                      <Button
                        className="add-content-button appointments-button"
                        round
                        color="primary"
                        authority="appointment_new"
                        onClick={() => this.addAppointment()}
                      >
                        <Add className="appointments-button-icon" />{" "}
                        {t("appointment.create")}
                      </Button>
                    </span>
                  </Tooltip>
                </div>
              </div>

              {
                <Grid container className="appointments-filters">
                  <Grid
                    container
                    item
                    xs={12}
                    sm={9}
                    md={10}
                    className="appointments-filters-row"
                  >
                    <Snackbar
                      place="tr"
                      color={"danger"}
                      message={
                        "No se encontraron datos para los filtros ingresados"
                      }
                      open={this.state.openMessage}
                    />
                    <Grid container item xs={12} className="filters-row">
                      <Grid container item xs={12} sm={3}>
                        <ValidationInput
                          className="filter-full no-padding"
                          text={"Nro. de solicitud"}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          type="number"
                          value={this.state.filterAppointmentId}
                          onChangeValue={(e) =>
                            this.setState({
                              filterAppointmentId: e,
                            })
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <SelectInput
                          label={t("appointment.new.customer.customer")}
                          elements={this.state.customersOptions}
                          onSelectedValue={(value) =>
                            this.setState({
                              filterCustomer: value,
                            })
                          }
                          value={this.state.filterCustomer}
                        />
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <ValidationInput
                          className="filter-full no-padding"
                          text={"Identificación"}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          value={this.state.filterIdentification}
                          onChangeValue={(e) =>
                            this.setState({
                              filterIdentification: e,
                            })
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <ValidationInput
                          className="filter-full no-padding"
                          text={"Chofer"}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          value={this.state.filterDriver}
                          onChangeValue={(e) =>
                            this.setState({
                              filterDriver: e,
                            })
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <SelectInput
                          className="select-input-filters"
                          label={"Estado"}
                          elements={this.state.transferStatus}
                          value={this.state.filterState}
                          onSelectedValue={(value) =>
                            this.handleValue(value, "filterState")
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <SelectInput
                          className="select-input-filters"
                          label={"Prestador"}
                          elements={[]}
                          value={this.state.filterFinancer}
                          onSelectedValue={(value) =>
                            this.handleValue(value, "filterFinancier")
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <DateInput
                          text={"Fecha inicio prestación"}
                          value={this.state.filterStartDate}
                          onChangeValue={(e) =>
                            this.setState({
                              filterStartDate: e,
                            })
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <DateInput
                          text={"Fecha fin prestación"}
                          value={this.state.filterEndDate}
                          onChangeValue={(e) =>
                            this.setState({
                              filterEndDate: e,
                            })
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    sm={3}
                    md={2}
                    className="filters-actions-appointments"
                  >
                    <Grid
                      container
                      item
                      justify="flex-end"
                      className="filters-row"
                    >
                      <Grid item className="filters-actions" xs={12}>
                        <Snackbar
                          color={"warning"}
                          message={
                            "Debe ingresar algún filtro para realizar la búsqueda"
                          }
                          open={this.state.showSnackBar}
                          place="tc"
                        />
                        <ComponentDisabledWithTooltip
                          tooltipText={"Buscar"}
                          disabled={false}
                          loading={false}
                          component={
                            <ButtonSpinner
                              onClick={() => {
                                this.validateSearsh(
                                  this.state.filterAppointmentId,
                                  this.state.filterStartDate,
                                  this.state.filterEndDate,
                                  this.state.filterState,
                                  this.state.filterCustomer,
                                  this.state.filterDriver,
                                  this.state.filterFinancier
                                );
                                this.getAppointments(
                                  this.state.filterAppointmentId,
                                  this.state.filterStartDate,
                                  this.state.filterEndDate,
                                  this.state.filterState,
                                  this.state.filterCustomer,
                                  this.state.filterDriver,
                                  this.state.filterFinancier
                                );
                              }}
                              label={"Buscar"}
                              labelLoading={"Buscar"}
                              typeButton="submit"
                              color="primary"
                              block
                            />
                          }
                        />
                      </Grid>
                      <Grid item className="filters-actions" xs={12}>
                        <Button
                          block
                          onClick={this.cleanFilters}
                          color="danger"
                        >
                          Limpiar
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              }
            </CardHeader>

            <CardBody className="appointments-content-body filtrable-table">
              <Table
                filterable
                loading={loading}
                tableHeaderColor={"primary"}
                defaultPageSize={appointments.length > 10 ? 20 : 10}
                sortable
                tableHead={[
                  {
                    Header: t("appointment.table.appointment_id"),
                    accessor: "appointmentId",
                  },
                  {
                    Header: t("appointment.table.init_date"),
                    accessor: "date",
                    sortMethod: (a, b) => this.sortDate(a, b),
                  },
                  {
                    Header: t("label.identification"),
                    accessor: "identification",
                  },
                  {
                    Header: t("appointment.table.customer.name"),
                    accessor: "name",
                  },
                  {
                    Header: t("appointment.table.start_date"),
                    accessor: "startDate",
                    sortMethod: (a, b) => this.sortDate(a, b),
                  },
                  {
                    Header: t("appointment.table.end_date"),
                    accessor: "endDate",
                    sortMethod: (a, b) => this.sortDate(a, b),
                  },
                  {
                    Header: t("appointment.table.status"),
                    accessor: "appStatus",
                  },
                  {
                    Header: t("appointment.table.actions"),
                    accessor: "actions",
                    sortable: false,
                    filterable: false,
                  },
                ]}
                tableData={this.state.appointments}
                colorsColls={["primary"]}
                className="-striped -highlight filtrable sticky"
                showPaginationTop={false}
                showPaginationBottom={true}
              />
            </CardBody>
          </Card>
        </GridItem>

        <CustomDialog
          title={t("appointment.renew.title")}
          maxWidth={"sm"}
          open={this.state.dialogCopyApointment}
          onClose={() => this.toggleCopy()}
        >
          <div>
            <p className="appointmentRenewTitle">
              {t("appointment.renew.question")}
            </p>
            <div className="booking-errors-actions">
              <CustomInput
                className="filter-number"
                id="appointment_copy"
                type="number"
                formControlProps={{
                  fullWidth: false,
                }}
                inputProps={{
                  autocomplete: "no",
                }}
                onChange={(e) =>
                  this.setState({ appointment_copy: e.target.value })
                }
              />
            </div>
          </div>
          <div className="content-actions">
            <ButtonSpinner
              onClick={() => this.checkAppointment()}
              disabled={this.state.busy}
              label={t("appointment.renew.acept")}
              labelLoading={t("common.saving")}
              loading={this.state.busy}
              typeButton="submit"
              color="primary"
            />
          </div>
        </CustomDialog>

        {alertWarningCopy && (
          <SweetAlert
            warning
            style={{ display: "block", marginTop: "-100px" }}
            onConfirm={() =>
              this.setState({
                dialogCopyApointment: true,
                alertWarningCopy: false,
              })
            }
            confirmBtnCssClass={"btn primary"}
            confirmBtnText={t("appointment.new.accept")}
            showCancel={false}
          >
            <p>{t("appointment.renew.renew_warning")}</p>
          </SweetAlert>
        )}
      </GridContainer>
    );
  }
}

Appointments.defaultProps = {
  appointments: [],
};

Appointments.propTypes = {
  onGetAppointments: PropTypes.func.isRequired,
  onDeleteAppointment: PropTypes.func.isRequired,
  onSelectAppointment: PropTypes.func.isRequired,
  onSelectAppointmentList: PropTypes.func.isRequired,
  onSelectAppointmentObject: PropTypes.func.isRequired,
  appointments: PropTypes.any,
};

export default withTranslation()(Appointments);
