import React, { Component } from 'react';
import { browserHistory } from 'react-router';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import Assignment from '@material-ui/icons/Assignment';
import HotelIcon from '@material-ui/icons/Hotel';
import PersonApiInvoker from '../../api/PersonApiInvoker';
import Button from '../../components/CustomButtons/Button';
import ButtonSpinner from '../../components/ButtonSpinner';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import Card from '../../components/Card/Card';
import CardBody from '../../components/Card/CardBody';
import CardHeader from '../../components/Card/CardHeader';
import CardIcon from '../../components/Card/CardIcon';
import Table from '../../components/Table/Table';
import { useFiltersParams } from '../../hooks/useFiltersParams';
import CustomersFilters from './CustomersFilters';
import './Customers.css';

class Customers extends Component {
    constructor(props) {
        super(props);

        this.state = {
            customers: [],
            address: [],
            personAddress: [],
            loading: false,
            pageNumber: 0,
            pageSize: 20,
            tableSize: 25,
            filters: { customerId:'', firstName: '', lastName: '', email: '', provinceId: null, locationId: null },
        }
    }

    componentWillMount() {
        if (this.props.address) {
            this.setState({            
                address: this.props.address,
            }, () => {
                this.props.customers && this.formatCustomers(this.props.customers);
            });
        }

        if (this.props.customers) {
            this.formatCustomers(this.props.customers);
        } else {            
            this.getCustomers();
        }
    }

    componentWillReceiveProps(next) {
        if (next.customers) {
            this.formatCustomers(next.customers);
        }

        if (next.address) {
            this.setState({ address: next.address });
        }
    }

    getAddress() {
        PersonApiInvoker.getAddress(data => {
            this.props.onGetAddress(data);
            if (!this.props.customers) {
                this.getCustomers();
            }
        }, null);
    }

    formatCustomers(data) {
        const { t } = this.props;
       
        let customers = data.map(c => {
            const actions = <div className="customers-actions">
                <Tooltip title={t('customers.tooltip.view')}>
                    <div>
                        <Button simple justIcon color="info" authority="customers_view,customers_view_related" onClick={() => browserHistory.push({
                            state: { mode: 'view'},
                            pathname: `/patient/${c.personId}`
                        })}>
                            <Assignment />
                        </Button>
                    </div>
                </Tooltip>
            </div>;
            return {
                lastName: c.lastName,
                firstName: c.firstName,
                customerId: c.id,
                email: c.email,
                location: c.localidad,
                province: c.province,
                actions,
            }
        });
        if (this.state.pageNumber !== 0) {
            customers = this.state.customers.concat(customers);
            this.setState({ tableSize: customers.length +1 });
        }
        if (this.state.customers.length > 2 && this.state.pageNumber > 0) {
            window.scrollTo({
                top: document.body.scrollHeight,
                left: 0,
                behavior: 'smooth',
            });
          }
        this.setState({ customers });
    }

    getCustomers() {
        const { filters, pageNumber, pageSize } = this.state;
        this.setState({ loading: true });
        const params = useFiltersParams(filters, pageNumber, pageSize);
        PersonApiInvoker.getCustomersFilter(params, data => {
            this.setState({ loading: false });
            if (data && data.length) {
                this.formatCustomers(data);
            }
        }, () => {
            this.setState({ loading: false });
        });
    }

    findFilters = (value) => {
        this.setState({ filters: value, customers: [] }, () => {
            this.getCustomers();
        });
    }

    cleanFilters = () => {
        this.setState({
            filters: { customerId:'', firstName: '', lastName: '', email: '', provinceId: '', locationId: '' },
            provinces: [],
            locations: [],
            customers: [],
            pageNumber: 0,
        }, () => {
            this.props.onGetCustomers([]);
            this.getCustomers();
        });
    }

    customerHistory = (id) => browserHistory.push(`/historial/${id}`);

    historiaClinicaList = (id) => browserHistory.push(`/patient/${id}/medical-record`);

    findPersonAddress(personId) {
        let address = '';
        if (this.state.personAddress && this.state.personAddress.length) {
            this.state.personAddress.map(pa => {
                if (pa.personId === personId) {
                    this.state.address.map(a => {
                        if (pa.addressId === a.addressId) {
                            address = a.address
                        }
                    })
                }
            });
        }
        return address;
    }

    showMoreCustomers = () => {
        this.setState({
          pageNumber: this.state.pageNumber + 1,
          loading: true,
        }, () => {
          this.getCustomers();
        })
    }

    render() {
        const { t } = this.props;
        const { customers, tableSize, loading } = this.state;
        return (
            <GridContainer className="customers">
                <GridItem xs={12}>
                    <Card>
                        <CardHeader icon>
                            <CardIcon color="primary">
                                <HotelIcon />
                            </CardIcon>
                            <CustomersFilters onCleanFilters={this.cleanFilters} onFindFilters={(value) => this.findFilters(value)} />
                        </CardHeader>
                        <CardBody className="appointments-content-body filtrable-table">
                            <Table
                                striped
                                filterable
                                loading={loading}
                                tableHeaderColor="primary"
                                tableHead={[
                                    { Header: t('label.firstName'), accessor: 'firstName' },
                                    { Header: t('label.lastName'), accessor: 'lastName' },
                                    { Header: t('label.identification'), accessor: 'customerId' },
                                    { Header: t('label.location'), accessor: 'location' },
                                    { Header: t('label.province'), accessor: 'province' },
                                    { Header: t('label.email'), accessor: 'email' },
                                    { Header: t('label.actions'), accessor: 'actions', sortable: false, filterable: false }
                                ]}
                                tableData={customers}
                                defaultPageSize={tableSize}
                                showPaginationTop={false}
                                showPaginationBottom={false}
                                colorsColls={['primary']}
                            />
                            {customers.length ?
                                <GridItem className="bottom" xs={12} sm={12} md={12}>
                                    <ButtonSpinner
                                        onClick={() => this.showMoreCustomers()}
                                        label={t('customers.showmore')}
                                        labelLoading={t('customers.showmore')}
                                        loading={false}
                                        typeButton="submit"
                                        block
                                        disabled={loading}
                                        className="button-gray"
                                    />
                                </GridItem>
                            : <div/>}
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        )
    }
}


Customers.propTypes = {
    onGetCustomers: PropTypes.func.isRequired,
    onGetAddress: PropTypes.func.isRequired,
    onGetPersonAddress: PropTypes.func.isRequired,
    customers: PropTypes.array,
    address: PropTypes.array,
    personAddress: PropTypes.array,
}

export default withTranslation()(Customers);
