import "./SchedulePlan.css";

import React, { Component } from "react";

import DateInput from "../DateInput";
import DayPicker from "../DayPicker";
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import PropTypes from "prop-types";
import RadioInput from "../RadioInput";
import SelectInput from "../SelectInput";
import ValidationInput from "../ValidationInput";
import isEmpty from "lodash/isEmpty";
import { withTranslation } from "react-i18next";

class SchedulePlan extends Component {
  constructor(props) {
    super(props);

    const { t } = this.props;

    this.state = {
      recurrenceTypes: [
        {
          id: "DAY",
          value: t("recurrenceTypes.daily"),
          component: null,
        },
        {
          id: "WEEK",
          value: t("recurrenceTypes.weekly"),
        },
        {
          id: "MONTH",
          value: t("recurrenceTypes.monthly"),
        },
      ],
      finishOptions: [
        {
          id: "1",
          value: t("finishOptions.finish"),
          component: false,
        },
        {
          id: "3",
          value: t("finishOptions.inDate"),
          component: true,
        },
        {
          id: "2",
          value: t("finishOptions.afterTo"),
          component: true,
        },
      ],
      recurrenceType: "",
      quantity: 1,
      quantityIntraday: null,
      every: 1,
      finish: "1",
      finishDate: "",
      finishOcurrences: "",
      monthRecurrence: "",
      weekRecurrence: [],
      invalidSelection: false,
    };
  }

  componentDidMount() {
    const { current, endDate } = this.props;
    if (current && !isEmpty(current)) {
      this.setState({
        recurrenceType: current.recurrenceType,
        quantity: current.quantity ? current.quantity : 1,
        every: current.every,
        finish: current.finish,
        finish2: current.finish
          ? current.finish
          : current.endType && current.endType === "DATE"
          ? "1"
          : current.endType === "OCURRENCES"
          ? "2"
          : null,
        finishOcurrences: current.finishOcurrences,
        monthRecurrence: current.monthRecurrence,
        endDate: endDate,
        quantityIntraday: current.quantityIntraday
          ? current.quantityIntraday
          : 1,
        weekRecurrence: current.weekRecurrence || [],
        // invalidSelection: current.invalidPlan || false,
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { savePlan, current, practiceDuration } = this.props;

    if (prevProps.endDate && !this.state.endDate) {
      this.setState({ endDate: prevProps.endDate });
    }

    if (
      savePlan &&
      prevProps.savePlan !== savePlan &&
      this.validateFields(this.state)
    ) {
      this.createRecurrence();
    }

    if (current && prevProps.current !== current) {
      this.setState({
        recurrenceType: current.recurrenceType,
        quantity: current.quantity ? current.quantity : 1,
        every: current.every,
        finish: current.finish,
        finish2: current.finish
          ? current.finish
          : current.endType && current.endType === "DATE"
          ? "1"
          : current.endType === "OCURRENCES"
          ? "2"
          : null,
        finishOcurrences: current.finishOcurrences,
        monthRecurrence: current.monthRecurrence,
        quantityIntraday: current.quantityIntraday
          ? current.quantityIntraday
          : practiceDuration
          ? 1440 / practiceDuration
          : 1,
        weekRecurrence: current.weekRecurrence
          ? current.weekRecurrence
          : current.weekDays
          ? current.weekDays
          : [],
      });
    }
  }

  handleValue(value, state) {
    if (state === "finish") {
      this.setState({ finishOcurrences: "" });
    }
    var weekRecurrence = this.state.weekRecurrence;
    if (state === "weekRecurrence") {
      weekRecurrence = value;
    }
    var quantityIntraday = this.state.quantityIntraday;
    if (state === "quantityIntraday") {
      quantityIntraday = value;
    }
    if (
      state === "recurrenceType" &&
      value === "INTRADAY" &&
      (weekRecurrence == null || weekRecurrence.length <= 0)
    ) {
      weekRecurrence = ["L", "M", "X", "J", "V", "S", "D"];
    }
    if (state === "recurrenceType" && value === "INTRADAY") {
      quantityIntraday = 1440 / this.props.practiceDuration;
    }
    this.setState(
      {
        [state]: value,
        weekRecurrence: weekRecurrence,
        quantityIntraday: quantityIntraday,
      },
      () => {
        this.createRecurrence();
      }
    );
  }

  validateFields(fields) {
    if (fields.recurrenceType) {
      if (fields.recurrenceType === "DAY") {
        return fields.quantity > 0;
      } else if (fields.recurrenceType === "WEEK") {
        if (!fields.weekRecurrence || fields.weekRecurrence.length < 1) {
          return false;
        }
      } else if (fields.recurrenceType === "MONTH") {
        if (
          !fields.monthRecurrence ||
          (fields.monthRecurrence && fields.monthRecurrence < 1) ||
          (fields.monthRecurrence && fields.monthRecurrence > 31)
        ) {
          return false;
        }
      }

      if (
        (fields.recurrenceType !== "INTRADAY" && !fields.every) ||
        (fields.recurrenceType !== "INTRADAY" &&
          fields.every &&
          fields.every < 1)
      ) {
        return false;
      }

      if (
        (fields.recurrenceType === "INTRADAY" && !fields.quantityIntraday) ||
        (fields.recurrenceType === "INTRADAY" &&
          fields.quantityIntraday &&
          fields.quantityIntraday.length < 1)
      ) {
        return false;
      }
    } else {
      return false;
    }

    if (fields.finish === "2") {
      if (
        !fields.finishOcurrences ||
        (fields.finishOcurrences && fields.finishOcurrences < 1)
      ) {
        return false;
      }
    }

    if (fields.finish === "3") {
      if (!fields.finishDate) {
        return false;
      }
    }

    return true;
  }

  createRecurrence() {
    this.props.onCreatedRecurrence({
      recurrenceType: this.state.recurrenceType,
      every: this.state.every || "",
      endDate: this.state.endDate,
      quantityIntraday: this.state.quantityIntraday,
      finish: this.state.finish ? this.state.finish : "1",
      finishOcurrences: this.state.finishOcurrences
        ? this.state.finishOcurrences
        : "",
      finishDate: this.state.finishDate ? this.state.finishDate : "",
      monthRecurrence: this.state.monthRecurrence
        ? this.state.monthRecurrence
        : "",
      weekRecurrence: this.state.weekRecurrence,
    });
  }

  getRecurrenceType = (type) => {
    const { t } = this.props;
    switch (type) {
      case "DAY":
        return t("recurrenceTypes.day");
      case "WEEK":
        return t("recurrenceTypes.week");
      case "MONTH":
        return t("recurrenceTypes.month");
      default:
        break;
    }
  };

  renderComponentFinish = (idComp, disabledComp) => {
    const { t, dates } = this.props;
    const { finish, finishOcurrences } = this.state;
    var minDate = "";
    var endDate = this.state.endDate;

    if (this.props.practiceStartDate) {
      minDate = this.props.practiceStartDate;
    } else {
      minDate = dates && dates.startDate ? dates.startDate : null;
    }
    switch (idComp) {
      case "2":
        return (
          <GridItem
            xs={7}
            className="schedule-plan-group ocurrences-group no-padding"
            onClick={!disabledComp && (() => this.setState({ finish: "2" }))}
          >
            <ValidationInput
              type="number"
              name="finishOcurrences"
              value={finish === "2" && finishOcurrences}
              onChangeValue={(value) =>
                this.handleValue(value, "finishOcurrences")
              }
              invalid={
                (finish === "2" && !finishOcurrences) ||
                (finish === "2" && finishOcurrences < 1)
              }
              errorText={
                finish === "2" && !finishOcurrences
                  ? t("error.required")
                  : finish === "2" && finishOcurrences < 1
                  ? t("error.number-min-1")
                  : null
              }
              disabled={finish !== "2" || disabledComp}
            />
            <span
              className={
                finish === "2" ? "suffix-label" : "suffix-label disabled-label"
              }
            >
              {t("schedule.plan.ocurrences")}
            </span>
          </GridItem>
        );
      case "3":
        return (
          <GridItem
            xs={7}
            className={`${
              finish !== "3" && "disabled"
            } schedule-plan-group finishDate-group no-padding`}
          >
            <DateInput
              text={t("appointment.new.schedule.practice.end_date")}
              minDate
              min={minDate}
              max={dates && dates.endDate ? dates.endDate : ""}
              value={endDate}
              onChangeValue={(value) => this.handleValue(value, "endDate")}
              invalid={finish === "3" && !endDate}
              errorText={
                finish === "3" && !endDate ? t("error.required") : null
              }
              disabled={finish !== "3" || disabledComp}
            />
          </GridItem>
        );
      default:
        break;
    }
  };

  setErrorTextQuantIntraday = (field, recurrenceType, qIntraday) => {
    const { t } = this.props;
    if (this.props.insideModule) {
      return false;
    } else {
      return !field
        ? t("error.required")
        : (field < 1 && t("error.number-min-1")) ||
            (recurrenceType === "INTRADAY" &&
              field > qIntraday &&
              t("error.number-min-1-max", { maxNum: qIntraday }));
    }
  };

  setErrorTextRecType = (recurrenceType, practiceDuration) => {
    const { t } = this.props;
    if (!this.props.insideModule) {
      return !recurrenceType
        ? t("error.required")
        : recurrenceType === "INTRADAY" && practiceDuration === null
        ? t("error.practiceRequired")
        : null;
    }
  };

  setInvalidSelection = (value) =>
    this.setState({ invalidSelection: value }, () => {
      this.props.invalidPlan(this.state.invalidSelection);
    });

  render() {
    const { t, appointmentAction, savePlan, practiceDuration } = this.props;
    const {
      recurrenceType,
      recurrenceTypes,
      every,
      monthRecurrence,
      quantityIntraday,
      finish,
      finishOptions,
      invalidSelection,
    } = this.state;
    const disabledComponent =
      appointmentAction === "assign_agenda" || this.props.disabled;
    const errorpicker = this.props.errorpicker
      ? this.props.errorpicker
      : t("error.required");

    let weekRecurrence = this.state.weekRecurrence;

    if (
      weekRecurrence &&
      !Array.isArray(weekRecurrence) &&
      weekRecurrence !== ""
    ) {
      weekRecurrence = weekRecurrence.split(",");
    }

    const getQuantIntraday = practiceDuration && 1440 / practiceDuration;
    // @TODO: setear la maxima cantidad de citas por dia como valor por defecto.
    const quantIntradayValue =
      practiceDuration && !quantityIntraday
        ? this.setState({ quantityIntraday: getQuantIntraday })
        : quantityIntraday;

    const arrayWeek = ["L", "M", "X", "J", "V", "S", "D"];
    const weekRecurrenceValue = weekRecurrence ? weekRecurrence : arrayWeek;
    return (
      <GridContainer>
        <GridItem className="base-font" xs={12}>
          <h4>{t("schedule.plan.repeat")}</h4>
        </GridItem>
        <GridItem xs={12} className="no-padding">
          <GridItem xs={12} sm={6} className="no-padding">
            <SelectInput
              label={t("schedule.plan.repeat.type")}
              elements={recurrenceTypes}
              value={recurrenceType}
              invalid={
                (!recurrenceType && savePlan) ||
                (recurrenceType === "INTRADAY" && practiceDuration === null)
              }
              errorText={this.setErrorTextRecType(
                recurrenceType,
                practiceDuration
              )}
              disabled={disabledComponent || this.props.insideModule}
              onSelectedValue={(value) =>
                this.handleValue(value, "recurrenceType")
              }
            />
          </GridItem>
        </GridItem>
        <GridItem xs={12} className="schedule-plan-group">
          <GridItem xs={3} className="custom-label no-padding">
            {recurrenceType === "INTRADAY"
              ? t("schedule.plan.repeat.day")
              : t("schedule.plan.repeat.each")}
          </GridItem>
          <GridItem xs={5} className="no-padding">
            {recurrenceType === "INTRADAY" ? (
              <ValidationInput
                type="number"
                name="quantityIntraday"
                value={quantIntradayValue}
                disabled={this.props.disabled}
                // invalid={
                //   !quantityIntraday ||
                //   quantityIntraday < 1 ||
                //   quantityIntraday > getQuantIntraday
                // }
                // errorText={this.setErrorTextQuantIntraday(
                //   quantityIntraday,
                //   recurrenceType,
                //   getQuantIntraday
                // )}
                onChangeValue={(value) =>
                  this.handleValue(value, "quantityIntraday")
                }
              />
            ) : (
              <ValidationInput
                type="number"
                name="every"
                value={every}
                disabled={this.props.disabled}
                invalid={every < 1 || !every}
                // errorText={
                //   !every
                //     ? t("error.required")
                //     : every < 1 && t("error.number-min-1")
                // }
                onChangeValue={(value) => this.handleValue(value, "every")}
              />
            )}
          </GridItem>
          {recurrenceType !== "INTRADAY" && (
            <GridItem className="no-padding">
              <span className="suffix-label">
                {this.getRecurrenceType(recurrenceType)}
              </span>
            </GridItem>
          )}
        </GridItem>
        <GridItem
          xs={12}
          className="schedule-plan-group days-selector no-padding"
        >
          {(recurrenceType === "WEEK" || recurrenceType === "INTRADAY") && (
            <DayPicker
              xs={9}
              label={t("recurrenceTypes.weekly.dayPickerLabel")}
              appointmentAction={appointmentAction}
              value={weekRecurrenceValue}
              insideModule={this.props.insideModule}
              disabled={this.props.disabled}
              indexArrayDays={this.props.indexArrayDays}
              onChange={(value) => this.handleValue(value, "weekRecurrence")}
              invalidSelection={(value) => this.setInvalidSelection(value)}
              invalid={(!weekRecurrence || !weekRecurrence.length) && savePlan}
              errorText={errorpicker}
            />
          )}
          {recurrenceType === "MONTH" && (
            <GridItem xs={12} className="schedule-plan-group">
              <GridItem xs={3} className="custom-label no-padding">
                {t("schedule.plan.repeat.month")} *
              </GridItem>
              <GridItem xs={5} className="no-padding">
                <ValidationInput
                  type="number"
                  onChangeValue={(value) =>
                    this.handleValue(value, "monthRecurrence")
                  }
                  value={monthRecurrence}
                  disabled={this.props.disabled}
                  invalid={
                    !monthRecurrence ||
                    monthRecurrence < 1 ||
                    monthRecurrence > 31
                  }
                  errorText={
                    !monthRecurrence
                      ? t("error.required")
                      : (monthRecurrence < 1 || monthRecurrence > 31) &&
                        t("error.number-min-1-max-31")
                  }
                />
              </GridItem>
            </GridItem>
          )}
        </GridItem>

        {false && this.props.insideModule === false ? (
          <GridItem
            xs={12}
            className="base-font schedule-plan-finish no-padding"
          >
            <h4 className="padding-horizontal">{t("schedule.plan.finish")}</h4>
            <RadioInput
              onChangeValue={(value) => this.handleValue(value, "finish")}
              elements={finishOptions}
              disabled={this.props.disabled}
              value={finish ? finish : "1"}
              components={(id, disabled) =>
                this.renderComponentFinish(id, disabled)
              }
              inputProps={{ disabled: disabledComponent }}
            />
          </GridItem>
        ) : (
          <div />
        )}
      </GridContainer>
    );
  }
}

SchedulePlan.defaultProps = {
  dates: {},
  practiceDuration: null,
};

SchedulePlan.propTypes = {
  onCreatedRecurrence: PropTypes.func,
  savePlan: PropTypes.bool,
  current: PropTypes.object,
  dates: PropTypes.object,
  practiceDuration: PropTypes.array,
  invalidPlan: PropTypes.func,
};

export default withTranslation()(SchedulePlan);
