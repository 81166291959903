import './Alerts.css'

import React, { Component } from 'react';

import Card from '../Card/Card';
import CardBody from '../Card/CardBody';
import CardHeader from '../Card/CardHeader';
import CardIcon from '../Card/CardIcon';
import FormControl from "@material-ui/core/FormControl";
import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import NotificationImportant from '@material-ui/icons/NotificationImportant';
import PersonApiInvoker from "../../api/PersonApiInvoker";
import PropTypes from 'prop-types';
import Select from "@material-ui/core/Select";
import Table from '../Table/Table';
import VerificationApiInvoker from "../../api/VerificationApiInvoker";
import moment from 'moment';
import { withTranslation } from 'react-i18next';

class Alerts extends Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: false,
            alerts: [],
            allAlerts: [],
            professionals: [],
            filterStatus: '',
            filterProfessionals: '',
        }
    }

    componentWillMount() {
        if (this.props.professionals) {
            this.setState({
                professionals: this.props.professionals
            })
        } else {
            this.getProfessionals()
        }

        //this.getAlerts();
    }

    componentWillReceiveProps(next) {
        if (next.professionals) {
            this.setState({
                professionals: next.professionals
            })
        }
    }

    getProfessionals() {
        PersonApiInvoker.getEmployees(data => {
            this.props.onGetProfessionals(data);
        }, null)
    }

    getAlerts() {
        this.setState({
            loading: true,
        })
        VerificationApiInvoker.getVerificationErrors(data => {
            this.setState({
                loading: false,
            })
            if (!data.message) {
                this.setState({
                    allAlerts: this.formatTableData(data),
                    alerts: this.formatTableData(data)
                })
            }
        }, e => {
            this.setState({
                loading: false,
            })
        })
    }

    formatTableData(alerts) {
        return alerts.map(a => {
            return {
                errorId: a.verificationErrorId,
                date: moment(a.updatedDateTime).format('DD-MM-YYYY hh:mm'),
                status: a.status,
                employee: a.employeeId,
                employeeName: `${a.employeeFirstName} ${a.employeeLastName}`,
                customerName: `${a.customerFirstName} ${a.customerLastName}`
            }
        });
    }

    handleStatus(e) {
        let data = this.state.allAlerts.filter(a => {
            return a.status === e.target.value;
        })

        this.setState({
            alerts: data,
            filterStatus: e.target.value
        })
    }

    handleProfessionals(e) {
        let data = this.state.allAlerts.filter(a => {
            return a.employeeId === e.target.value
        })

        this.setState({
            alerts: data,
            filterProfessionals: e.target.value
        })
    }

    render() {
        const { t } = this.props;
        return (
            <div className="alerts">
                <GridContainer>
                    <GridItem xs={12}>
                        <Card>
                            <CardHeader icon>
                                <CardIcon color="secondary">
                                    <NotificationImportant />
                                </CardIcon>
                                <h4 className="card-title">{t("alerts.title")}</h4>
                                <div className="alerts-filters">
                                    <FormControl className="alerts-dropdown">
                                        <InputLabel
                                            htmlFor="status-select">
                                            {t("alerts.input.status")}
                                        </InputLabel>
                                        <Select
                                            MenuProps={{
                                            }}
                                            value={this.state.filterStatus}
                                            onChange={(event) => this.handleStatus(event)}
                                            inputProps={{
                                                name: "statusSelect",
                                                id: "status-select"
                                            }}
                                        >
                                            <MenuItem key={`alerts-ignored`} value={t("alerts.input.status.ignored")}>
                                                {t("alerts.input.status.ignored")}
                                            </MenuItem>
                                            <MenuItem key={`alerts-failed`} value={t("alerts.input.status.failed")}>
                                                {t("alerts.input.status.failed")}
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </CardHeader>
                            <CardBody className="alerts-content-body">
                                <Table
                                    striped
                                    loading={this.state.loading}
                                    tableHeaderColor="primary"
                                    tableHead={[
                                        { Header: t("alerts.table.id"), accessor: 'errorId' },
                                        { Header: t("alerts.table.date"), accessor: 'date' },
                                        { Header: t("label.status"), accessor: 'status' },
                                        { Header: t("alerts.table.professional"), accessor: 'employeeName' },
                                        { Header: t("alerts.table.customer"), accessor: 'customerName' }
                                    ]}
                                    tableData={this.state.alerts}
                                    colorsColls={["primary"]}
                                />
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
        )
    }
}

Alerts.propTypes = {
    onGetProfessionals: PropTypes.func.isRequired,
    professionals: PropTypes.array,
}

export default withTranslation()(Alerts);