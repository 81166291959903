import "./Login.css";

import {
  Checkbox,
  CircularProgress,
  FilledInput,
  FormControl,
  FormControlLabel,
  IconButton,
  withStyles,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";

import BusinessIcon from "@material-ui/icons/Business";
import Button from "../../components/CustomButtons/Button";
import Card from "../../components/Card/Card";
import CardBody from "../../components/Card/CardBody";
import CardFooter from "../../components/Card/CardFooter";
import { Check } from "@material-ui/icons";
import { Component } from "react";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import InputAdornment from "@material-ui/core/InputAdornment";
import LetterAvatar from "../../components/Avatar/LetterAvatar";
import { Link } from "react-router";
import Person from "@material-ui/icons/Person";
import PropTypes from "prop-types";
import SelectInput from "../../components/SelectInput";
import Snackbar from "../../components/Snackbar/Snackbar";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import VpnKey from "@material-ui/icons/VpnKey";
import { browserHistory } from "react-router";
import hmmLogo from "../../assets/img/login/logo-hmm-mobility.png";
import isEmpty from "lodash/isEmpty";
import pwdBy from "../../assets/img/glin-white.png";
import styles from "../../assets/components/customCheckboxRadioSwitch";
import { withTranslation } from "react-i18next";

class Login extends Component {
  profileTimeOut;

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      disableInteractions: false,
      alertColor: "success",
      alertMessage: "",
      alertOpen: false,
      checking: false,
      rememberMe: false,
      showPassword: false,
      realms: [],
      disabled_realm: false,
      realm: "",
      profile: {},
      isSubmitLogin: false,
    };
  }

  componentWillMount() {
    clearTimeout(this.profileTimeOut);
  }

  handleLogin = () => {
    this.setState({ isSubmitLogin: true });
    let realm = this.state.realms.filter((r) => r["id"] == this.state.realm);
    if (realm.length == 0) {
      return;
    }

    const { t } = this.props;
    this.setState({
      disableInteractions: true,
      loading: true,
    });
    realm = realm[0]["code"];
    fetch(`/api/auth/token`, {
      method: "POST",
      body: JSON.stringify({
        username: document.getElementById("username").value,
        password: document.getElementById("password").value,
        realm: realm,
      }),
      headers: new Headers({
        "Content-Type": "application/json",
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (!data.error) {
          fetch(`/api/profile`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          })
            .then((response) => response.json())
            .then((profile) => {
              if (profile) {
                this.setState({ profile }, () => {
                  this.profileTimeOut = setTimeout(() => {
                    this.setState({
                      disableInteractions: false,
                      loading: false,
                    });
                    //this.props.onGetCompanies(profile.companies);
                    if (profile.companies && profile.companies.length > 0) {
                      localStorage.setItem(
                        "company",
                        JSON.stringify(profile.companies[0])
                      );
                      localStorage.setItem(
                        "itlg_default_company_id",
                        profile.companies[0].company_id
                      );
                      localStorage.setItem(
                        "itlg_default_company_name",
                        profile.companies[0].name
                      );
                    }
                    if (profile.company_partnership_id != null) {
                      localStorage.setItem(
                        "company_partnership_id",
                        profile.company_partnership_id == null ||
                          profile.company_partnership_id == "null"
                          ? null
                          : profile.company_partnership_id
                      );
                    } else {
                      localStorage.removeItem("company_partnership_id");
                    }
                    localStorage.setItem("user_id", profile.userId);
                    localStorage.setItem(
                      "userName",
                      `${profile.firstName} ${profile.lastName}`
                    );
                    localStorage.setItem("email", profile.email);
                    localStorage.setItem("APP_VERSION", profile.APP_VERSION);
                    localStorage.setItem("RELEASE_DATE", profile.RELEASE_DATE);
                    browserHistory.push("/solicitudes");
                  }, 3000);
                });
              }
            });
        } else {
          this.openAlert("danger", t("login.bad_credentials"));
          this.setState({
            disableInteractions: false,
            loading: false,
          });
        }
      })
      .catch((e) => {
        this.openAlert("danger", t("login.bad_credentials"));
        this.setState({
          disableInteractions: false,
          loading: false,
        });
      });
  };

  handleRealms = () => {
    const { t } = this.props;
    this.setState({
      disableInteractions: true,
      loading: true,
    });
    fetch(`/api/realms`, {
      method: "POST",
      body: JSON.stringify({
        username: document.getElementById("username").value,
      }),
      headers: new Headers({
        "Content-Type": "application/json",
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          disableInteractions: false,
          loading: false,
        });

        if (!data.error) {
          var realms = [];
          data.forEach((element, idx) => {
            realms.push({
              id: idx + 1,
              value: element["realmName"],
              code: element["realm"],
            });
          });
          if (realms.length == 0) {
            this.setState({ realms: realms, realm: -1, disabled_realm: true });
          } else if (realms.length == 1) {
            this.setState({
              realms: realms,
              realm: realms[0]["id"],
              disabled_realm: true,
            });
          } else {
            this.setState({ realms: realms, realm: realms[0]["id"] });
          }
        } else {
          this.openAlert("danger", t("login.bad_credentials"));
          this.setState({
            disableInteractions: false,
            loading: false,
          });
        }
      })
      .catch((e) => {
        this.setState({
          disableInteractions: false,
          loading: false,
        });
      });
  };

  openAlert(color, message) {
    this.setState({
      alertColor: color,
      alertMessage: message,
      alertOpen: true,
    });

    setTimeout(() => {
      this.setState({ alertOpen: false });
    }, 5000);
  }

  handleRememberMe = (event) => {
    this.setState({ rememberMe: event.target.checked });
  };

  handleValue = (value) => {
    this.setState({ realm: value });
  };

  render() {
    const { t, classes } = this.props;
    return (
      <div>
        <div>
          <div className="wrapper">
            <div className="fullpage">
              <div className="login">
                <GridContainer justify="center">
                  <GridItem xs={12} sm={8}>
                    <div className="hmm-logo">
                      <img src={hmmLogo} alt="HMM Logo" />
                    </div>
                    <form
                      id="login-form"
                      onSubmit={this.handleLogin}
                      onKeyPress={({ key }) =>
                        key === "Enter" && this.handleLogin()
                      }
                    >
                      <Card login className="login-card">
                        <CardBody>
                          {this.state.loading &&
                          !isEmpty(this.state.profile) ? (
                            <div className="loading-profile">
                              <LetterAvatar
                                user={
                                  this.state.profile.firstName +
                                  " " +
                                  this.state.profile.lastName
                                }
                              />
                              <span className="loading-profile-welcome">
                                {t("login.welcome")}
                              </span>
                              <span className="loading-profile-username">
                                {`${this.state.profile.firstName} ${this.state.profile.lastName}`}
                              </span>
                              <CircularProgress className="circular-progress" />
                            </div>
                          ) : (
                            <React.Fragment>
                              <FormControl variant="filled">
                                <FilledInput
                                  disabled={this.state.disableInteractions}
                                  id="username"
                                  onBlur={() => this.handleRealms()}
                                  inputProps={{
                                    disabled: this.state.disableInteractions,
                                    placeholder: t("login.user"),
                                  }}
                                  type="text"
                                  startAdornment={
                                    <InputAdornment position="start">
                                      <Person />
                                    </InputAdornment>
                                  }
                                />
                              </FormControl>

                              <FormControl>
                                <SelectInput
                                  className="company"
                                  placeholder={t("login.realm")}
                                  disabled={this.state.disabled_realm}
                                  elements={this.state.realms}
                                  value={this.state.realm}
                                  onSelectedValue={(value) =>
                                    this.handleValue(value)
                                  }
                                  invalid={
                                    !this.state.realm &&
                                    this.state.isSubmitLogin
                                  }
                                  error={
                                    !this.state.realm &&
                                    this.state.isSubmitLogin
                                  }
                                  isAdornedStart={true}
                                  iconAdornedStart={BusinessIcon}
                                />
                              </FormControl>

                              <FormControl variant="filled">
                                <FilledInput
                                  disabled={this.state.disableInteractions}
                                  id="password"
                                  inputProps={{
                                    disabled: this.state.disableInteractions,
                                    placeholder: t("login.password"),
                                  }}
                                  type={
                                    !this.state.showPassword
                                      ? "password"
                                      : "text"
                                  }
                                  startAdornment={
                                    <InputAdornment position="start">
                                      <VpnKey />
                                    </InputAdornment>
                                  }
                                  endAdornment={
                                    <InputAdornment position="end">
                                      <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() =>
                                          this.setState({
                                            showPassword:
                                              !this.state.showPassword,
                                          })
                                        }
                                        edge="end"
                                      >
                                        {this.state.showPassword ? (
                                          <Visibility />
                                        ) : (
                                          <VisibilityOff />
                                        )}
                                      </IconButton>
                                    </InputAdornment>
                                  }
                                />
                              </FormControl>
                            </React.Fragment>
                          )}
                        </CardBody>
                        {!this.state.loading ? (
                          <CardFooter className="login-sign-in">
                            <Button
                              onClick={this.handleLogin}
                              className="signIn"
                              variant="contained"
                              disabled={this.state.disableInteractions}
                            >
                              {t("login.signin")}
                            </Button>
                            <div>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    tabIndex={-1}
                                    className="login-remember-me"
                                    checked={this.state.rememberMe}
                                    onChange={this.handleRememberMe}
                                    checkedIcon={
                                      <Check className={classes.checkedIcon} />
                                    }
                                    icon={
                                      <Check
                                        className={classes.uncheckedIcon}
                                      />
                                    }
                                    classes={{
                                      checked: classes.checked,
                                      root: classes.checkRoot,
                                    }}
                                  />
                                }
                                classes={{
                                  label: classes.label,
                                  root: "check-label",
                                }}
                                label={t("login.remember_me")}
                              />
                            </div>
                            <div
                              className="login-recover"
                              style={
                                this.state.loading
                                  ? {
                                      pointerEvents: "none",
                                    }
                                  : {}
                              }
                            >
                              <Link className="login-recover-link" to="/">
                                {t("login.forgot")}
                              </Link>
                            </div>
                          </CardFooter>
                        ) : null}
                      </Card>
                      <Snackbar
                        place="tr"
                        color={this.state.alertColor}
                        message={this.state.alertMessage}
                        open={this.state.alertOpen}
                      />
                    </form>
                  </GridItem>
                </GridContainer>
              </div>
              <div className="login-footer powered-by">
                <a
                  href="https://glin-technologies.com/"
                  rel="noopener noreferrer"
                  target="_blank"
                  title="Glin"
                >
                  <img src={pwdBy} alt="Glin" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Login.propTypes = {
  onUserLogged: PropTypes.func.isRequired,
  companies: PropTypes.array,
  onGetCompanies: PropTypes.func,
};

export default withStyles(styles)(withTranslation()(Login));
