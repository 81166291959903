import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import PropTypes from 'prop-types';
import Cookies from 'universal-cookie';
import Sidebar from '../Sidebar/Sidebar';
import routes from '../../routes';
import image from '../../assets/img/sidebar-2.jpg';
import './Navbar.css';

const Navbar = (props) => {
    const [routesNav, setRoutesNav] = useState([]);
    const [user, setUser] = useState({});
    
    useEffect(() => {
        const cookies = new Cookies();
        if (!cookies.get('sessionActive')) {            
            browserHistory.push('/login');
        }

        setUser(localStorage.getItem('username'));
        setRoutesNav(routes);
      return () => {
        setUser({});
        setRoutesNav([]);
      }
    }, [])

    return (
        <Sidebar 
            routes={routesNav}
            logoText="HMM"
            color="transparent"
            bgColor="primary"
            user={user}
            image={image}
            miniActive={props.miniActive}
            handleDrawerToggle={props.handleDrawerToggle}
            open={props.open}
            companies={props.companies}
        />
    )
}

Navbar.propTypes = {
    miniActive: PropTypes.bool,
    open: PropTypes.bool,
    handleDrawerToggle: PropTypes.func,
}

const mapStateToProps = (state) => {
    return {
        companies: state.user.companies,
    }
};

export default connect(mapStateToProps, null)(Navbar);
