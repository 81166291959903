import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { Icon } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import ButtonSpinner from '../../ButtonSpinner';
import Button from '../../CustomButtons/Button';
import DateInput from '../../DateInput';
import GridContainer from '../../Grid/GridContainer';
import GridItem from '../../Grid/GridItem';
import SelectInput from '../../SelectInput';
import StatusIcon from '../StatusIcon';
import TimePickerGCalendar from '../../TimePickerGCalendar';
import ValidationInput from '../../ValidationInput';
import AppointmentApiInvoker from '../../../api/AppointmentApiInvoker';
import PersonApiInvoker from '../../../api/PersonApiInvoker';
import SchedulerInvoker from '../../../api/SchedulerInvoker';
import Snackbar from '../../Snackbar/Snackbar';

class BookingDetail extends Component {
  constructor(props) {
    super(props)

    this.state = {
      current: null,
      professionals: [],
      errors: [],
      disabled: false,
      status: '',
      hasChanged: false,
      validation: false,
      validationResult: null,
      validating: false,
      busy: false,
      alertColor: null,
      alertMessage: "",
      alertOpen: false,
    }
  }

  componentDidMount() {
    this.getDetail()
  }

  componentWillReceiveProps(next) {
      
      if (next.errors) {
        var error = "";
        next.errors.map(e => {
           error = error + "\n" + e.detail
        });
        this.openAlert("danger", error);
        this.setState({
          disabled: false,
          status: next.status,
          validation: false,
          busy: false,

        })
      } 
      
  }

  openAlert(color, message) {
    this.setState({
        alertColor: color,
        alertMessage: message,
        alertOpen: true,
    })
    setTimeout(() => {
      this.setState({ alertOpen: false });
    }, 2000);
  }


  getDetail() {
    const { event } = this.props;
    
    if (event) {
      if (event.type === 'schedule') {
        AppointmentApiInvoker.getScheduleInCalendar(event.id, details => {
          this.setState(
            { current: details },
            () => this.getEmployees()
          )
        }, null);
      }
      else {
        AppointmentApiInvoker.getScheduleBookingInCalendar(event.id, details => {
          this.setState(
            { current: details },
            () => this.getEmployees()
          )
        }, null);
      }
    }
  }

  getBookingDetail() {
    const { event } = this.props;
    if (event) {
      AppointmentApiInvoker.getScheduleBookingInCalendar(event.id, details => {
        details.employeeId = event.employeeId
        this.setState({ current: details });
      }, null);
    }
  }

  getEmployees() {
    const { current } = this.state;

    if (current) {
      PersonApiInvoker.getEmployeesByPracticetype(current.practiceTypeId, data => {
        this.setState({ professionals: this.formatEmployees(data) });
      }, null);
    }
  }

  formatEmployees(data) {
    let result = [];
    data.forEach(e => {
      result.push({
        id: e.personId,
        value: `${e.firstName} ${e.lastName}`,
      })
    })
    return result;
  }

  cancel() {
    this.props.onCancel();
  }

  updateBooking(checking) {
    const { event } = this.props;

    if (event) {
      const fetchUrl = event.type === 'schedule'
        ? 'schedules'
        : 'bookings';

      const body = {
        employeeId: this.state.current.employeeId,
        scheduleDateTime: `${moment(this.state.current.scheduleDate).format(
          'YYYY-MM-DD'
        )}T${this.state.current.startTime}`,
      };

      if (checking) {
        this.setState({
          "validationResult": null,
          "validation": false,
          "validating": true,
          busy: false,
        });

        SchedulerInvoker.postDynamicContentOnlyCheck(fetchUrl, event.id, body, data => {
          this.setState({
            "validationResult": data,
            "validation": data.status !== "ERROR",
            "validating": false,
            busy: false,
          });
        }, error => {
          this.setState({ "validating": false });
        });
      }
      else {
        
        this.setState({ busy: true });
        SchedulerInvoker.postDynamicContent(fetchUrl, event.id, body, data => {
          if (this.props.updateAppointment) {
            this.props.updateAppointment(data);
          }
          if(this.props.onUpdate) {
            this.props.onUpdate(data);
          }
        }, error => {
          this.setState({ busy: false });
        });
      }
    }

  }

  handleChange = (value, state) => {
    let hasChanged
    if (state === 'startTime') {
      hasChanged = (this.props.event.start && moment(this.props.event.start.toString().split('T')[1], 'HH:mm').format('HH:mm') !== value) ||
        moment(value, 'HH:mm').format('HH:mm') !== moment(this.state.current.startTime, 'HH:mm').format('HH:mm');
    } else {
      hasChanged = this.state.current[state] !== value;
    }

    let endTime = this.state.current.endTime;
    if (state === 'startTime') {
      endTime = moment()
        .set('hours', value.split(":")[0])
        .set('minutes', value.split(":")[1])
        .add(this.state.current.estimatedDuration, 'minutes')
        .format("HH:mm")
    }

    this.setState(prevState => ({
      current: {
        ...prevState.current,
        endTime,
        [state]: value
      },
      hasChanged,
      validation: !hasChanged,
      disabled: false,
      errors: []
    }))
  }

  render() {
    const { t, disabled, event, readOnly } = this.props;
    const { busy, current, errors, hasChanged, professionals, status, validating, validation, validationResult } = this.state;
    const disabledState = this.state.disabled;
    const today = moment.utc();
    const scheduleDate = current && current.scheduleDate;
    const dateIsPast = today.isAfter(scheduleDate, "day");

    return (
      <div className="booking-detail-form">
        {current && (
          <GridContainer>
            <GridItem xs={12} sm={event && event.id ? 6 : 12}>
              <ValidationInput
                text={t('common.patient')}
                value={`${current.customerFirstName} ${current.customerLastName}`}
                disabled
              />
            </GridItem>
            {event && event.id &&
              <GridItem xs={12} sm={6}>
                <ValidationInput
                  text={t('common.scheduleNumber')}
                  value={event.id}
                  disabled
                />
              </GridItem>
            }
            <GridItem xs={12}>
              {(dateIsPast || readOnly) ? (
                    <SelectInput
                      label={`${t('common.professional')} *`}
                      elements={professionals}
                      value={current.employeeId}
                      disabled
                      onSelectedValue={value => this.handleChange(value, 'employeeId')}
                      invalid={!current.employeeId}
                    />
                ) : (
                  <SelectInput
                    label={`${t('common.professional')} *`}
                    elements={professionals}
                    value={current.employeeId}
                    disabled={disabled}
                    onSelectedValue={value => this.handleChange(value, 'employeeId')}
                    invalid={!current.employeeId}
                  />
                )}
            </GridItem>
            <GridItem xs={12}>
              <ValidationInput
                text={t('common.speciality')}
                value={current.practiceTypeName}
                disabled
              />
            </GridItem>
            <GridItem xs={12}>
                {(current.practiceTypeName) ?
                <ValidationInput
                    text={t('estimated_label')}
                    value={`${moment.utc(moment.duration(parseInt(current.estimatedDuration), 'minutes').asMilliseconds()).format('HH:mm')}${t('estimated_minutes')}`}
                    disabled
                  /> : ""
                } 
            </GridItem>
            <GridItem xs={12}> 
                <ValidationInput
                    text={t('schedule.booking.detail.statusname')}
                    value={
                      (current.scheduleStatus) 
                      ? t(`schedule.booking.state.${current.scheduleStatus}`) 
                      : t(`schedule.booking.state.${current.scheduleBookingStatus}`) }
                    disabled
                  />
            </GridItem>
            <GridItem xs={12}>
                {(current.scheduleStatus === 'REJECTED') ?
                <ValidationInput
                    text={t('schedule.booking.detail.reasonRejected')}
                    value={current.reasonRejected}
                    disabled
                  /> : ""
                } 
            </GridItem>
            <GridItem xs={12}>
              {dateIsPast ? (
                  <span>
                    <DateInput
                      text={t('common.date')}
                      disabled
                      value={current.scheduleDate}
                      onChangeValue={value => this.handleChange(value, 'scheduleDate')}
                    />
                  </span>
                )
                : (<DateInput
                    text={t('common.date')}
                    disabled={disabled || readOnly}
                    value={current.scheduleDate}
                    onChangeValue={value => this.handleChange(value, 'scheduleDate')}
                  />)
              }
            </GridItem>
            <GridItem xs={6}>
              {(dateIsPast || readOnly) ? (
                  <span>
                    <TimePickerGCalendar
                      text={`${t('common.startTime')} *`}
                      value={current.startTime}
                      onSelectedValue={value => this.handleChange(value, 'startTime')}
                      disabled
                      name="booking_detail_start_time"
                      invalid={!current.startTime}
                      errorText={!current.startTime ? t("error.required") : ''}
                    />
                  </span>
                ) : 
                (<TimePickerGCalendar
                  text={`${t('common.startTime')} *`}
                  value={current.startTime}
                  onSelectedValue={value => this.handleChange(value, 'startTime')}
                  disabled={disabled}
                  name="booking_detail_start_time"
                  invalid={!current.startTime}
                  errorText={!current.startTime ? t('error.required') : ''}
                />)
              }
            </GridItem>
            <GridItem xs={6}>
              <TimePickerGCalendar
                name="booking_detail_end_time"
                text={t('common.endTime')}
                value={current.endTime}
                onSelectedValue={current.endTime}
                invalid={!current.endTime}
                disabled
                errorText={!current.endTime ? t("error.required") : ''}
              />
            </GridItem>
            <GridItem xs={12}>
              <ValidationInput
                text={t('address.title')}
                value={`${current.addressStreet} ${current.addressNumber}`}
                disabled
              />
            </GridItem>
        
            {errors && errors.length > 0 &&
              errors.map(e => {
                return (
                  <GridItem xs={12} className={e.statusError === 'ERROR' ? 'error' : 'warning'}>
                    <Icon color={e.statusError === 'ERROR' ? 'error' : 'warning'}>
                      {
                        e.statusError === 'ERROR' ? 'error' : 'warning'
                      }
                    </Icon>{' '}
                    <span>{e.detail}</span>
                  </GridItem>
                )
              })
            }
            <GridItem xs={12}>
              <div>
                {validating ?
                  <div style={{
                    display: 'flex',
                    height: '200px',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                    <CircularProgress />
                  </div> : ''}
              </div>
              {validationResult && (
                <StatusIcon
                  value={
                    validationResult.status
                  }
                />
              )}
            </GridItem>

            {validationResult &&
              validationResult.status !== "OK" ?
              validationResult.errors.map(e => {
                return (<GridItem xs={12}>
                  <GridContainer><GridItem xs={12} sm={7} className={e.statusError === 'ERROR' ? 'error' : 'warning'}>
                    {' '}
                    <span>{t(e.detail)}</span>
                  </GridItem></GridContainer>
                </GridItem>)
              })
              : ''
            }

            <GridItem xs={12} className="booking-detail-actions">
              <Button color="danger" onClick={() => this.cancel()}>
                {event.type === 'booking' || !event.action ? t('cancel') : t('common.close')}
              </Button>
              {!readOnly && ((event.type ==='booking' && !disabled) || !event.action ) && (
                <>
                  <Button
                    color="info"
                    onClick={() => this.updateBooking(true)}
                    disabled={!hasChanged || validating}
                  >
                    {t('common.check')}
                  </Button>

                  <ButtonSpinner
                    onClick={() => this.updateBooking()}
                    disabled={
                      busy || !hasChanged ||
                      (disabledState && status === 'ERROR' || !validation)
                    }
                    label={t('common.accept')}
                    labelLoading={t('common.saving')}
                    loading={busy}
                    typeButton="submit"
                    color="primary"
                  />
                </>
              )}
            </GridItem>
          </GridContainer>
        )}
      </div>
    )
  }
}

BookingDetail.propTypes = {
  event: PropTypes.object,
  onCancel: PropTypes.func,
  updateAppointment: PropTypes.func,
  errors: PropTypes.array,
  status: PropTypes.string,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
}

export default withTranslation()(BookingDetail);
