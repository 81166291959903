import "../NewSchedule.css";

import React, { Component } from "react";

import { AlertTitle } from "@material-ui/lab";
import Card from "../../Card/Card";
import CardBody from "../../Card/CardBody";
import CardHeader from "../../Card/CardHeader";
import CardIcon from "../../Card/CardIcon";
import GridContainer from "../../Grid/GridContainer";
import GridItem from "../../Grid/GridItem";
import HotelIcon from "@material-ui/icons/Hotel";
import PersonApiInvoker from "../../../api/PersonApiInvoker";
import PropTypes from "prop-types";
import SelectInput from "../../SelectInput";
import { TextField } from "@material-ui/core";
import store from "../../../store";
import { withTranslation } from "react-i18next";

class CustomerForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      customers: [],
      customer: null,
      customerId: null,
      addresses: [],
      address: "",
      zone: "",
      location: "",
      province: "",
    };
  }

  componentDidMount() {
    this.getCustomers();
    if (this.props.value) {
      this.handleValue(this.props.value.customer, "customer");
      this.handleValue(this.props.value.address, "address");
    }
  }

  componentWillReceiveProps(next, state) {
    if (next.customers) {
      this.setState({
        customers: this.formatCustomers(next.customers),
      });
    }

    if (this.props.value && this.props.value.customer && this.state.customers) {
      const customerIndex = this.state.customers.findIndex(
        (item) => item.id === this.props.value.customer
      );
      if (customerIndex !== -1) {
        this.setState({
          customerId: this.state.customers[customerIndex].customerId,
          customer: this.state.customers[customerIndex],
        });
      } else {
        this.setState({
          value: this.props.value,
        });
      }
    }

    if (this.props.value && this.props.value.address) {
      this.setState({
        address: this.props.value.address,
        zone: this.props.value.zone,
        location: this.props.value.zone,
        province: this.props.value.province,
        address_name: this.props.value.address_name,
      });
    }
  }

  formatCustomers = (customers) => {
    let result = customers.map((c) => {
      return {
        id: c.personId,
        value: `${c.firstName} ${c.lastName}`,
        customerId: c.id,
      };
    });
    return result;
  };

  formatAddresses = (addresses) => {
    let result = addresses.map((a) => {
      return {
        id: a.addressId,
        value: `${a.street} ${a.number}`,
        zone: a.geographicZone.detail,
        geographicZoneId: a.geographicZone.geographicZoneId,
        location: a.location.name,
        province: a.provinceName,
        latitude: a.latitude,
        longitude: a.longitude,
      };
    });
    return result;
  };

  getCustomers() {
    if (
      store.getState().customers.customers &&
      store.getState().customers.customers.length > 0
    ) {
      this.refreshData(store.getState().customers.customers);
      this.setState({
        customers: this.formatCustomers(store.getState().customers.customers),
      });
    } else {
      PersonApiInvoker.getCustomers(
        (data) => {
          this.props.onGetCustomers(data);
          this.refreshData(data);
          this.setState({
            customers: this.formatCustomers(data),
          });
        },
        (e) => {
          this.setState({
            loading: false,
          });
        }
      );
    }
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    if (nextState.customer === "") {
      this.props.setData(true);
      this.setState({
        customerId: null,
        customer: {
          id: null,
          value: null,
        },
      });
      this.setState({
        address: null,
        zone: null,
        location: null,
        province: null,
        address_name: null,
      });
    } else {
      this.props.setData(false);
    }
  }

  refreshData = (data) => {
    if (this.state.value && this.state.value.customer) {
      const customerIndex = data.findIndex(
        (item) => item.personId === this.state.value.customer
      );
      if (customerIndex !== -1) {
        this.setState({
          customerId: data[customerIndex].id,
          customer: {
            id: data[customerIndex].personId,
            value: data[customerIndex].firstName,
          },
        });
      }
    }
  };

  getAddresses(personId) {
    if (personId) {
      PersonApiInvoker.getPersonsAddress(
        personId,
        (data) => {
          if (data && data.length) {
            this.setState(
              {
                addresses: this.formatAddresses(data),
              },
              () => {
                let address = null;
                if (this.state.addresses.length === 0) {
                  address = {
                    address: "",
                    zone: "",
                    location: "",
                    province: "",
                    geographicZoneId: "",
                    latitude: "",
                    longitude: "",
                    address_name: "",
                  };
                } else if (this.state.addresses.length === 1) {
                  address = {
                    address: this.state.addresses[0].id,
                    zone: this.state.addresses[0].zone,
                    location: this.state.addresses[0].location,
                    province: this.state.addresses[0].province,
                    geographicZoneId: this.state.addresses[0].geographicZoneId,
                    latitude: this.state.addresses[0].latitude,
                    longitude: this.state.addresses[0].longitude,
                    address_name: this.state.addresses[0].value,
                  };
                } else if (this.state.addresses.length > 1) {
                  const addressIndex = this.state.addresses.findIndex(
                    (item) => item.id === this.state.address
                  );
                  if (addressIndex !== -1) {
                    address = {
                      address: this.state.addresses[addressIndex].id,
                      zone: this.state.addresses[addressIndex].zone,
                      location: this.state.addresses[addressIndex].location,
                      province: this.state.addresses[addressIndex].province,
                      geographicZoneId:
                        this.state.addresses[addressIndex].geographicZoneId,
                      latitude: this.state.addresses[addressIndex].latitude,
                      longitude: this.state.addresses[addressIndex].longitude,
                      address_name: this.state.addresses[addressIndex].value,
                    };
                  } else {
                    address = {
                      address: "",
                      zone: "",
                      location: "",
                      province: "",
                      geographicZoneId: "",
                      latitude: "",
                      longitude: "",
                      address_name: "",
                    };
                  }
                }
                if (address) {
                  this.setState(
                    {
                      address: address.address,
                      zone: address.zone,
                      location: address.location,
                      province: address.province,
                      geographicZoneId: address.geographicZoneId,
                      latitude: address.latitude,
                      longitude: address.longitude,
                      address_name: address.address_name,
                    },
                    () => {
                      this.props.onFilledCustomer({
                        customer: this.state.customer,
                        customerId: this.state.customerId,
                        address: this.state.address,
                        zone: this.state.zone,
                        geographicZoneId: this.state.geographicZoneId,
                        location: this.state.location,
                        province: this.state.province,
                        latitude: this.state.latitude,
                        longitude: this.state.longitude,
                        address_name: this.state.address_name,
                      });
                    }
                  );
                }
              }
            );
          } else {
            this.setState(
              {
                addresses: [],
                address: "",
                zone: "",
                location: "",
                province: "",
                geographicZoneId: "",
              },
              () => {
                this.props.onFilledCustomer({
                  customer: this.state.customer,
                  customerId: this.state.customerId,
                  address: this.state.address,
                  zone: this.state.zone,
                  geographicZoneId: this.state.geographicZoneId,
                  location: this.state.location,
                  province: this.state.province,
                });
              }
            );
          }
        },
        null
      );
    } else {
      this.setState({
        address: "",
        zone: "",
        location: "",
        province: "",
        geographicZoneId: "",
        latitude: "",
        longitude: "",
        address_name: "",
      });
    }
  }

  handleValue(value, state) {
    this.setState({
      [state]: value,
    });

    if (state === "customer") {
      if (value) {
        this.getAddresses(value);
      }
      const customerIndex = this.state.customers.findIndex(
        (item) => item.id === value
      );
      if (customerIndex !== -1) {
        this.setState(
          {
            customer: this.state.customers[customerIndex],
            customerId: this.state.customers[customerIndex].customerId,
          },
          () => {
            this.props.onFilledCustomer({
              customer: this.state.customer,
              customerId: this.state.customerId,
            });
          }
        );
      }
    }
    if (state === "address") {
      const addressIndex = this.state.addresses.findIndex(
        (item) => item.id === value
      );
      if (addressIndex !== -1) {
        this.setState(
          {
            address: this.state.addresses[addressIndex].id,
            geographicZoneId:
              this.state.addresses[addressIndex].geographicZoneId,
            zone: this.state.addresses[addressIndex].zone || "",
            location: this.state.addresses[addressIndex].location || "",
            province: this.state.addresses[addressIndex].province || "",
            latitude: this.state.addresses[addressIndex].latitude,
            longitude: this.state.addresses[addressIndex].longitude,
          },
          () => {
            this.props.onFilledCustomer({
              customer: this.state.customer,
              customerId: this.state.customer,
              address: this.state.address,
              geographicZoneId: this.state.geographicZoneId,
              zone: this.state.zone,
              location: this.state.location,
              province: this.state.province,
              latitude: this.state.latitude,
            });
          }
        );
      }
    }
  }

  render() {
    const {
      customers,
      customer,
      customerId,
      addresses,
      address,
      zone,
      location,
      province,
    } = this.state;
    const { disabled, checkInvalid, t } = this.props;
    return (
      <Card className="card-customer-form">
        <CardHeader icon>
          <CardIcon color="primary">
            <HotelIcon />
          </CardIcon>
          <h3 className="card-icon-title">{t("appointment.customer")}</h3>
        </CardHeader>
        <CardBody>
          <GridContainer className="card-customer-container">
            <GridItem className="fullWidth-input" xs={12} sm={6}>
              <SelectInput
                disabled={disabled}
                label={t("appointment.new.customer.customer")}
                elements={customers}
                onSelectedValue={(value) => this.handleValue(value, "customer")}
                value={customer}
                invalid={!customer && checkInvalid}
                errorText={!customer && checkInvalid ? t("error.required") : ""}
              />
            </GridItem>
            <GridItem className="fullWidth-input" xs={12} sm={6}>
              <TextField
                disabled
                fullWidth
                label={t("label.identification")}
                value={(customer && customerId) || ""}
              />
            </GridItem>
            <GridItem className="fullWidth-input" xs={12} sm={6}>
              {!disabled ? (
                <SelectInput
                  fullWidth
                  disabled={disabled}
                  label={t("label.address")}
                  elements={addresses}
                  onSelectedValue={(value) =>
                    this.handleValue(value, "address")
                  }
                  value={customer && address}
                  invalid={!address && checkInvalid}
                  errorText={
                    !address && checkInvalid ? t("error.required") : ""
                  }
                />
              ) : (
                <TextField
                  disabled
                  fullWidth
                  label={t("appointment.new.customer.address")}
                  value={this.state.address_name ? this.state.address_name : ""}
                />
              )}
            </GridItem>
            <GridItem className="fullWidth-input" xs={12} sm={6}>
              <TextField
                disabled
                fullWidth
                label={t("label.geographicZone")}
                value={(customer && address && zone) || ""}
              />
            </GridItem>
            <GridItem className="fullWidth-input" xs={12} sm={6}>
              <TextField
                disabled
                fullWidth
                label={t("label.location")}
                value={(customer && address && location) || ""}
              />
            </GridItem>
            <GridItem className="fullWidth-input" xs={12} sm={6}>
              <TextField
                disabled
                fullWidth
                label={t("label.province")}
                value={(customer && address && province) || ""}
              />
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    );
  }
}

CustomerForm.propTypes = {
  onGetCustomers: PropTypes.func,
  onFilledCustomer: PropTypes.func,
  value: PropTypes.object,
  disabled: PropTypes.bool,
  checkInvalid: PropTypes.bool,
  t: PropTypes.func,
};

export default withTranslation()(CustomerForm);
