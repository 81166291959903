import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { browserHistory } from "react-router";
import { withTranslation } from "react-i18next";
import moment from "moment";
import Add from "@material-ui/icons/Add";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField } from "@material-ui/core";
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import Card from "../Card/Card";
import CardHeader from "../Card/CardHeader";
import CardBody from "../Card/CardBody";
import CardFooter from "../Card/CardFooter";
import CardIcon from "../Card/CardIcon";
import Button from "../CustomButtons/Button";
import ValidationInput from "../ValidationInput";
import DateInput from "../DateInput";
import SelectInput from "../SelectInput";
import Snackbar from "../Snackbar/Snackbar";
import ButtonSpinner from "../ButtonSpinner";
import PersonApiInvoker from "../../api/PersonApiInvoker";
import "./NewCustomer.css";

class NewCustomer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      documentTypes: [],
      documents: [],
      nationalities: [],
      genderTypes: [],
      name: "",
      lastName: "",
      document: "",
      gender: "",
      documentType: "",
      email: "",
      birthDate: "",
      nationality: "",
      alertColor: "info",
      alertMessage: "",
      alertOpen: false,
      setTimeout: 2000,
      save: false,
      dataChanged: false,
      loading: false,
    };
  }

  componentWillMount() {
    this.getNationalities();
    this.getGenderTypes();
    this.getDocumentTypes();
  }

  componentWillReceiveProps(next) {
    if (next.documentTypes) {
      const documentTypes = this.formatDocumentTypes(next.documentTypes);
      this.setState({
        documentTypes,
        documents: next.documentTypes,
      });
    }
  }

  getNationalities() {
    PersonApiInvoker.getNationalities((jsonResponse) => {
      this.setState({
        nationalities: jsonResponse.map((nationalityItem) => {
          return {
            id: nationalityItem.nationalityId,
            name: nationalityItem.name,
          };
        }),
      });
    });
  }

  formatDocumentTypes(documentTypes) {
    let result = documentTypes.map((d) => {
      return {
        id: d.idTypeId,
        value: d.name + " - " + d.type,
      };
    });
    return result;
  }

  setGenderTypesState(genderTypes) {
    const { t } = this.props;
    let genderTypesMap = {
      UNDEFINED: t("label..undefined"),
      MALE: t("label.gender.male"),
      FEMALE: t("label.gender.female"),
    };
    let result = genderTypes.map((genderType) => {
      return {
        id: genderType,
        value: genderTypesMap[genderType],
      };
    });

    this.setState({ genderTypes: result });
  }

  getDocumentTypes() {
    PersonApiInvoker.getIdTypes((data) => {
      this.props.onGetDocumentTypes(data);
    }, null);
  }

  getGenderTypes() {
    PersonApiInvoker.getGenderTypes((data) => {
      this.setGenderTypesState(data);
    }, null);
  }

  findDocumentType(id) {
    let type = {};
    this.state.documents.map((d) => {
      if (id === d.idTypeId) {
        type = d;
        return;
      }
    });
    return type;
  }

  openAlert(color, message, data) {
    this.setState({
      alertColor: color,
      alertMessage: message,
      alertOpen: true,
      setTimeout: color === "success" ? 500 : 2000,
    });

    setTimeout(() => {
      this.setState({ alertOpen: false });
      if (color === "success") {
        browserHistory.push(`/editar-paciente/${data.personId}`);
        this.cleanFields();
      }
    }, this.state.setTimeout);
  }

  validEmail(value) {
    if (value.length === 0) return true;
    const emailRex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRex.test(value);
  }

  typeClick() {
    this.setState({ save: true, loading: true });
    const { t } = this.props;
    const requiredFields =
      this.state.name &&
      this.state.lastName &&
      this.state.document &&
      this.state.documentType !== "0" &&
      this.state.birthDate &&
      this.state.nationality &&
      this.state.gender;

    if (requiredFields && this.validEmail(this.state.email)) {
      PersonApiInvoker.postCustomer(
        {
          id: this.state.document,
          avatarUrl: "",
          birthdate: moment(this.state.birthDate).format("YYYY-MM-DD"),
          email: this.state.email,
          nationality: this.findNationality(),
          sex: this.state.gender,
          firstName: this.state.name,
          lastName: this.state.lastName,
          idType: this.findDocumentType(this.state.documentType),
          metadata: {},
          verificationId: 0,
          qr: "",
        },
        (data) => {
          this.props.onAddCustomer(data);
          this.setState({ dataChanged: false, loading: false });
          this.openAlert("success", t("customer.message.success"), data);
        },
        (error) => {
          this.openAlert("danger", "Error: " + error.message + "");
          this.setState({ loading: false });
        }
      );
    } else {
      const messageEmail = !this.validEmail(this.state.email)
        ? t("message.invalidEmail")
        : "";
      const messageRequired = !requiredFields
        ? t("appointment.new.requiredFields")
        : "";
      this.openAlert("warning", `${messageRequired} ${messageEmail}`);
      this.setState({ loading: false });
    }
  }

  handleChange(event) {
    this.setState({ formChanged: event.target.value });
  }

  findNationality() {
    let nationalityObj = _.find(this.state.nationalities, (nationalityItem) => {
      return nationalityItem.id === this.state.nationality.id;
    });

    return {
      name: nationalityObj.name,
      nationalityId: nationalityObj.id,
    };
  }

  cancelSave() {
    browserHistory.push(`/patients`);
  }

  cleanFields() {
    this.setState({
      name: "",
      lastName: "",
      document: "",
      nationality: "",
      documentType: "0",
      email: "",
      birthDate: null,
      save: false,
    });
  }

  handleValue(value, state) {
    this.setState({
      [state]: value,
      dataChanged: true,
    });
  }

  render() {
    const { t } = this.props;
    const { dataChanged, loading } = this.state;
    const maxDate = moment().format("YYYY-MM-DD");
    const minDate = moment().subtract(120, "years").format("YYYY-MM-DD");
    return (
      <div className="new-customer">
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader icon>
                <CardIcon color="primary">
                  <Add />
                </CardIcon>
                <h3 className="card-icon-title">{t("customers.new.title")}</h3>
              </CardHeader>
              <CardBody className="new-customer-form">
                <form>
                  <GridContainer>
                    <GridItem xs={12} sm={6}>
                      <ValidationInput
                        text={t("customers.new.first_name")}
                        onChangeValue={(value) =>
                          this.handleValue(value, "name")
                        }
                        value={this.state.name}
                        invalid={this.state.name === "" && this.state.save}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={6}>
                      <ValidationInput
                        text={t("customers.new.last_name")}
                        onChangeValue={(value) =>
                          this.handleValue(value, "lastName")
                        }
                        value={this.state.lastName}
                        invalid={this.state.lastName === "" && this.state.save}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={6}>
                      <Autocomplete
                        className="nationality-autocomplete select-input"
                        options={this.state.nationalities}
                        getOptionLabel={(option) => option.name}
                        getOptionSelected={(option) => option.id}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={t("customers.new.nationality")}
                            fullWidth
                          />
                        )}
                        onChange={(event, value) =>
                          this.handleValue(value, "nationality")
                        }
                        value={this.state.nationality}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={6}>
                      <SelectInput
                        className="select-input"
                        label={t("customers.new.gender")}
                        elements={this.state.genderTypes}
                        value={this.state.gender}
                        onSelectedValue={(value) =>
                          this.handleValue(value, "gender")
                        }
                        invalid={this.state.gender === "" && this.state.save}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={6}>
                      <SelectInput
                        className="select-input"
                        label={t("customers.new.id_type")}
                        elements={this.state.documentTypes}
                        onSelectedValue={(value) =>
                          this.handleValue(value, "documentType")
                        }
                        value={this.state.documentType}
                        invalid={
                          this.state.documentType === "" && this.state.save
                        }
                      />
                    </GridItem>
                    <GridItem xs={12} sm={6}>
                      <ValidationInput
                        text={t("customers.new.id")}
                        type="text"
                        onChangeValue={(value) =>
                          this.handleValue(value, "document")
                        }
                        value={this.state.document}
                        invalid={this.state.document === "" && this.state.save}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={6} className="calendar-up">
                      <DateInput
                        minDate
                        min={minDate}
                        max={maxDate}
                        text={t("customers.new.birth_date")}
                        onChangeValue={(value) =>
                          this.handleValue(value, "birthDate")
                        }
                        value={this.state.birthDate}
                        invalid={
                          this.state.birthdate
                            ? this.state.birthdate <= maxDate
                            : !this.state.birthDate && this.state.save
                        }
                      />
                    </GridItem>
                    <GridItem xs={12} sm={6}>
                      <ValidationInput
                        text={t("customers.new.email")}
                        type="email"
                        onChangeValue={(value) =>
                          this.handleValue(value, "email")
                        }
                        value={this.state.email}
                        invalid={!this.validEmail(this.state.email)}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <p className="required-text">
                        <small>*</small> Campos requeridos
                      </p>
                    </GridItem>
                  </GridContainer>
                </form>
              </CardBody>
              <CardFooter className="new-customer-submit padding-horizontal">
                <Button color="default" onClick={this.cancelSave.bind(this)}>
                  {t("customers.new.cancel")}
                </Button>
                {/* <Button disabled={!this.state.dataChanged} color="primary" onClick={this.typeClick.bind(this)}>
                                    {t("customers.new.save")}
                                </Button> */}
                <ButtonSpinner
                  onClick={this.typeClick.bind(this)}
                  disabled={!dataChanged || loading}
                  label={t("customers.new.save")}
                  labelLoading={t("customers.new.saving")}
                  loading={loading}
                  typeButton="submit"
                  color="primary"
                />
              </CardFooter>
            </Card>
            <Snackbar
              place="tr"
              color={this.state.alertColor}
              message={this.state.alertMessage}
              open={this.state.alertOpen}
            />
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

NewCustomer.propTypes = {
  onGetDocumentTypes: PropTypes.func.isRequired,
  onAddCustomer: PropTypes.func.isRequired,
};

export default withTranslation()(NewCustomer);
