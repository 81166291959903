import { Module } from "module";
import Appointments from "./components/Appointments";
import Drivers from "./pages/Drivers";
import AppointmentDetail from "./components/Appointments/AppointmentDetail";
import NewCustomer from "./components/NewCustomer/new-customer";
import EditCustomer from "./components/EditCustomer/edit-customer";
import NewEmployee from "./components/NewEmployee/new-employee";
import EditEmployee from "./components/EditEmployee/EditEmployee";

// icons
import AssignmentOutlinedIcon from "@material-ui/icons/AssignmentOutlined";
import HotelOutlinedIcon from "@material-ui/icons/HotelOutlined";
import PollOutlinedIcon from "@material-ui/icons/PollOutlined";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import Car from "./icons/Drivers";
import DataReport from "./components/EditEmployee/EditEmployee";
import Customers from "./pages/Customers";
import Driver from "./pages/Driver";

const routes = [
  {
    path: "/solicitudes",
    name: "routes.appointments",
    mini: "routes.appointments.mini",
    icon: AssignmentOutlinedIcon,
    rtlName: "",
    component: Appointments,
    layout: "",
    enabled: true,
    authority: "menu_appointment",
  },
  {
    path: "/drivers",
    name: "routes.drivers",
    mini: "routes.drivers.mini",
    icon: Car,
    rtlName: "",
    component: Drivers,
    layout: "",
    enabled: true,
    authority: "menu_employees",
  },
  {
    path: "/driver/:id",
    name: "routes.driver",
    mini: "routes.driver.mini",
    rtlName: "",
    component: Driver,
    layout: "",
    enabled: true,
    hidden: true,
  },
  {
    path: "/appointments/:id/edit/agenda",
    name: "routes.appointments",
    mini: "routes.appointments.mini",
    rtlName: "",
    component: AppointmentDetail,
    layout: "",
    enabled: true,
    hidden: true,
  },
  {
    path: "/nuevo-paciente",
    name: "routes.newPatient",
    mini: "routes.newPatient.mini",
    rtlName: "",
    component: NewCustomer,
    layout: "",
    enabled: true,
    hidden: true,
  },
  {
    path: "/editar-paciente/:id",
    name: "routes.editPatient",
    mini: "routes.editPatient.mini",
    rtlName: "",
    component: EditCustomer,
    layout: "",
    enabled: true,
    hidden: true,
    authority: "customers_edit",
  },
  {
    path: "/patient/:id",
    name: "routes.viewPatient",
    mini: "routes.viewPatient.mini",
    inset: 10,
    rtlName: "",
    component: EditCustomer,
    layout: "",
    enabled: true,
    hidden: true,
    authority: "customers_view,customers_view_related",
  },
  {
    path: "/patients",
    name: "routes.patients",
    mini: "routes.patients.mini",
    icon: HotelOutlinedIcon,
    rtlName: "",
    component: Customers,
    layout: "",
    enabled: true,
    authority: "menu_customers",
    views: [],
  },
  {
    path: "/nuevo-paciente",
    name: "routes.newPatient",
    mini: "routes.newPatient.mini",
    rtlName: "",
    component: NewCustomer,
    layout: "",
    enabled: true,
    hidden: true,
  },
  {
    path: "/nuevo-profesional",
    name: "routes.newProfessional",
    mini: "routes.newProfessional.mini",
    rtlName: "",
    component: NewEmployee,
    layout: "",
    enabled: true,
    hidden: true,
  },
  {
    path: "/editar-profesional/:id",
    name: "routes.editProfessional",
    mini: "routes.editProfessional.mini",
    rtlName: "",
    component: EditEmployee,
    layout: "",
    enabled: true,
    hidden: true,
  },
  {
    path: "/ver-profesional",
    name: "routes.viewProfessional",
    mini: "routes.viewProfessional.mini",
    rtlName: "",
    component: EditEmployee,
    layout: "",
    enabled: true,
    hidden: true,
  },
  {
    path: "/statistics",
    name: "routes.statistics",
    mini: "routes.statistics.mini",
    icon: PollOutlinedIcon,
    rtlName: "",
    component: DataReport,
    layout: "",
    enabled: true,
    views: [],
  },
];

export default routes;
